import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import { Button } from "react-bootstrap";
import DocEditor from "../components/common/DocEditor";
import Pagination, { usePaging } from "../components/common/Pagination";
import { useMwfRole, useWindowSize } from "../stores/SystemStore";
import { useNavigate } from "react-router-dom";

function PublishedReleaseNotes(props: { portalDocument?: boolean }) {
  const navigate = useNavigate();
  const mwfRole = useMwfRole();
  const [paging, setPaging] = usePaging(1, 50);
  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteDocumentation.getPublishedItems(
        {
          page: paging.page,
          pageSize: paging.pageSize,
          search: search.used,
          orderByEnum: SysModels.OrderByEnum.Ascending,
        },
        props.portalDocument
      ),
    props.portalDocument ? "Portal Documentation" : "Release Notes"
  );

  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [selectedDoc, setSelectedDoc] =
    useState<SysModels.IGeneralDocumentationDisplayOutputDto>();
  // const docObj = useFetchHelper(
  //   async () =>
  //     SysServices.http.siteDocumentation.getContentPublished(
  //       selectedDoc?.sqlId || ""
  //     ),
  //   props.portalDocument ? "Portal Documentation" : "Release Note"
  // );

  useEffect(() => {
    if (
      grid.status === FetchStatus.Complete &&
      grid.data &&
      grid.data.siteDocumentationDisplayOutputDtos.length > 0
    ) {
      setSelectedDoc(grid.data.siteDocumentationDisplayOutputDtos[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  // useEffect(() => {
  //   if (!!selectedDoc) {
  //     docObj.getData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDoc]);
  const [expand, setExpand] = useState(false);
  const windowSize = useWindowSize();

  return (
    <>
      <div className="default-page-layout h-100 flex flex-column">
        <h4 className="hide-on-print">
          {props.portalDocument ? "Portal Documentation" : "Release Notes"}
        </h4>
        <div className="bg-white col-sm-12 flex-1 mb-4">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div className="h-100 flex flex-column">
              <div className="customer-documentation flex-1">
                <div
                  className={`hide-on-print ${expand ? "display-none" : ""}`}
                >
                  <div className="p-3 flex flex-wrap gap-10">
                    <div className="flex-0" style={{ maxWidth: "100%" }}>
                      <div className="input-group search-box">
                        <input
                          autoFocus={true}
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          value={search.typed}
                          onChange={(e) => {
                            setSearch((data) => {
                              return {
                                ...data,
                                typed: e.target.value,
                              };
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              e.stopPropagation();
                              setSearch((data) => {
                                if (data.used === data.typed) {
                                  grid.getData();
                                  return data;
                                }
                                return {
                                  ...data,
                                  used: data.typed,
                                };
                              });
                            }
                          }}
                        ></input>
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) => {
                              setSearch((data) => {
                                if (data.used === data.typed) {
                                  grid.getData();
                                  return data;
                                }
                                return {
                                  ...data,
                                  used: data.typed,
                                };
                              });
                            }}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={(e) => {
                              setSearch((data) => {
                                return { typed: "", used: "" };
                              });
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                          {!!(
                            mwfRole & SysModels.UserRolesEnum.ApplicationAdmin
                          ) && (
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={(e) => {
                                navigate("/releasenotesmaintenance");
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className="table table-hover pointer m-0">
                    <thead>
                      <tr>
                        <th>Title / Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grid.data?.siteDocumentationDisplayOutputDtos?.map(
                        (doc) => (
                          <tr
                            key={doc.sqlId}
                            onClick={(e) => {
                              setSelectedDoc(doc);
                              var anchor =
                                document.querySelector("#document-preview");
                              anchor?.scrollIntoView();
                            }}
                            className={`${
                              selectedDoc?.sqlId === doc.sqlId
                                ? "selected-document"
                                : ""
                            }`}
                          >
                            <td>
                              <div className="flex">
                                <div className="flex-1">{doc.title}</div>
                                <div>
                                  {!windowSize.isSmall && (
                                    <i
                                      title="Open in New Tab"
                                      className="fa fa-external-link txt-primary"
                                      onClick={(e) => {
                                        if (props.portalDocument) {
                                          window.open(
                                            `/portaldocumentation/${doc.sqlId}`,
                                            "_blank"
                                          );
                                        } else {
                                          window.open(
                                            `/releasenotes/${doc.sqlId}`,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                              <div>
                                <small>{doc.subject}</small>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>

                  {!grid.data?.totalRecords && (
                    <div className="text-center py-4">
                      <small>No Record(s) Found</small>
                    </div>
                  )}
                </div>
                <div>
                  <div
                    id="document-preview"
                    className={`${
                      !grid.data?.totalRecords ? "display-none" : ""
                    }`}
                  >
                    {/* {docObj.status === FetchStatus.InProgress && (
                      <div className="py-4">
                        <CommonSpinner></CommonSpinner>
                      </div>
                    )}
                    {docObj.status === FetchStatus.Failed && (
                      <div className="py-4 text-center">
                        <strong className="text-danger">
                          Failed Getting Document
                        </strong>
                      </div>
                    )} */}
                    {!!selectedDoc && (
                      <div>
                        <div className="show-on-print">
                          <strong>{selectedDoc.title}</strong>
                          <div
                            className="py-2"
                            style={{ borderBottom: "solid 1px #ddd" }}
                          >
                            {selectedDoc.subject}
                          </div>
                        </div>

                        <div
                          className="hide-on-print px-4 py-2 flex flex-center"
                          style={{
                            background: "#eee",
                            borderBottom: "solid 1px #ddd",
                          }}
                        >
                          <div className="pe-4">
                            <Button
                              type="button"
                              variant={expand ? "danger" : "light"}
                              size="sm"
                              onClick={(e) => {
                                setExpand(!expand);
                              }}
                            >
                              {!expand && (
                                <i className="fa fa-expand text-primary"></i>
                              )}
                              {expand && <i className="fa fa-compress"></i>}
                            </Button>
                          </div>
                          <div className="flex-1">
                            <div className="mb-2">
                              <strong>{selectedDoc.title}</strong>
                            </div>
                            {!!`${selectedDoc.subject || ""}`.trim() && (
                              <div>{selectedDoc.subject}</div>
                            )}
                          </div>
                          <div>
                            <Button
                              type="button"
                              variant="default"
                              onClick={(e) => {
                                window.print();
                              }}
                            >
                              <i className="fa fa-print txt-primary"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="p-4" key={selectedDoc.sqlId || ""}>
                          <DocEditor
                            readonly={true}
                            content={selectedDoc.content || ""}
                          ></DocEditor>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`p-3 pt-0`}
                style={{
                  borderTop: "solid 1px #eee",
                }}
              >
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="documents"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PublishedReleaseNotes;
