import SysModels from "../../models";
import fetcher from "../Fetcher";

class SystemService {
  getSiteMessage = async () => {
    const url = `/System/SiteMessage`;
    return fetcher.get<SysModels.ISiteMessageOutputDto>(url);
  };

  getSiteDown = async () => {
    const url = `/System/SiteDown`;
    return fetcher.get<SysModels.ISiteDownOutputDto>(url, { notoken: true });
  };

  getSiteVersion = async () => {
    const url = `/System/SiteVersion`;
    return fetcher.get<SysModels.ISiteVersionOutputDto>(url, { notoken: true });
  };
}

const system = new SystemService();
export default system;
