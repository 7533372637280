import React, { useEffect, useState } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../../components/common/DateRangePicker";
import { reaction } from "mobx";
import systemStore from "../../stores/SystemStore";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button, ButtonGroup } from "react-bootstrap";

type OrderSummaryTabTypes = "percentByQuantity" | "percentByAmount";

const OrderSummaryTabs: { label: string; percProp: OrderSummaryTabTypes }[] = [
  { label: "By Category", percProp: "percentByQuantity" },
  { label: "By Amount", percProp: "percentByAmount" },
];

function OrderSummaryWidget(props: {
  hide?: boolean;
  tabs?: OrderSummaryTabTypes[];
  titleAsWidget?: string;
  action: (
    data: SysModels.ICustomerOrderingSummaryFilterDto
  ) => Promise<SysModels.ICustomerOrderingSummaryOutputDto>;
}) {
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: DatePeriod;
  }>({
    from: GetPeriodValues(DatePeriod.ThisWeek).from,
    to: GetPeriodValues(DatePeriod.ThisWeek).to,
    period: DatePeriod.ThisWeek,
  });

  const getPeriod = () => {
    if (period.period === DatePeriod.ThisMonth) {
      return SysModels.CustomerOrderingSummaryDateRangeEnum.ThisMonth;
    }
    if (period.period === DatePeriod.ThisQuarter) {
      return SysModels.CustomerOrderingSummaryDateRangeEnum.ThisQuarter;
    }
    if (period.period === DatePeriod.ThisYear) {
      return SysModels.CustomerOrderingSummaryDateRangeEnum.ThisYear;
    }
    return SysModels.CustomerOrderingSummaryDateRangeEnum.ThisWeek;
  };

  const summary = useFetchHelper(
    async () =>
      props.action({
        dateRange: getPeriod(),
      }),
    "Order Summary"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      summary.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [colors, setColors] = useState<
    {
      name: string;
      color: string;
    }[]
  >([]);

  const getColor = (cnt: number) => {
    let x = 360 * Math.random(); // (((cnt % 10) + 1) / 10) * 360;
    if (cnt <= 10) {
      x = 36 * (11 - cnt);
    }
    return `hsla(${~~x}, 70%,  72%, 0.8)`;
  };

  useEffect(() => {
    if (summary.data) {
      setColors((prev) => {
        const list = [...prev];
        if (summary.data) {
          [
            ...summary.data.currentPeriodData,
            ...summary.data.lastPeriodData,
          ].forEach((categ) => {
            if (!list.find((color) => color.name === categ.categoryName)) {
              list.push({
                name: categ.categoryName,
                color: getColor(list.length + 1),
              });
            }
          });
        }
        return list;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary.data]);

  const getValuesBy = (
    list: SysModels.ICustomerOrderingSummaryCategoryOutputDto[],
    forQty = false
  ) => {
    const rtn = list.map((data) => {
      return {
        category: data.categoryName,
        percentage: forQty ? data.percentByQuantity : data.percentByAmount,
        value: forQty ? data.quantity : data.amount,
        formatted: forQty
          ? commonService.toNumberWithComma(data.quantity)
          : commonService.toMoney(data.amount),
      };
    });
    if (rtn.length === 0) {
      return [
        {
          category: "No Data",
          percentage: 0,
          value: 1,
          formatted: 1,
        },
      ];
    }
    return rtn.sort(commonService.sortByStringProperty("category"));
  };

  const [selTab, setSelTab] =
    useState<OrderSummaryTabTypes>("percentByQuantity");
  const [moreCateg, setMoreCateg] = useState(false);

  return (
    <div className="hide-on-print">
      <div className="mb-4 bg-white">
        <div
          className={`p-3 py-2 txt-light ${
            props.titleAsWidget ? "bg-success" : "pt-4"
          }`}
        >
          <div className={isSmallScreen ? "" : "flex flex-center"}>
            <div className="flex flex-center flex-1">
              {!!props.titleAsWidget && (
                <h4 className="flex-1 m-0">
                  <span className="flex flex-center">
                    <em className="fa fa-pie-chart me-2"></em>
                    <span>{props.titleAsWidget}</span>
                  </span>
                </h4>
              )}
              <div className="flex-0">
                <DateRangePicker
                  disabled={summary.status === FetchStatus.InProgress}
                  from={period.from}
                  to={period.to}
                  onChanged={(from, to, period) => {
                    setPeriod({ from: from, to: to, period: period });
                  }}
                  defaultPeriod={DatePeriod.ThisWeek}
                  hideCustom={true}
                  periods={[
                    DatePeriod.ThisWeek,
                    DatePeriod.ThisMonth,
                    DatePeriod.ThisQuarter,
                    DatePeriod.ThisYear,
                  ]}
                ></DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 overflow-auto min-height-100">
          {summary.status === FetchStatus.InProgress && (
            <CommonSpinner></CommonSpinner>
          )}
          {summary.status === FetchStatus.Complete && (
            <div>
              {(props.tabs?.length || 0) > 1 && (
                <div className="px-3 pb-4">
                  <ButtonGroup>
                    {props.tabs?.map((btn) => (
                      <Button
                        key={btn}
                        type="button"
                        variant={selTab === btn ? "success" : "outline-success"}
                        disabled={selTab === btn}
                        onClick={(e) => setSelTab(btn)}
                      >
                        {
                          OrderSummaryTabs.find((x) => x.percProp === btn)
                            ?.label
                        }
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              )}

              <div className="container">
                {OrderSummaryTabs.filter(
                  (x) =>
                    (props.tabs || ["percentByQuantity"]).includes(
                      x.percProp
                    ) && x.percProp === selTab
                ).map((by) => (
                  <div key={by.label} className="text-left">
                    {(props.tabs?.length || 0) <= 1 && (
                      <h5 style={{ marginLeft: "-10px", marginBottom: "16px" }}>
                        <i className="fa fa-pie-chart"></i> {by.label}
                      </h5>
                    )}
                    <div
                      className="row mb-4 alert alert-secondary"
                      style={{
                        backgroundColor: "#efefef",
                      }}
                    >
                      {[
                        {
                          label: "Last Period",
                          data: (summary.data?.lastPeriodData || []).map(
                            (d) => {
                              return {
                                ...d,
                              };
                            }
                          ),
                        },
                        {
                          label: "Current Period",
                          data: summary.data?.currentPeriodData || [],
                        },
                      ].map((list, i) => (
                        <div
                          key={list.label}
                          className="col col-sm-12 col-md-6"
                        >
                          <div
                            className="chart-doughnut text-center"
                            style={{
                              maxWidth: "500px",
                              minHeight: "300px",
                              margin: "0 auto",
                            }}
                          >
                            {/* <label>{list.label}</label> */}
                            {!props.hide && (
                              <Doughnut
                                title={list.label}
                                data={{
                                  labels:
                                    getValuesBy(
                                      list.data || [],
                                      by.percProp === "percentByQuantity"
                                    )?.map(
                                      (x) =>
                                        `${
                                          x.category || "N/A"
                                        }: ${commonService.toNumberWithComma(
                                          (x.percentage || 0) * 100
                                        )}%`
                                    ) || [],
                                  datasets: [
                                    {
                                      label: list.label,
                                      data: getValuesBy(
                                        list.data || [],
                                        by.percProp === "percentByQuantity"
                                      ),

                                      backgroundColor: [
                                        ...(getValuesBy(
                                          list.data || [],
                                          by.percProp === "percentByQuantity"
                                        )?.map(
                                          (x) =>
                                            colors.find(
                                              (c) => x.category === c.name
                                            )?.color || "#ccc"
                                        ) || []),
                                        "#ddd",
                                      ],
                                      borderWidth: 0,
                                    },
                                  ],
                                }}
                                plugins={[ChartDataLabels as any]}
                                options={{
                                  cutout: 0,
                                  responsive: true,
                                  maintainAspectRatio: true,
                                  plugins: {
                                    legend: {
                                      display: false, //!props.titleAsWidget,
                                      position: isSmallScreen ? "top" : "left",
                                      fullSize: true,
                                    },
                                    title: {
                                      display: true,
                                      text: list.label,
                                      color: "#222",
                                      position: "top",
                                      align: "center",
                                      font: {
                                        weight: "bold",
                                      },
                                      padding: 10,
                                    },
                                    datalabels: {
                                      display: true,
                                      color: "#222",
                                      formatter: (val, context) => {
                                        if (val.percentage < 0.05) {
                                          return "";
                                        }
                                        // if (val.percentage < 0.05) {
                                        //   return `${commonService.toNumberWithComma(
                                        //     val.percentage * 100
                                        //   )}%`;
                                        // }
                                        // return `${
                                        //   val.category
                                        // }: ${commonService.toNumberWithComma(
                                        //   val.percentage * 100
                                        // )}%`;
                                        return val.category || "N/A";
                                      },
                                    },
                                    tooltip: {
                                      callbacks: {
                                        label: (context) => {
                                          if (
                                            !context.label ||
                                            (context.raw as any).percentage ===
                                              0
                                          ) {
                                            return "No Data";
                                          }
                                          return ` ${context.label} - ${
                                            (context.raw as any).formatted
                                          }`;
                                        },
                                      },
                                    },
                                  },
                                }}
                              ></Doughnut>
                            )}
                            <div
                              className="pt-2"
                              key={`${list.label}-${moreCateg}`}
                            >
                              {(
                                getValuesBy(
                                  list.data || [],
                                  by.percProp === "percentByQuantity"
                                ) || []
                              ).map((data, i) => (
                                <React.Fragment key={data.category}>
                                  {(i < 10 || moreCateg) && (
                                    <div className="px-2">
                                      <div className="flex flex-center">
                                        <small
                                          className="p-1 px-2 me-2"
                                          style={{
                                            backgroundColor:
                                              colors.find(
                                                (c) => c.name === data.category
                                              )?.color || "#bbb",
                                          }}
                                        ></small>
                                        <small className="flex-1 text-left">
                                          {data.category === "No Data" &&
                                          data.percentage === 0 ? (
                                            <>No Data</>
                                          ) : (
                                            <>
                                              {data.category || "N/A"}:
                                              {` ${commonService.toNumberWithComma(
                                                (data.percentage || 0) * 100
                                              )}% - ${data.formatted}`}
                                            </>
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  )}
                                  {!moreCateg && i === 10 && (
                                    <div className="text-left text-primary pointer px-2">
                                      <strong
                                        onClick={(e) => {
                                          setMoreCateg(true);
                                        }}
                                      >
                                        <small>More Categories...</small>
                                      </strong>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderSummaryWidget;
