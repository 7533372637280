import React, { useEffect, useState } from "react";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import CommonSpinner from "../components/common/CommonSpinner";
import { useNavigate } from "react-router-dom";
import SysModels from "../models";
import systemStore from "../stores/SystemStore";
import { reaction } from "mobx";

function TwoFactorAuth(props: { must?: boolean }) {
  const navigate = useNavigate();
  const [pwData, setPwData] = useState({
    currentPassword: "",
  });
  const [inProgress, setInProgress] = useState(false);
  const [data2fa, setData2fa] =
    useState<SysModels.ICustomerUserTwoFactorSecretOutputDto>();
  const [code, setCode] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const allowSave = () => {
    if (
      data2fa ||
      (loginStatus.is2faRequired &&
        loginStatus.mustDoSomething?.need2faAuthentication)
    ) {
      return !commonService.isNullOrEmpty(code);
    }
    return !commonService.isNullOrEmpty(pwData.currentPassword);
  };

  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (
      loginStatus.is2faRequired &&
      loginStatus.mustDoSomething?.need2faAuthentication
    ) {
      commonService.focusInput("2fa_code");
    } else {
      commonService.focusInput("2fa_password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  return (
    <>
      {inProgress && (
        <CommonSpinner overlay={true} message="Processing..."></CommonSpinner>
      )}

      <div className="row">
        {props.must && <div className="cols-sm-12 col-md-6 col-lg-4"></div>}
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="default-page-layout">
            <h4>Two-Factor Authentication</h4>
            {props.must && (
              <div className="text-danger mb-4">
                <label>
                  <i className="fa fa-shield me-1"></i> Please enter code
                  generated from your Authenticator App
                </label>
              </div>
            )}
          </div>
          {!loginStatus.is2faRequired && !data2fa && (
            <div className="bg-white mb-2">
              <div className="p-3 bg-blue txt-light">
                <h5 className="m-0">
                  <em className="fa fa-shield me-2"></em>Enter Password to
                  Enable 2FA
                </h5>
              </div>
              <div className="p-3" style={{ minHeight: "230px" }}>
                <form
                  className="pt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (allowSave()) {
                      setInProgress(true);
                      SysServices.http.customerUser
                        .getTwoFactorSecret({
                          password: pwData.currentPassword,
                        })
                        .then((data: any) => {
                          setData2fa(data);
                          setInProgress(false);
                          commonService.focusInput("2fa_code", 500);
                        })
                        .catch((err: any) => {
                          toastStore.showError("Failed Getting 2FA Codes", err);
                          setInProgress(false);
                        });
                    }
                  }}
                >
                  <input
                    id="2fa_password"
                    type="password"
                    name="current-password"
                    placeholder="Current Password"
                    className="form-control mb-3"
                    value={pwData.currentPassword}
                    onChange={(e) => {
                      setPwData((p) => {
                        return {
                          ...p,
                          currentPassword: e.target.value,
                        };
                      });
                    }}
                    disabled={inProgress}
                  />
                  <button
                    className="btn btn-primary mt-3"
                    type="submit"
                    disabled={inProgress || !allowSave()}
                  >
                    {inProgress ? "Sending Request..." : "Enable 2FA"}
                  </button>
                </form>
              </div>
            </div>
          )}
          {!loginStatus.is2faRequired && !!data2fa && (
            <div className="bg-white mb-2">
              <div className="p-3 bg-blue txt-light">
                <h5 className="m-0">
                  <em className="fa fa-shield me-2"></em>Setup Authenticator App
                </h5>
              </div>
              <div className="p-3" style={{ minHeight: "230px" }}>
                <div className="text-center pb-2">
                  <img src={`${data2fa.qrCode}`} alt="QR Code" />
                  <div style={{ fontSize: "18px" }}>{data2fa.secret}</div>
                </div>

                <form
                  className="pt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (allowSave()) {
                      setInProgress(true);
                      SysServices.http.customerUser
                        .acceptTwoFactor(code)
                        .then((data: any) => {
                          SysServices.http.fetcher.storeToken(data);
                          systemStore.setAuthDataForCustomerSite(data);
                          setInProgress(false);
                          setCode("");
                          setPwData({ currentPassword: "" });
                          toastStore.showToast(
                            "Two-Factor Authentication has been Enabled",
                            "success"
                          );
                        })
                        .catch((err: any) => {
                          toastStore.showError("Failed Validating Code", err);
                          setInProgress(false);
                        });
                    }
                  }}
                >
                  <input
                    id="2fa_code"
                    type="text"
                    name="current-password"
                    placeholder="2FA Code"
                    className="form-control mb-3"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    disabled={inProgress}
                  />

                  <button
                    className="btn btn-primary mt-3"
                    type="submit"
                    disabled={inProgress || !allowSave()}
                  >
                    {inProgress ? "Sending Request..." : "Send Code"}
                  </button>
                </form>
              </div>
            </div>
          )}

          {loginStatus.is2faRequired &&
            !loginStatus.mustDoSomething?.need2faAuthentication && (
              <div className="bg-white mb-2">
                <div className="p-3 bg-red txt-light">
                  <h5 className="m-0">
                    <em className="fa fa-shield me-2"></em>Enter Password to
                    Disable 2FA
                  </h5>
                </div>
                <div className="p-3" style={{ minHeight: "230px" }}>
                  <form
                    className="pt-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (allowSave()) {
                        setInProgress(true);
                        SysServices.http.customerUser
                          .turnOffTwoFactor({
                            password: pwData.currentPassword,
                          })
                          .then((data: any) => {
                            SysServices.http.fetcher.storeToken(data);
                            systemStore.setAuthDataForCustomerSite(data);
                            setInProgress(false);
                            setCode("");
                            setPwData({ currentPassword: "" });
                            toastStore.showToast(
                              "Two-Factor Authentication has been Disabled",
                              "success"
                            );
                          })
                          .catch((err: any) => {
                            toastStore.showError("Failed to Disable 2FA", err);
                            setInProgress(false);
                          });
                      }
                    }}
                  >
                    <input
                      id="2fa_password"
                      type="password"
                      name="current-password"
                      placeholder="Current Password"
                      className="form-control mb-3"
                      value={pwData.currentPassword}
                      onChange={(e) => {
                        setPwData((p) => {
                          return {
                            ...p,
                            currentPassword: e.target.value,
                          };
                        });
                      }}
                      disabled={inProgress}
                    />
                    <button
                      className="btn btn-danger mt-3"
                      type="submit"
                      disabled={inProgress || !allowSave()}
                    >
                      {inProgress ? "Sending Request..." : "Disable 2FA"}
                    </button>
                  </form>
                </div>
              </div>
            )}

          {loginStatus.is2faRequired &&
            loginStatus.mustDoSomething?.need2faAuthentication && (
              <div className="bg-white mb-2">
                <div className="p-3 bg-blue txt-light">
                  <h5 className="m-0">
                    <em className="fa fa-shield me-2"></em>Enter Code from
                    Authenticator App
                  </h5>
                </div>
                <div className="p-3" style={{ minHeight: "230px" }}>
                  <form
                    className="pt-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (allowSave()) {
                        setInProgress(true);
                        SysServices.http.customerUser
                          .loginTwoFactor({
                            code: code,
                            email: SysServices.http.fetcher.getEmailFromToken(),
                            rememberMe: rememberMe,
                            sessionId:
                              SysServices.http.fetcher.getToken()?.sessionId ||
                              "",
                          })
                          .then((data: any) => {
                            SysServices.http.fetcher.storeToken(data);
                            systemStore.setAuthDataForCustomerSite(data);
                            //setInProgress(false);
                            navigate("/");
                          })
                          .catch((err: any) => {
                            toastStore.showError("Failed Validating Code", err);
                            setInProgress(false);
                          });
                      }
                    }}
                  >
                    <input
                      id="2fa_code"
                      type="text"
                      name="current-password"
                      placeholder="2FA Code"
                      className="form-control mb-3"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                      disabled={inProgress}
                    />

                    <div className="flex flex-center">
                      <input
                        id="rememberMe"
                        type="checkbox"
                        className="me-2"
                        checked={rememberMe}
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      />
                      <label htmlFor="rememberMe" className="pointer">
                        Remember Me
                      </label>
                    </div>

                    <button
                      className="btn btn-primary mt-3"
                      type="submit"
                      disabled={inProgress || !allowSave()}
                    >
                      {inProgress ? "Sending Request..." : "Send Code"}
                    </button>
                  </form>
                </div>
              </div>
            )}
        </div>
        <div className="cols-sm-12 col-md-6 col-lg-4"></div>
        {!props.must && <div className="cols-sm-12 col-md-6 col-lg-4"></div>}
      </div>
    </>
  );
}

export default TwoFactorAuth;
