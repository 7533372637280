import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import Pagination, { usePaging } from "../../components/common/Pagination";
import FormModal from "../../components/common/FormModal";
import CommonSpinner from "../../components/common/CommonSpinner";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";

function ViewMasterAccountChildrenDialog(props: {
  customerId: string;
  close: () => void;
}) {
  const grid = useFetchHelper(
    async () =>
      SysServices.http.customer.orderGuideChildren(
        paging.page,
        paging.pageSize,
        props.customerId
      ),
    "Child Accounts"
  );

  const [paging, setPaging] = usePaging(1, 25);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const [isSwitching, setIsSwitching] = useState(false);
  const switchNow = async (customerNumber: string) => {
    setIsSwitching(true);
    await SysServices.http.customer
      .get(customerNumber)
      .then((data) => {
        systemStore.setCurrentCustomer({
          name: data.customerName,
          number: data.erpCustomerNumber,
          id: data.sqlId,
          data: data,
        });
        setIsSwitching(false);
        props.close();
      })
      .catch((error) => {
        toastStore.showError("Failed Getting Customer", error);
        setIsSwitching(false);
      });
  };

  return (
    <FormModal
      title={`View Master Account's Children: ${props.customerId}`}
      size="lg"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        props.close();
      }}
      primaryButtonOnly={true}
      customBodyFooter={true}
    >
      <ModalBody>
        {isSwitching && (
          <CommonSpinner message="Switching..." overlay={true}></CommonSpinner>
        )}
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Customers</th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.customerOrderGuideChildrenGridItemDtos?.map(
                    (customer) => (
                      <tr key={`${customer.customerNumber}`}>
                        <td>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              switchNow(customer.customerNumber);
                            }}
                          >
                            {customer.customerName} ({customer.customerNumber})
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => {
            props.close();
          }}
          variant="secondary"
        >
          Close
        </Button>
      </ModalFooter>
    </FormModal>
  );
}

export default ViewMasterAccountChildrenDialog;
