import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class CustomerService {
  get = async (customerNumber?: string) => {
    let url = `/Customer`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(
          systemStore.currentCustomer.number
        )}`;
      }
    }
    return fetcher.get<SysModels.IErpCustomerEmployeeSiteOutputDto>(url);
  };

  scheduleCustomerUpdate = async (data: SysModels.ICustomerUpdateDto) => {
    const url = `/Customer/ScheduleCustomerUpdate`;
    return fetcher.post<any>(url, data);
  };

  scheduleOrderGuideReload = async (data: SysModels.ICustomerUpdateDto) => {
    const url = `/Customer/ScheduleOrderGuideReload`;
    return fetcher.post<any>(url, data);
  };

  turnOffCustomersTwoFactorAuthentication = async (
    data: SysModels.ITurnOffCustomerTwoFactorInputDto
  ) => {
    const url = `/Customer/TurnOffCustomersTwoFactorAuthentication`;
    return fetcher.post<any>(url, data);
  };

  customerOrderingSummary = async (
    data: SysModels.ICustomerOrderingSummaryFilterDto
  ) => {
    let url = `/Customer/OrderingSummary`;
    if (systemStore.currentCustomer) {
      url = `/Customer/CustomerOrderingSummary/${encodeURIComponent(
        systemStore.currentCustomer.number
      )}`;
    }
    return fetcher.post<SysModels.ICustomerOrderingSummaryOutputDto>(url, data);
  };

  orderGuideHistoryUpdate = async (customerNumber: string) => {
    const url = `/OrderGuideHistoryUpdate/${encodeURIComponent(
      customerNumber
    )}`;
    return fetcher.get<SysModels.ICustomerOrderGuideUpdateHistoryDto[]>(url);
  };

  orderGuideChildren = async (
    page: number,
    pageSize: number,
    customerNumber: string
  ) => {
    const url = `/Customer/OrderGuideChildren/${page}/${pageSize}/${encodeURIComponent(
      customerNumber
    )}`;
    return fetcher.get<SysModels.ICustomerOrderGuideChildrenGridDto>(url);
  };
}

const customer = new CustomerService();
export default customer;
