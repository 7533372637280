import React, { useEffect, useState } from "react";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { reaction } from "mobx";
import { protectedResources } from "../AuthConfig";
import systemStore, { useCurrentCustomer } from "../stores/SystemStore";
import NavBar from "./NavBar";
import SideNav from "../layout/SideNav";
import packageInfo from "../../package.json";
import SysServices from "../services";
import SystemBanner from "./SystemBanner";
import SysModels from "../models";
import { loginRequest } from "../AuthConfig";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import CommonSpinner from "./common/CommonSpinner";
import ConfirmDialog from "./common/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import ChatBot from "../pages/widgets/ChatBot";

function Layout({ children }: any) {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  const [tmoSystemMessage, setTmoSystemMessage] = useState(0);
  const [message, setMessage] = useState<SysModels.ISiteMessageOutputDto>();
  const [otherErrorMessage, setOtherErrorMessage] = useState<string>();

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );

    const disposer1 = reaction(
      () => systemStore.tmoSystemMessage,
      (val, prevVal, r) => {
        setTmoSystemMessage(val);
      }
    );

    return () => {
      disposer();
      disposer1();
    };
  }, []);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [prompt2fa, setPrompt2fa] = useState(
    SysServices.http.fetcher.prompt2faDialog()
  );
  useEffect(() => {
    if (loginStatus.canAccessWebsite && !loginStatus.mustDoSomething) {
      SysServices.http.system
        .getSiteMessage()
        .then((data) => {
          setMessage(data);
          systemStore.setSystemMessageVisible(data.isVisible);
        })
        .catch((error) => {});
    } else {
      systemStore.setSystemMessageVisible(false);
    }
    setPrompt2fa(SysServices.http.fetcher.prompt2faDialog());
  }, [
    loginStatus.canAccessWebsite,
    loginStatus.mustDoSomething,
    tmoSystemMessage,
  ]);

  useEffect(() => {
    //For Employee Site Only.
    if (commonService.isEmployeeSite && account && inProgress === "none") {
      if (account.environment !== process.env.REACT_APP_B2C_AUTHORITY_DOMAIN) {
        instance.logout();
        return;
      }

      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiMwf.scopes,
          account: account,
        })
        .then((response) => {
          systemStore.setAuthenticationData(response);

          console.log("### INITIAL AUTH DATA SET");
          console.log(`### EXPIRES ON: ${response.expiresOn}`);
          //do more request here
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (
            error instanceof InteractionRequiredAuthError ||
            error instanceof BrowserAuthError
          ) {
            if (error instanceof BrowserAuthError) {
              setOtherErrorMessage(
                "Token acquisition failed due to timeout. Make sure your account has access this website."
              );
              instance.logout();
              return;
            }

            if (account && inProgress === "none") {
              instance
                .acquireTokenRedirect({
                  scopes: protectedResources.apiMwf.scopes,
                })
                .then((response) => {
                  //console.log(response);
                  //systemStore.setAuthenticationData(response);
                  //do more request here
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [account, inProgress, instance]);

  const [showLeftPanel, setShowLeftPanel] = useState(false);
  const isSmallScreen = () => window.innerWidth < 1400;

  const [checking, setChecking] = useState({
    inProgress: false,
    checked: false,
  });
  const [addedToEmployee, setAddedToEmployee] = useState(false);
  const currentCustomer = useCurrentCustomer();

  useEffect(() => {
    //For Employee Site Only.
    if (
      commonService.isEmployeeSite &&
      loginStatus.loggedIn &&
      !loginStatus.canAccessWebsite &&
      !checking.checked &&
      !checking.inProgress
    ) {
      setChecking({ inProgress: true, checked: false });
      SysServices.http.fetcher
        .post("/UserInitialize")
        .then((data) => {
          console.info("Added to Employee Portal");
          setAddedToEmployee(true);
        })
        .catch((error) => {
          const errCode = commonService.getErrorStatusCode(error);
          if (errCode === 406 || errCode === 404) {
            console.error(commonService.parseErrorMessage(error));
          } else {
            toastStore.showError("Authorization", error);
          }
        })
        .finally(() => {
          setChecking({ inProgress: false, checked: true });
        });
    }
  }, [loginStatus, checking]);

  useEffect(() => {
    if (!commonService.isEmployeeSite) {
      const token = SysServices.http.fetcher.getToken();
      if (token) {
        systemStore.setAuthDataForCustomerSite(token);
        //console.log(commonService.parseJwt(token.token));
      }
    }
  }, []);

  const [doingSomething, setDoingSomething] = useState(false);
  const markPrompt2faAsked = async (goto2fa: boolean) => {
    setDoingSomething(true);
    await SysServices.http.customerUser
      .askedAboutTwoFactor(SysServices.http.fetcher.getEmailFromToken())
      .then((data) => {})
      .catch((err) => {})
      .finally(() => {
        setDoingSomething(false);
        if (goto2fa) {
          navigate("/2fa");
        }
      });
  };

  const forQa =
    //window.location.host === "localhost:3000" ||
    window.location.host === "mwf-customer-prod-rc-v2-qa.azurewebsites.net" ||
    window.location.host === "mwf-employee-prod-rc-v2-qa.azurewebsites.net";

  return (
    <>
      {(doingSomething || checking.inProgress) && (
        <CommonSpinner overlay={true} message="Please wait..."></CommonSpinner>
      )}

      <div
        className={`layout ${showLeftPanel ? "showLeftPanel" : ""} ${
          forQa ? "qa" : ""
        }`}
      >
        <div className="layout-top">
          {loginStatus.loggedIn && (
            <NavBar
              toggle={() => {
                setShowLeftPanel(!showLeftPanel);
              }}
            ></NavBar>
          )}
        </div>
        <div className="layout-bottom">
          {!loginStatus.loggedIn && (
            <>
              {!otherErrorMessage && (
                <div className="cannot-access-website">loading...</div>
              )}
              {!!otherErrorMessage && (
                <div className="cannot-access-website">
                  <div className="pb-2 text-danger">{otherErrorMessage}</div>
                  <div>redirecting...</div>
                </div>
              )}
            </>
          )}

          {loginStatus.canAccessWebsite && !loginStatus.mustDoSomething && (
            <>
              <ChatBot key={currentCustomer?.id || ""}></ChatBot>
              <div
                className={`overlay ${
                  showLeftPanel && isSmallScreen() ? "show" : ""
                }`}
                onClick={() => setShowLeftPanel(false)}
              ></div>
              <div
                className={`left-navbar ${showLeftPanel ? "show" : ""}`}
                onMouseOver={() => {
                  if (isSmallScreen()) {
                    setShowLeftPanel(true);
                  }
                }}
                onMouseLeave={() => setShowLeftPanel(false)}
              >
                <SideNav
                  toggle={() => {
                    setShowLeftPanel(!showLeftPanel);
                  }}
                ></SideNav>
              </div>
            </>
          )}

          {loginStatus.loggedIn && (
            <div
              onScroll={(e) => {
                systemStore.setMainContainerScrollTop(
                  (e.target as any).scrollTop
                );
              }}
              className={`main-container ${
                message?.isVisible ? "system-message-visible" : ""
              }`}
              style={
                loginStatus.canAccessWebsite && !loginStatus.mustDoSomething
                  ? {}
                  : { margin: 0 }
              }
            >
              <div className="main-body">
                {message?.isVisible && (
                  <SystemBanner
                    message={message.globalMessage}
                    error={message.error}
                    warning={message.warning}
                  />
                )}

                {loginStatus.canAccessWebsite && <>{children}</>}

                {loginStatus.canAccessWebsite &&
                  !loginStatus.is2faRequired &&
                  !loginStatus.mustDoSomething &&
                  prompt2fa && (
                    <ConfirmDialog
                      show={true}
                      title="Enable Two-Factor Authentication"
                      message="We recommend enabling Two-Factor Authentication in your account. <br/>Click <strong>'Continue'</strong> to get started."
                      buttons={[
                        {
                          label: "Cancel",
                          value: "cancel",
                          variant: "secondary",
                        },
                        {
                          label: "Continue",
                          value: "continue",
                          variant: "primary",
                        },
                      ]}
                      done={(rtn) => {
                        SysServices.http.fetcher.setPrompt2faAsked();
                        markPrompt2faAsked(rtn === "continue");
                        setPrompt2fa(false);
                      }}
                    ></ConfirmDialog>
                  )}

                {!loginStatus.canAccessWebsite && addedToEmployee && (
                  <div className="text-center">
                    <h5 className="text-primary">
                      <div className="mb-2">
                        <i className="fa fa-info-circle fa-2x"></i>
                      </div>
                      We have added you to the Employee Portal.
                    </h5>
                    <div className="mt-2">
                      Please click{" "}
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          instance.loginRedirect(loginRequest);
                        }}
                      >
                        here to login
                      </a>
                      .
                    </div>
                  </div>
                )}
                {!loginStatus.canAccessWebsite &&
                  !addedToEmployee &&
                  !checking.inProgress && (
                    <div className="cannot-access-website text-danger">
                      <div className="mb-2">
                        <i className="fa fa-warning fa-2x"></i>
                      </div>
                      You do not yet have access to the site. Please contact the
                      site administrator to be granted access.
                      <div className="mt-2 text-black">
                        Click{" "}
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            instance.loginRedirect(loginRequest);
                          }}
                        >
                          here to re-login
                        </a>{" "}
                        once the administrator has granted you access
                      </div>
                    </div>
                  )}
              </div>
              <div className="main-footer flex gap-15">
                <span className="flex-1">
                  {new Date().getFullYear()} © Midwest Foods Inc. Copyright of
                  Midwest Foods
                </span>

                <span className="hide-on-print">v{packageInfo.version}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Layout;
