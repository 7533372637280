export interface IAgingSummaryDto {
    total: number;
    totalOverDue: number;
    days30: number;
    days60: number;
    days90: number;
    days120: number;
    daysOver120: number;
}
export interface IApiCustomerDetailDto {
    customerNumber: string;
    customerName: string;
}
export interface IApiIntegrationDetailsDto {
    description: string;
    allCustomers: boolean;
    apiIntegrationScopes: IApiScopeDetailDto[];
    apiCustomerDetails: IApiCustomerDetailDto[];
}
export interface IApiIntegrationOutputDto {
    name: string;
    active: boolean;
    apiIntegrationDetailsDto: IApiIntegrationDetailsDto;
    id: string;
    apiKey: string;
    acceptIntegrationTermsLink: string;
    apiTermsOfUsageOutputDto: IApiTermsOfUsageOutputDto;
}
export interface IApiScopeDetailDto {
    apiIntegrationScope: ApiIntegrationScopesEnum;
}
export interface IApiTermsOfUsageInputDto {
    name: string;
    emailAddress: string;
}
export interface IApiTermsOfUsageOutputDto {
    name: string;
    emailAddress: string;
    dateAccepted: Date;
    ipAddress: string;
}
export interface ICartDto {
    cartItemDtos: ICartItemDto[];
}
export interface ICartItemDto {
    itemId: string;
    description: string;
    quantity: number;
}
export interface ICatalogCategoryOutputDto {
    id: number;
    displayId: string;
    displayDescription: string;
}
export interface ICatalogFeaturedCategoryOutputDto {
    id: string;
    displayDescription: string;
}
export interface ICatalogGridDto {
    totalRecords: number;
    erpInventoryItemOutputDtos: IErpInventoryItemOutputDto[];
}
export interface ICatalogProductSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    categoryId: string;
}
export interface IChangePasswordRequestDto {
    currentPassword: string;
    newPassword: string;
}
export interface IContactUsInputDto {
    subject: string;
    message: string;
    orderGuideId: number;
    nsId: number;
}
export interface ICreditMemoGridDto {
    totalRecords: number;
    creditMemoGridItemDtos: ICreditMemoGridItemDto[];
}
export interface ICreditMemoGridItemDto {
    sqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
}
export interface ICreditMemoOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    dueDate: Date;
    tranAmount: number;
    referenceInvoiceNumber: string;
    totalOrderAmount: number;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface ICustomerInvoicePaymentDto {
    sqlId: number;
    transactionDate: Date;
    paymentInformation: IPaymentInformationDto;
}
export interface ICustomerInvoicePaymentGridDto {
    totalRecords: number;
    customerInvoicePaymentDtos: ICustomerInvoicePaymentDto[];
}
export interface ICustomerOrderingSummaryCategoryOutputDto {
    categoryName: string;
    amount: number;
    quantity: number;
    percentByAmount: number;
    percentByQuantity: number;
}
export interface ICustomerOrderingSummaryFilterDto {
    dateRange: CustomerOrderingSummaryDateRangeEnum;
}
export interface ICustomerOrderingSummaryOutputDto {
    lastPeriodData: ICustomerOrderingSummaryCategoryOutputDto[];
    currentPeriodData: ICustomerOrderingSummaryCategoryOutputDto[];
}
export interface ICustomerSalesPersonDto {
    email: string;
    personName: string;
}
export interface ICustomerUserTwoFactorSecretOutputDto {
    secret: string;
    qrCode: string;
}
export interface IDashboardAnnouncementForDisplayDto {
    title: string;
    content: string;
    instructions: string;
    announcementType: DashboardAnnouncementTypeEnum;
    erpInventoryItemOutputDtos: IErpInventoryItemOutputDto[];
}
export interface IDownSearchesDto {
    isCreditMemoDown: boolean;
    isCustomerDown: boolean;
    isInventoryItemsDown: boolean;
    isInvoiceDown: boolean;
    isSalesOrdersDown: boolean;
}
export interface IDummyOutputDto {
    ftpUploadFileRequestInputDto: IFtpUploadFileRequestInputDto;
    datePeriodEnum: DatePeriodEnum;
    userRolesEnum: UserRolesEnum;
}
export interface IErpCustomerCustomerSiteOutputDto {
    erpCustomerNumber: string;
    customerName: string;
    lastOrderGuideUpdate: Date;
    email: string;
    alternateEmail: string;
    phone: string;
    sqlId: number;
    totalAmountOrdered: number;
    numberOfOrdersPlaced: number;
    billingAddressText: string;
    shippingAddressText: string;
    routeId: string;
    routeInfo: string;
    grabAndGoCustomer: boolean;
    orderGuideAllowedYn: boolean;
}
export interface IErpInventoryGridDto {
    totalRecords: number;
    erpInventoryItemsDtos: IErpInventoryItemsDto[];
}
export interface IErpInventoryItemCatalogDetailOutputDto {
    sqlId: number;
    erpInternalId: string;
    itemId: string;
    displayName: string;
    description: string;
    activeYn: boolean;
    itemGroup: string;
    erpLastUpdatedDate: Date;
    lastUpdatedDate: Date;
    lastModifiedDate: Date;
    uom: string;
    purchaseLabel: string;
    category: string;
    subCategory: string;
    packSize: string;
    manufactureItemNumber: string;
    manufacturedName: string;
    manufacturerId: string;
    categoryId: string;
    packQuantity: string;
    erpImageUrl: string;
    unitPrice: number;
    webLongDescription: string;
}
export interface IErpInventoryItemOutputDto {
    sqlId: number;
    erpInternalId: string;
    itemId: string;
    displayName: string;
    description: string;
    activeYn: boolean;
    itemGroup: string;
    erpLastUpdatedDate: Date;
    lastUpdatedDate: Date;
    lastModifiedDate: Date;
    uom: string;
    purchaseLabel: string;
    category: string;
    subCategory: string;
    packSize: string;
    manufactureItemNumber: string;
    manufacturedName: string;
    manufacturerId: string;
    categoryId: string;
    packQuantity: string;
    erpImageUrl: string;
    unitPrice: number;
}
export interface IErpInventoryItemsDto {
    itemId: string;
    description: string;
    uom: string;
    quantity: number;
    unitPrice: number;
    leadDays: number;
}
export interface IErpInventorySearchDto {
    search: string;
    orderByEnum: OrderByEnum;
}
export interface IErrorOutputDto {
    fieldName: string;
    message: string;
}
export interface IForgotPasswordDto {
    email: string;
}
export interface IFtpUploadFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    toFolder: string;
    toFileName: string;
}
export interface IGeneralDocumentationDisplayGridDto {
    totalRecords: number;
    generalDocumentationDisplayOutputDtos: IGeneralDocumentationDisplayOutputDto[];
}
export interface IGeneralDocumentationDisplayOutputDto {
    sqlId: string;
    sequence: number;
    title: string;
    subject: string;
    content: string;
    publishedOn: Date;
}
export interface IGeneralDocumentationSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    onlyPublished: boolean;
}
export interface IGlobalSearchGridOutputDto {
    downSearchesDto: IDownSearchesDto;
    globalSearchOutputDtos: IGlobalSearchOutputDto[];
}
export interface IGlobalSearchInputDto {
    query: string;
    isCreditMemos: boolean;
    isCustomers: boolean;
    isInventoryItems: boolean;
    isInvoices: boolean;
    isOrders: boolean;
    isHowTos: boolean;
}
export interface IGlobalSearchOutputDto {
    id: string;
    searchType: MartenTypeEnum;
    name: string;
    customerNumber: string;
    sqlId: number;
}
export interface IGlobalSearchPreviewOutputDto {
    globalSearchOutputDtos: IGlobalSearchOutputDto[];
    downSearchesDto: IDownSearchesDto;
}
export interface IHttpErrorOutputDto {
    errors: IErrorOutputDto[];
    statusCode: number;
}
export interface IInvoiceAddressDto {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
}
export interface IInvoiceGridForCustomerSiteDto {
    totalRecords: number;
    invoiceGridItemDtos: IInvoiceGridItemDto[];
    allowInvoicePayment: boolean;
}
export interface IInvoiceGridItemDto {
    sqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
    dueDate: Date;
    amountRemaining: number;
    daysOverDue: number;
    paidAmount: number;
}
export interface IInvoiceOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    dueDate: Date;
    tranAmount: number;
    daysOverDue: number;
    amountRemaining: number;
    paidAmount: number;
    webOrderNumber: string;
    totalOrderAmount: number;
    invoiceAddressDto: IInvoiceAddressDto;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface IInvoicePaymentDto {
    invoiceNumber: string;
    invoiceAmount: number;
    amountPaid: number;
}
export interface ILocalSpendBySourceReportOutputDto {
    itemCode: string;
    farm: string;
    itemCategory: string;
    description: string;
    packTypeUom: string;
    quantity: number;
    amount: number;
    averagePrice: number;
    purchaseWeight: number;
}
export interface ILookupIntDto {
    label: string;
    value: number;
}
export interface IMultiLookupIntDto {
    enumName: string;
    lookupIntDtos: ILookupIntDto[];
}
export interface INonDeliveryDateDto {
    nonDeliveryDate: Date;
}
export interface INonDeliveryDateGridDto {
    totalRecords: number;
    nonDeliveryDateDtos: INonDeliveryDateDto[];
    nonDeliveryDateDtosToDisplay: INonDeliveryDateDto[];
    mondayDelivery: boolean;
    tuesdayDelivery: boolean;
    wednesdayDelivery: boolean;
    thursdayDelivery: boolean;
    fridayDelivery: boolean;
    saturdayDelivery: boolean;
    sundayDelivery: boolean;
    minDeliveryAmount: number;
    restockingFee: number;
    companyTime: Date;
}
export interface IOrderGuideGridDto {
    totalRecords: number;
    categories: string[];
    orderGuideGridItemDtos: IOrderGuideGridItemDto[];
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placedByEmail: string;
    totalOrderAmount: number;
    erpOrderNumber: string;
    orderStatus: number;
}
export interface IOrderGuideGridItemDto {
    category: string;
    description: string;
    fromOg: boolean;
    itemCode: string;
    itemDescription: string;
    itemId: string;
    leadDays: number;
    mwfInvoiceCategory: string;
    orderGuideOrdersId: number;
    quantity: number;
    totalPrice: number;
    uniqueValue: number;
    unitPrice: number;
    uom: string;
    uomChoices: string;
}
export interface IOrderGuideInputDto {
    orderGuideGridItemDtos: IOrderGuideItemInputDto[];
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placeSameDay: boolean;
}
export interface IOrderGuideItemInputDto {
    category: string;
    fromOg: boolean;
    itemCode: string;
    itemDescription: string;
    mwfInvoiceCategory: string;
    orderGuideOrdersId: number;
    quantity: number;
    unitPrice: number;
    uom: string;
    uomChoices: string;
    uniqueValue: number;
}
export interface IOrderLineOutputDto {
    sqlId: number;
    orderId: number;
    itemCode: string;
    itemDescription: string;
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    uom: string;
    uomChoices: string;
    category: string;
    leadDays: number;
}
export interface IOrderOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placedByEmail: string;
    totalOrderAmount: number;
    localOrderYn: boolean;
    erpOrderNumber: string;
    orderStatus: number;
    dueDate: Date;
    amountDue: number;
    daysOverDue: number;
    externalOrderNumber: string;
    comment: string;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface IPasswordDto {
    password: string;
}
export interface IPaymentInformationDto {
    confirmationNumber: string;
    returnMessage: string;
    totalPaid: number;
    invoicesPayments: IInvoicePaymentDto[];
}
export interface IRefreshTokenDto {
    refreshToken: string;
    email: string;
    sessionId: string;
}
export interface IResetPasswordDto {
    email: string;
    resetPasswordToken: string;
    password: string;
}
export interface ISalesLocalSearchDto {
    fromDate: Date;
    toDate: Date;
}
export interface ISalesLocalVsOutputDto {
    fromDate: Date;
    toDate: Date;
    numberOfDays: number;
    totalOrderAmount: number;
    totalLocalAmount: number;
}
export interface ISalesOrderGridDto {
    totalRecords: number;
    salesOrderGridItemDtos: ISalesOrderGridItemDto[];
}
export interface ISalesOrderGridInputDto {
    search: string;
    orderByEnum: OrderByEnum;
    salesOrderGridOrderByEnum: SalesOrderGridOrderByEnum;
    fromDate: Date;
    toDate: Date;
}
export interface ISalesOrderGridItemDto {
    nsSqlId: number;
    portalSqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
    dueDate: Date;
    amountDue: number;
    daysOverDue: number;
    orderPlacedBy: string;
    deliveryDate: Date;
}
export interface ISiteDocumentationDisplayGridDto {
    totalRecords: number;
    siteDocumentationDisplayOutputDtos: ISiteDocumentationDisplayOutputDto[];
}
export interface ISiteDocumentationDisplayOutputDto {
    sqlId: string;
    sequence: number;
    title: string;
    subject: string;
    content: string;
    publishedOn: Date;
}
export interface ISiteDocumentationSearchInputDto {
    search: string;
    orderByEnum: OrderByEnum;
    page: number;
    pageSize: number;
}
export interface ISiteDownOutputDto {
    globalMessage: string;
    startDate: Date;
    endDate: Date;
    isVisible: boolean;
}
export interface ISiteMessageOutputDto {
    globalMessage: string;
    startDate: Date;
    endDate: Date;
    announcement: boolean;
    warning: boolean;
    error: boolean;
    isVisible: boolean;
}
export interface ISiteVersionOutputDto {
    versionNumber: string;
    forceReload: boolean;
}
export interface ISurveyInputDto {
    surveyToken: string;
    deliveryRating: number;
    productQualityRating: number;
    customerServiceRating: number;
    onlineOrderingRating: number;
    salesRepRating: number;
    overallCompanyRating: number;
    comment: string;
}
export interface ISurveyItemOutputDto {
    surveyToken: string;
    sentOn: Date;
    receivedOn: Date;
    deliveryRating: number;
    productQualityRating: number;
    customerServiceRating: number;
    onlineOrderingRating: number;
    salesRepRating: number;
    overallCompanyRating: number;
    comment: string;
    customerName: string;
    customerNumber: string;
    email: string[];
}
export interface ITokenDto {
    token: string;
    refreshToken: string;
    refreshTokenExpiration: Date;
    sessionId: string;
}
export interface IUserLoginDto {
    email: string;
    password: string;
}
export interface IUserTwoFactorLoginDto {
    email: string;
    code: string;
    sessionId: string;
    rememberMe: boolean;
}
export interface IFileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
export interface IAgingSummaryDto {
    total: number;
    totalOverDue: number;
    days30: number;
    days60: number;
    days90: number;
    days120: number;
    daysOver120: number;
}
export interface IApiCustomerDetailDto {
    customerNumber: string;
    customerName: string;
}
export interface IApiIntegrationDetailsDto {
    description: string;
    allCustomers: boolean;
    apiIntegrationScopes: IApiScopeDetailDto[];
    apiCustomerDetails: IApiCustomerDetailDto[];
}
export interface IApiIntegrationEmailInputDto {
    id: string;
    email: string;
}
export interface IApiIntegrationGridItemDto {
    id: string;
    name: string;
    description: string;
}
export interface IApiIntegrationGridOutputDto {
    totalRecords: number;
    apiIntegrationGridItemDtos: IApiIntegrationGridItemDto[];
}
export interface IApiIntegrationInputDto {
    name: string;
    active: boolean;
    apiIntegrationDetailsDto: IApiIntegrationDetailsDto;
}
export interface IApiIntegrationOutputDto {
    name: string;
    active: boolean;
    apiIntegrationDetailsDto: IApiIntegrationDetailsDto;
    id: string;
    apiKey: string;
    acceptIntegrationTermsLink: string;
    apiTermsOfUsageOutputDto: IApiTermsOfUsageOutputDto;
}
export interface IApiScopeDetailDto {
    apiIntegrationScope: ApiIntegrationScopesEnum;
}
export interface IApiTermsOfUsageOutputDto {
    name: string;
    emailAddress: string;
    dateAccepted: Date;
    ipAddress: string;
}
export interface IAsSalesRepGridDto {
    totalRecords: number;
    asSalesRepOutputDtos: IAsSalesRepOutputDto[];
}
export interface IAsSalesRepOutputDto {
    email: string;
    personName: string;
    isSalesPerson: boolean;
}
export interface IAsSalesRepSearchInputDto {
    searchCriteria: string;
}
export interface IAzureFunctionsOnOffScheduleInsertDto {
    sectionName: string;
    fromDateTimeString: string;
    toDateTimeString: string;
}
export interface IAzureFunctionsOnOffScheduleUpdateDto {
    fromDateTimeString: string;
    toDateTimeString: string;
}
export interface IBatchJobExpressionInputDto {
    expression: string;
}
export interface IBatchJobScheduleInsertDto {
    batchJobScheduleTypeEnum: BatchJobScheduleTypeEnum;
    functionName: string;
    url: string;
    functionKey: string;
    triggerName: string;
    isDurable: boolean;
}
export interface IBatchJobScheduleItemOutputDto {
    expression: string;
    description: string;
    nextExecutions: Date[];
}
export interface IBatchJobScheduleUpdateDto {
    functionName: string;
    url: string;
    functionKey: string;
    triggerName: string;
    isDurable: boolean;
}
export interface IBatchJobsScheduleEntityGetOutputDto {
    batchJobScheduleTypeEnum: BatchJobScheduleTypeEnum;
    rowKey: string;
    functionName: string;
    isDurable: boolean;
    url: string;
    triggerName: string;
}
export interface IBatchJobsScheduleEntityOutputDto {
    batchJobScheduleTypeEnum: BatchJobScheduleTypeEnum;
    rowKey: string;
    functionName: string;
    isDurable: boolean;
}
export interface IBatchReRequestCreditMemoGridOutputDto {
    totalRecords: number;
    batchReRequestCreditMemoOutputDtos: IBatchReRequestCreditMemoOutputDto[];
}
export interface IBatchReRequestCreditMemoOutputDto {
    creditMemoNumber: string;
    requestedOn: Date;
    processedOn: Date;
    firstName: string;
    lastName: string;
    buyingGroups: string;
    error: string;
}
export interface IBatchReRequestInvoiceGridOutputDto {
    totalRecords: number;
    batchReRequestInvoiceOutputDtos: IBatchReRequestInvoiceOutputDto[];
}
export interface IBatchReRequestInvoiceOutputDto {
    invoiceNumber: string;
    requestedOn: Date;
    processedOn: Date;
    firstName: string;
    lastName: string;
    buyingGroups: string;
    error: string;
}
export interface IBatchResubmitInvoiceInputDto {
    invoiceNumbers: string[];
    buyingGroupSqlIds: number[];
}
export interface IBuyingGroupFileDisplayOutputDto {
    filename: string;
    lastModifiedOn: Date;
}
export interface IBuyingGroupFileListInputDto {
    areaId: string;
    continuationToken: string;
}
export interface IBuyingGroupFileListOutputDto {
    buyingGroupFileDisplayOutputDtos: IBuyingGroupFileDisplayOutputDto[];
    continuationToken: string;
}
export interface IBuyingGroupMappingGridOutputDto {
    totalRecords: number;
    buyingGroupMappingOutputDtos: IBuyingGroupMappingOutputDto[];
}
export interface IBuyingGroupMappingInputDto {
    buyingGroupId: number;
    buyingGroupItemId: string;
    netSuiteItemId: string;
    isMappingActive: boolean;
}
export interface IBuyingGroupMappingOutputDto {
    sqlId: number;
    buyingGroupItemId: string;
    netSuiteItemId: string;
    isMappingActive: boolean;
}
export interface IBuyingGroupMappingUpdatetDto {
    isMappingActive: boolean;
}
export interface IBuyingGroupOutputDto {
    sqlId: number;
    buyingGroupName: string;
    customerIndicator: string;
}
export interface IBuyingGroupStorageAreaOutputDto {
    sqlId: number;
    buyingGroupName: string;
    descriptionOfStorage: string;
    containerName: string;
}
export interface IBuyingGroupTotalSummaryOutputDto {
    sqlId: number;
    buyingGroupSqlId: number;
    buyingGroupName: string;
    thisMonthTotalNo: number;
    thisMonthTotalAmount: number;
    lastMonthTotalNo: number;
    lastMonthTotalAmount: number;
    prevTotalNo: number;
    prevTotalAmount: number;
    noOfAccounts: number;
}
export interface IBuyingGroupWithMappingsGridItemDto {
    buyingGroupName: string;
    buyingGroupId: number;
}
export interface IBuyingGroupWithMappingsGridOutputDto {
    totalRecords: number;
    buyingGroupWithMappingsGridItemDtos: IBuyingGroupWithMappingsGridItemDto[];
}
export interface ICartDto {
    cartItemDtos: ICartItemDto[];
}
export interface ICartItemDto {
    itemId: string;
    description: string;
    quantity: number;
}
export interface ICatalogCategoryOutputDto {
    id: number;
    displayId: string;
    displayDescription: string;
}
export interface ICatalogFeaturedCategoryOutputDto {
    id: string;
    displayDescription: string;
}
export interface ICatalogGridDto {
    totalRecords: number;
    erpInventoryItemOutputDtos: IErpInventoryItemOutputDto[];
}
export interface ICatalogProductSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    categoryId: string;
}
export interface IConfigAzureTableItemInsertDto {
    section: string;
    item: string;
    value: string;
}
export interface IConfigAzureTableItemOutputDto {
    section: string;
    item: string;
    value: string;
    fromDateTimeString: string;
    toDateTimeString: string;
}
export interface IConfigAzureTableItemUpdateDto {
    value: string;
}
export interface ICreditMemoGridDto {
    totalRecords: number;
    creditMemoGridItemDtos: ICreditMemoGridItemDto[];
}
export interface ICreditMemoGridItemDto {
    sqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
}
export interface ICreditMemoOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    dueDate: Date;
    tranAmount: number;
    referenceInvoiceNumber: string;
    totalOrderAmount: number;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface ICsrTotalSummaryOutputDto {
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    totalOrdersNo: number;
    totalAmount: number;
    noOfAccounts: number;
}
export interface ICurrentSystemActivityDto {
    createdOn: Date;
    action: string;
    max: number;
    soFar: number;
}
export interface ICustomerBidSheetOrderCreateDto {
    itemCode: string;
    sequence: number;
    customerNumber: string;
}
export interface ICustomerBidSheetOrderGridDto {
    totalRecords: number;
    customerBidSheetOrderOutputDtos: ICustomerBidSheetOrderOutputDto[];
}
export interface ICustomerBidSheetOrderOutputDto {
    itemCode: string;
    sequence: number;
    itemDescription: string;
    sqlId: number;
}
export interface ICustomerBidSheetOrderUpdateDto {
    sequence: number;
}
export interface ICustomerInvoicePaymentDto {
    sqlId: number;
    transactionDate: Date;
    paymentInformation: IPaymentInformationDto;
}
export interface ICustomerInvoicePaymentGridDto {
    totalRecords: number;
    customerInvoicePaymentDtos: ICustomerInvoicePaymentDto[];
}
export interface ICustomerOrderGuideChildrenGridDto {
    totalRecords: number;
    customerOrderGuideChildrenGridItemDtos: ICustomerOrderGuideChildrenGridItemDto[];
}
export interface ICustomerOrderGuideChildrenGridItemDto {
    customerNumber: string;
    customerName: string;
}
export interface ICustomerOrderGuideUpdateHistoryDto {
    erpCustomerNumber: string;
    dateRequested: Date;
    dateProcessed: Date;
    requestor: string;
}
export interface ICustomerOrderingSummaryCategoryOutputDto {
    categoryName: string;
    amount: number;
    quantity: number;
    percentByAmount: number;
    percentByQuantity: number;
}
export interface ICustomerOrderingSummaryFilterDto {
    dateRange: CustomerOrderingSummaryDateRangeEnum;
}
export interface ICustomerOrderingSummaryOutputDto {
    lastPeriodData: ICustomerOrderingSummaryCategoryOutputDto[];
    currentPeriodData: ICustomerOrderingSummaryCategoryOutputDto[];
}
export interface ICustomerSalesPersonDto {
    email: string;
    personName: string;
}
export interface ICustomerSalesSummaryBySalesRepGridOutputDto {
    totalRecords: number;
    customerSalesSummaryBySalesRepOutputDtos: ICustomerSalesSummaryBySalesRepOutputDto[];
}
export interface ICustomerSalesSummaryBySalesRepOutputDto {
    email: string;
    erpCustomerNumber: string;
    erpCustomerId: number;
    thisMonthAmount: number;
    lastMonthAmount: number;
    previousMonthAmount: number;
    threeMonthAmount: number;
    fourMonthAmount: number;
    fiveMonthAmount: number;
    sixMonthAmount: number;
    customerName: string;
}
export interface ICustomerUpdateDto {
    customerNumber: string;
}
export interface IDashboardAnnouncementAdminOutputDto {
    sqlId: string;
    title: string;
    activeYn: boolean;
    lastUpdatedOn: Date;
    lastUpdatedBy: string;
    showFromDate: Date;
    showToDate: Date;
    forLeftHandSideYn: boolean;
    content: string;
    recipeInstructions: string;
    announcementType: DashboardAnnouncementTypeEnum;
    erpInventoryItemOutputDtos: IErpInventoryItemOutputDto[];
}
export interface IDashboardAnnouncementCreateDto {
    title: string;
    activeYn: boolean;
    showFromDate: Date;
    showToDate: Date;
    forLeftHandSideYn: boolean;
    content: string;
    recipeInstructions: string;
    itemsToAdd: string[];
    announcementType: DashboardAnnouncementTypeEnum;
}
export interface IDashboardAnnouncementDisplayAdminDto {
    sqlId: string;
    title: string;
    activeYn: boolean;
    showFromDate: Date;
    showToDate: Date;
    announcementType: DashboardAnnouncementTypeEnum;
}
export interface IDashboardAnnouncementDisplayAdminGridDto {
    totalRecords: number;
    dashboardAnnouncementDisplayAdminDtos: IDashboardAnnouncementDisplayAdminDto[];
}
export interface IDashboardAnnouncementForDisplayDto {
    title: string;
    content: string;
    instructions: string;
    announcementType: DashboardAnnouncementTypeEnum;
    erpInventoryItemOutputDtos: IErpInventoryItemOutputDto[];
}
export interface IDashboardAnnouncementSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    dashboardAnnouncementActiveSearchEnum: DashboardAnnouncementActiveSearchEnum;
    showFromDate: Date;
    showToDate: Date;
}
export interface IDashboardAnnouncementUpdateDto {
    title: string;
    activeYn: boolean;
    showFromDate: Date;
    showToDate: Date;
    forLeftHandSideYn: boolean;
    content: string;
    recipeInstructions: string;
    itemsToAdd: string[];
    itemsToDelete: string[];
}
export interface IDownSearchesDto {
    isCreditMemoDown: boolean;
    isCustomerDown: boolean;
    isInventoryItemsDown: boolean;
    isInvoiceDown: boolean;
    isSalesOrdersDown: boolean;
}
export interface IDummyOutputDto {
    ftpUploadFileRequestInputDto: IFtpUploadFileRequestInputDto;
    datePeriodEnum: DatePeriodEnum;
    userRolesEnum: UserRolesEnum;
}
export interface IDurableFunctionStatusDto {
    instanceId: string;
    createdTime: Date;
    lastUpdatedTime: Date;
    runtimeStatus: string;
    runningStatusEnum: number;
    customStatus: string;
    output: string;
}
export interface IErpCustomerEmployeeSiteOutputDto {
    erpCustomerNumber: string;
    customerName: string;
    lastOrderGuideUpdate: Date;
    email: string;
    alternateEmail: string;
    phone: string;
    sqlId: number;
    totalAmountOrdered: number;
    numberOfOrdersPlaced: number;
    billingAddressText: string;
    shippingAddressText: string;
    routeId: string;
    routeInfo: string;
    grabAndGoCustomer: boolean;
    buyingGroupId: number;
    activeYn: boolean;
    welcomeEmailSentYn: boolean;
    erpInternalCustomerNumber: string;
    webPassword: string;
    newYn: boolean;
    orderGuideAllowedYn: boolean;
    custEntityBidSheetCustomerId: string;
    lastUpdatedFromErp: Date;
    priceLastUpdatedDateTime: Date;
    buyingGroupUnitId: string;
    erpComments: string;
    noSaturdayDelivery: boolean;
    allowSendSurvey: boolean;
    reportGroup: string;
    buyingGroup: string;
    outgoingInvoiceIntegration: string;
    outgoingSalesOrderIntegration: string;
    masterAccount: string;
    masterAccountCustomerNumber: string;
    hasOrderGuideChildren: boolean;
}
export interface IErpCustomerGridDto {
    totalRecords: number;
    erpCustomerGridItemDtos: IErpCustomerGridItemDto[];
}
export interface IErpCustomerGridItemDto {
    sqlId: number;
    erpCustomerNumber: string;
    customerName: string;
    email: string;
    phone: string;
}
export interface IErpInventoryGridDto {
    totalRecords: number;
    erpInventoryItemsDtos: IErpInventoryItemsDto[];
}
export interface IErpInventoryItemCatalogDetailOutputDto {
    sqlId: number;
    erpInternalId: string;
    itemId: string;
    displayName: string;
    description: string;
    activeYn: boolean;
    itemGroup: string;
    erpLastUpdatedDate: Date;
    lastUpdatedDate: Date;
    lastModifiedDate: Date;
    uom: string;
    purchaseLabel: string;
    category: string;
    subCategory: string;
    packSize: string;
    manufactureItemNumber: string;
    manufacturedName: string;
    manufacturerId: string;
    categoryId: string;
    packQuantity: string;
    erpImageUrl: string;
    unitPrice: number;
    webLongDescription: string;
}
export interface IErpInventoryItemOutputDto {
    sqlId: number;
    erpInternalId: string;
    itemId: string;
    displayName: string;
    description: string;
    activeYn: boolean;
    itemGroup: string;
    erpLastUpdatedDate: Date;
    lastUpdatedDate: Date;
    lastModifiedDate: Date;
    uom: string;
    purchaseLabel: string;
    category: string;
    subCategory: string;
    packSize: string;
    manufactureItemNumber: string;
    manufacturedName: string;
    manufacturerId: string;
    categoryId: string;
    packQuantity: string;
    erpImageUrl: string;
    unitPrice: number;
}
export interface IErpInventoryItemsDto {
    itemId: string;
    description: string;
    uom: string;
    quantity: number;
    unitPrice: number;
    leadDays: number;
}
export interface IErpInventorySearchDto {
    search: string;
    orderByEnum: OrderByEnum;
}
export interface IErrorDeleteManyInputDto {
    rowPartitionKeysList: IRowPartitionKeysDto[];
}
export interface IErrorOutputDto {
    fieldName: string;
    message: string;
}
export interface IErrorTableGridOutputDto {
    totalRecords: number;
    errorTableOutputDtos: IErrorTableOutputDto[];
}
export interface IErrorTableOutputDto {
    partitionKey: string;
    rowKey: string;
    dateTime: Date;
    subject: string;
    body: string;
}
export interface IExecutiveSummaryAmountOutputDto {
    totalInvoicesPaid: number;
    totalAmountPaid: number;
    totalInvoice: number;
}
export interface IExecutiveSummaryDetailOutputDto {
    sqlId: number;
    customerNumber: string;
    customerName: string;
    transactionDate: Date;
    paymentInformation: IPaymentInformationDto;
}
export interface IExecutiveSummaryFilterDto {
    datePeriod: ExecutiveSummaryDatePeriodEnum;
    startDate: Date;
    endDate: Date;
    customerNumber: string;
}
export interface IExecutiveSummaryOutputDto {
    totalRecords: number;
    executiveSummaryAmountOutputDto: IExecutiveSummaryAmountOutputDto;
    executiveSummaryPaymentsOutputDto: IExecutiveSummaryPaymentsOutputDto;
    executiveSummaryDetailOutputDtos: IExecutiveSummaryDetailOutputDto[];
}
export interface IExecutiveSummaryPaymentsOutputDto {
    totalSuccessfulPayments: number;
    totalDeclinedInvoices: number;
}
export interface IFtpContentOutputDto {
    ftpObjectTypeEnum: FtpObjectTypeEnum;
    name: string;
    date: Date;
}
export interface IFtpCopyFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    fromFile: string;
    toFile: string;
}
export interface IFtpDeleteFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    fileName: string;
}
export interface IFtpDownloadFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    fileName: string;
}
export interface IFtpListRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    folderName: string;
}
export interface IFtpMoveFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    fromFileName: string;
    toFileName: string;
}
export interface IFtpUploadFileRequestInputDto {
    ftpNameEnum: FtpNameEnum;
    toFolder: string;
    toFileName: string;
}
export interface IGeneralDocumentationActiveStatusDto {
    active: boolean;
}
export interface IGeneralDocumentationAdminOutputDto {
    sqlId: string;
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    activeYn: boolean;
    lastUpdatedOn: Date;
    lastUpdatedBy: string;
    publishedOn: Date;
}
export interface IGeneralDocumentationDisplayAdminDto {
    sqlId: string;
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    publishedOn: Date;
}
export interface IGeneralDocumentationDisplayAdminGridDto {
    totalRecords: number;
    generalDocumentationDisplayAdminDtos: IGeneralDocumentationDisplayAdminDto[];
}
export interface IGeneralDocumentationDisplayGridDto {
    totalRecords: number;
    generalDocumentationDisplayOutputDtos: IGeneralDocumentationDisplayOutputDto[];
}
export interface IGeneralDocumentationDisplayOutputDto {
    sqlId: string;
    sequence: number;
    title: string;
    subject: string;
    content: string;
    publishedOn: Date;
}
export interface IGeneralDocumentationInputDto {
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    activeYn: boolean;
}
export interface IGeneralDocumentationSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    onlyPublished: boolean;
}
export interface IGlobalSearchGridOutputDto {
    downSearchesDto: IDownSearchesDto;
    globalSearchOutputDtos: IGlobalSearchOutputDto[];
}
export interface IGlobalSearchInputDto {
    query: string;
    isCreditMemos: boolean;
    isCustomers: boolean;
    isInventoryItems: boolean;
    isInvoices: boolean;
    isOrders: boolean;
    isHowTos: boolean;
}
export interface IGlobalSearchOutputDto {
    id: string;
    searchType: MartenTypeEnum;
    name: string;
    customerNumber: string;
    sqlId: number;
}
export interface IGlobalSearchPreviewOutputDto {
    globalSearchOutputDtos: IGlobalSearchOutputDto[];
    downSearchesDto: IDownSearchesDto;
}
export interface IGridOrderGuideOrdersForCsrDto {
    totalRecords: number;
    orderGuideOrderItemForCsrDtos: IOrderGuideOrderItemForCsrDto[];
}
export interface IHttpErrorOutputDto {
    errors: IErrorOutputDto[];
    statusCode: number;
}
export interface IInvoiceAddressDto {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
}
export interface IInvoiceGridDto {
    totalRecords: number;
    invoiceGridItemDtos: IInvoiceGridItemDto[];
}
export interface IInvoiceGridItemDto {
    sqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
    dueDate: Date;
    amountRemaining: number;
    daysOverDue: number;
    paidAmount: number;
}
export interface IInvoiceOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    dueDate: Date;
    tranAmount: number;
    daysOverDue: number;
    amountRemaining: number;
    paidAmount: number;
    webOrderNumber: string;
    totalOrderAmount: number;
    invoiceAddressDto: IInvoiceAddressDto;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface IInvoicePaymentDto {
    invoiceNumber: string;
    invoiceAmount: number;
    amountPaid: number;
}
export interface ILocalSpendBySourceReportOutputDto {
    itemCode: string;
    farm: string;
    itemCategory: string;
    description: string;
    packTypeUom: string;
    quantity: number;
    amount: number;
    averagePrice: number;
    purchaseWeight: number;
}
export interface ILookupIntDto {
    label: string;
    value: number;
}
export interface IMultiLookupIntDto {
    enumName: string;
    lookupIntDtos: ILookupIntDto[];
}
export interface IMwfGlobalSettingsBindingOutputDto {
    ordersEmailAddresses: string;
}
export interface IMwfGlobalSettingsBindingUpdateDto {
    ordersEmailAddresses: string;
}
export interface INonDeliveryDateDto {
    nonDeliveryDate: Date;
}
export interface INonDeliveryDateGridDto {
    totalRecords: number;
    nonDeliveryDateDtos: INonDeliveryDateDto[];
    nonDeliveryDateDtosToDisplay: INonDeliveryDateDto[];
    mondayDelivery: boolean;
    tuesdayDelivery: boolean;
    wednesdayDelivery: boolean;
    thursdayDelivery: boolean;
    fridayDelivery: boolean;
    saturdayDelivery: boolean;
    sundayDelivery: boolean;
    minDeliveryAmount: number;
    restockingFee: number;
    companyTime: Date;
}
export interface INonDeliveryDateInsertDto {
    nonDeliveryDate: Date;
}
export interface INonDeliveryDateOutputDto {
    sqlId: number;
    nonDeliveryDate: Date;
}
export interface IOrderDetailOutputDto {
    sqlId: number;
    buyingGroupId: number;
    unitNo: number;
    itemCount: number;
    lineNumber: number;
    itemCode: string;
    upc: string;
    quantity: number;
    description: string;
    brand: string;
    unitPrice: number;
    extendedPrice: number;
    comments: string;
    uom: string;
}
export interface IOrderGridOutputDto {
    totalRecords: number;
    orderHeaderOutputDtos: IOrderHeaderOutputDto[];
}
export interface IOrderGuideGridDto {
    totalRecords: number;
    categories: string[];
    orderGuideGridItemDtos: IOrderGuideGridItemDto[];
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placedByEmail: string;
    totalOrderAmount: number;
    erpOrderNumber: string;
    orderStatus: number;
}
export interface IOrderGuideGridItemDto {
    category: string;
    description: string;
    fromOg: boolean;
    itemCode: string;
    itemDescription: string;
    itemId: string;
    leadDays: number;
    mwfInvoiceCategory: string;
    orderGuideOrdersId: number;
    quantity: number;
    totalPrice: number;
    uniqueValue: number;
    unitPrice: number;
    uom: string;
    uomChoices: string;
}
export interface IOrderGuideInputDto {
    orderGuideGridItemDtos: IOrderGuideItemInputDto[];
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placeSameDay: boolean;
}
export interface IOrderGuideItemInputDto {
    category: string;
    fromOg: boolean;
    itemCode: string;
    itemDescription: string;
    mwfInvoiceCategory: string;
    orderGuideOrdersId: number;
    quantity: number;
    unitPrice: number;
    uom: string;
    uomChoices: string;
    uniqueValue: number;
}
export interface IOrderGuideOrderItemForCsrDto {
    sqlId: number;
    customerName: string;
    customerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    orderAmount: number;
}
export interface IOrderHeaderOutputDto {
    sqlId: number;
    buyingGroupId: number;
    recordType: number;
    unitNo: number;
    customerAccountNo: string;
    contractReference: string;
    orderAmount: number;
    functionalCategory: string;
    purchaseOrderLineItemIdBuyer: number;
    originalInvoiceNo: number;
    deliveryReference: string;
    customerReferenceNo: number;
    recordStatus: number;
    receivedOn: Date;
    submittedOn: Date;
    invoicedOn: Date;
    submittedToNetsuite: Date;
    submittedToBuyingGroup: Date;
    submitOrder: boolean;
    customerName: string;
    createDate: Date;
    deliveryDate: Date;
    comment: string;
    erpOrderId: string;
    erpOrderNumber: string;
    externalOrderNo: string;
    errorRead: boolean;
    buyingGroup: string;
    orderDetailOutputDtos: IOrderDetailOutputDto[];
}
export interface IOrderInErrorOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placedByEmail: string;
    totalOrderAmount: number;
    localOrderYn: boolean;
    erpOrderNumber: string;
    orderStatus: number;
    dueDate: Date;
    amountDue: number;
    daysOverDue: number;
    externalOrderNumber: string;
    comment: string;
    orderLineOutputDtos: IOrderLineOutputDto[];
    errorDateTime: Date;
    errorDescription: string;
    errorRead: boolean;
    recordStatus: number;
    erpOrderId: string;
    externalOrderNo: string;
}
export interface IOrderInErrorSearchInputDto {
    filterBy: OrdersInErrorFilterByEnum;
    search: string;
}
export interface IOrderLineOutputDto {
    sqlId: number;
    orderId: number;
    itemCode: string;
    itemDescription: string;
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    uom: string;
    uomChoices: string;
    category: string;
    leadDays: number;
}
export interface IOrderOutputDto {
    sqlId: number;
    tranId: string;
    erpCustomerId: number;
    customerName: string;
    erpCustomerNumber: string;
    placedOn: Date;
    deliveryDate: Date;
    lineCount: number;
    poNumber: string;
    notes: string;
    placedByEmail: string;
    totalOrderAmount: number;
    localOrderYn: boolean;
    erpOrderNumber: string;
    orderStatus: number;
    dueDate: Date;
    amountDue: number;
    daysOverDue: number;
    externalOrderNumber: string;
    comment: string;
    orderLineOutputDtos: IOrderLineOutputDto[];
}
export interface IOrderSearchInputDto {
    sortBy: IncomingOrderSortByEnum;
    orderBy: OrderByEnum;
    filterBy: IncomingOrderFilterByEnum;
    search: string;
    startDate: Date;
    endDate: Date;
}
export interface IPaymentInformationDto {
    confirmationNumber: string;
    returnMessage: string;
    totalPaid: number;
    invoicesPayments: IInvoicePaymentDto[];
}
export interface IPortalInvoiceGridItemOutputDto {
    sqlId: number;
    invoiceNumber: string;
    customerAccountNo: string;
    invoiceAmount: number;
    invoiceDate: Date;
    webOrderNumber: string;
    submittedToBuyingGroupOn: Date;
    resubmittedYn: boolean;
    buyingGroupName: string;
    processedNotSubmitted: Date;
    inError: boolean;
}
export interface IPortalInvoiceGridOutputDto {
    totalRecords: number;
    portalInvoiceGridItemOutputDtos: IPortalInvoiceGridItemOutputDto[];
}
export interface IPortalInvoiceLineItemOutputDto {
    sqlId: number;
    itemCode: string;
    description: string;
    quantityOrdered: number;
    unitPrice: number;
    total: number;
    uom: string;
}
export interface IPortalInvoiceOutputDto {
    sqlId: number;
    invoiceNumber: string;
    customerAccountNo: string;
    invoiceAmount: number;
    invoiceDate: Date;
    webOrderNumber: string;
    submittedToBuyingGroupOn: Date;
    isCreditMemo: boolean;
    portalInvoiceLineItemOutputDtos: IPortalInvoiceLineItemOutputDto[];
}
export interface IPortalInvoicesSearchDto {
    search: string;
    portalInvoiceFilter: PortalInvoiceFilterEnum;
}
export interface IPortalMonitorRuleInsertDto {
    problemMinutes: number;
    recheckMinutes: number;
    active: boolean;
    processName: string;
    startTime: number;
    endTime: number;
    systemActivityId: number;
    ruleRegimeId: RuleRegimeEnum;
}
export interface IPortalMonitorRuleOutputDto {
    systemActivityId: number;
    ruleRegimeId: RuleRegimeEnum;
    problemMinutes: number;
    recheckMinutes: number;
    lastTimeChecked: Date;
    timeErrorHappened: Date;
    lastTimeEmailSent: Date;
    lastTimeCleared: Date;
    active: boolean;
    processName: string;
    startTime: number;
    endTime: number;
}
export interface IPortalMonitorRuleUpdateDto {
    problemMinutes: number;
    recheckMinutes: number;
    active: boolean;
    processName: string;
    startTime: number;
    endTime: number;
}
export interface IPurchaseOrderGridItemOutputDto {
    tranId: string;
    tranDate: Date;
    expectedDate: Date;
    preparedBy: string;
    vendorAccountNo: string;
    erpVendorInternalAccountNo: string;
    deliveryTruck: string;
}
export interface IPurchaseOrderGridOutputDto {
    totalRecords: number;
    purchaseOrderOutputDtos: IPurchaseOrderGridItemOutputDto[];
}
export interface IPurchaseOrderLineOutputDto {
    lineNumber: number;
    mwfItemNumber: string;
    vendorItemNumber: string;
    quantity: number;
    uom: string;
}
export interface IPurchaseOrderOutputDto {
    tranId: string;
    tranDate: Date;
    expectedDate: Date;
    preparedBy: string;
    vendorAccountNo: string;
    erpVendorInternalAccountNo: string;
    deliveryTruck: string;
    purchaseOrderLineOutputDtos: IPurchaseOrderLineOutputDto[];
}
export interface IPurchaseOrderSearchDto {
    page: number;
    pageSize: number;
    isSummary: boolean;
    search: string;
    vendorId: string;
    orderBy: OrderByEnum;
    dateRange: PurchaseOrderSummaryDateRangeEnum;
    start: Date;
    end: Date;
}
export interface IPurchaseOrderSummaryByVendorOutputDto {
    vendorId: string;
    numberPurchaseOrders: number;
    erpVendorInternalAccountNo: string;
}
export interface IPurchaseOrderSummaryOutputDto {
    purchaseOrderSummaryByVendorOutputDtos: IPurchaseOrderSummaryByVendorOutputDto[];
}
export interface IPurchaseOrderSummarySearchDto {
    dateRange: PurchaseOrderSummaryDateRangeEnum;
    start: Date;
    end: Date;
}
export interface IRecentlyViewedCustomerOutputDto {
    sqlId: number;
    userName: string;
    erpCustomerNumber: string;
    customerName: string;
    dateAdded: Date;
}
export interface IRecentlyViewedVendorOutputDto {
    sqlId: number;
    userName: string;
    erpInternalVendorId: string;
    vendorName: string;
    dateAdded: Date;
}
export interface IReportGridDto {
    totalRecords: number;
    reportGridItemDtos: IReportItemDto[];
}
export interface IReportGridSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    reportRoleDto: IUserRoleDto;
}
export interface IReportInsertDto {
    title: string;
    description: string;
    pageToDisplay: string;
    reportRoleDto: IUserRoleDto;
    isActive: boolean;
}
export interface IReportItemDto {
    title: string;
    description: string;
    pageToDisplay: string;
    id: string;
}
export interface IReportLocalSpendBySourceDto {
    reportLocalSpendBySourceItemsDtos: IReportLocalSpendBySourceItemsDto[];
}
export interface IReportLocalSpendBySourceItemsDto {
    customerNumber: string;
    customerName: string;
    localSpendBySourceReportOutputDtos: ILocalSpendBySourceReportOutputDto[];
}
export interface IReportLocalSpendBySourceParameterDto {
    fromDate: Date;
    toDate: Date;
    customers: string[];
}
export interface IReportLocalSpendBySourceSummaryDto {
    customerNumber: string;
    customerName: string;
    quantity: number;
    amount: number;
    averagePrice: number;
    purchaseWeight: number;
}
export interface IReportMaintenanceGridDto {
    totalRecords: number;
    reportGridMaintenanceItemDtos: IReportMaintenanceItemDto[];
}
export interface IReportMaintenanceGridSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    activeStatusEnum: ReportMaintenanceActiveStatusEnum;
}
export interface IReportMaintenanceItemDto {
    title: string;
    description: string;
    pageToDisplay: string;
    id: string;
    isActive: boolean;
}
export interface IReportMaintenanceOutputDto {
    title: string;
    description: string;
    pageToDisplay: string;
    id: string;
    isActive: boolean;
    reportRoleDto: IUserRoleDto;
}
export interface IReportOpenOrderCategoriesDto {
    catalogCategoryOutputDtos: ICatalogCategoryOutputDto[];
    defaultOneDtos: ICatalogCategoryOutputDto[];
    defaultTwoDtos: ICatalogCategoryOutputDto[];
}
export interface IReportOpenOrdersOutputDto {
    category: string;
    itemId: string;
    name: string;
    description: string;
    saleUnit: string;
    quantity: number;
}
export interface IReportOpenOrdersParameterDto {
    fromDate: Date;
    toDate: Date;
    categoryDescriptions: string[];
}
export interface IReportSavedQueryGridItemDto {
    id: string;
    userId: string;
    name: string;
    json: string;
}
export interface IReportSavedQueryGridOutputDto {
    totalRecords: number;
    reportId: string;
    reportQueryGridItemDtos: IReportSavedQueryGridItemDto[];
}
export interface IReportSavedQueryInputDto {
    reportId: string;
    name: string;
    json: string;
}
export interface IReportUpdateDto {
    title: string;
    description: string;
    pageToDisplay: string;
    reportRoleDto: IUserRoleDto;
    isActive: boolean;
}
export interface IReportViewActiveOnlineCustomersDto {
    customerNumber: string;
    customerName: string;
    lastDateOrderedOn: Date;
    totalNumberOfOrders: number;
    totalAmount: number;
}
export interface IReportViewDateRageDto {
    fromDate: Date;
    toDate: Date;
}
export interface IReportViewInactiveOnlineCustomersDto {
    customerNumber: string;
    customerName: string;
}
export interface IResubmitInvoiceDto {
    invoiceId: number;
}
export interface IResubmitInvoiceInputDto {
    invoiceNumber: string;
}
export interface IResubmitInvoicesDto {
    invoiceNumbers: string[];
}
export interface IRowPartitionKeysDto {
    partitionKey: string;
    rowKey: string;
}
export interface ISalesLocalSearchDto {
    fromDate: Date;
    toDate: Date;
}
export interface ISalesLocalVsOutputDto {
    fromDate: Date;
    toDate: Date;
    numberOfDays: number;
    totalOrderAmount: number;
    totalLocalAmount: number;
}
export interface ISalesOrderGridDto {
    totalRecords: number;
    salesOrderGridItemDtos: ISalesOrderGridItemDto[];
}
export interface ISalesOrderGridInputDto {
    search: string;
    orderByEnum: OrderByEnum;
    salesOrderGridOrderByEnum: SalesOrderGridOrderByEnum;
    fromDate: Date;
    toDate: Date;
}
export interface ISalesOrderGridItemDto {
    nsSqlId: number;
    portalSqlId: number;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
    dueDate: Date;
    amountDue: number;
    daysOverDue: number;
    orderPlacedBy: string;
    deliveryDate: Date;
}
export interface ISalesOrderInvoiceGridItemDto {
    sqlId: number;
    erpCustomerId: number;
    customerName: string;
    customerNumber: string;
    webOrderNumber: string;
    tranDate: Date;
    tranId: string;
    tranAmount: number;
    paidAmount: number;
    amountRemaining: number;
    discount: number;
    dueDate: Date;
    daysOverDue: number;
}
export interface ISalesOrderInvoiceGridOutputDto {
    totalRecords: number;
    salesOrderInvoiceGridItemDtos: ISalesOrderInvoiceGridItemDto[];
}
export interface ISalesOrderInvoiceSearchInputDto {
    orderBy: OrderByEnum;
    search: string;
    salesOrderInvoiceTime: SalesOrderInvoiceTimeEnum;
}
export interface ISalesRepProjectionGridOutputDto {
    totalRecords: number;
    salesRepProjectionOutputDtos: ISalesRepProjectionOutputDto[];
}
export interface ISalesRepProjectionOutputDto {
    customerNumber: string;
    customerName: string;
    lastPeriodAmount: number;
    thisPeriodAmount: number;
    percentage: number;
}
export interface ISalesRepProjectionSearchDto {
    page: number;
    pageSize: number;
    isSummary: boolean;
    orderBy: OrderByEnum;
    datePeriod: SalesRepProjectionDatePeriodEnum;
}
export interface ISalesRepSummaryOutputDto {
    email: string;
    personName: string;
    thisMonthAmount: number;
    lastMonthAmount: number;
    previousAmount: number;
    threeMonthAmount: number;
    fourMonthAmount: number;
    fiveMonthAmount: number;
    sixMonthAmount: number;
}
export interface ISaveDashboardInputDto {
    dashboardName: string;
}
export interface ISearchInputBaseDto {
    search: string;
    orderByEnum: OrderByEnum;
}
export interface ISiteDocumentationActiveStatusDto {
    active: boolean;
}
export interface ISiteDocumentationAdminOutputDto {
    siteDocumentationType: SiteDocumentationTypeEnum;
    sqlId: string;
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    activeYn: boolean;
    lastUpdatedOn: Date;
    lastUpdatedBy: string;
    publishedOn: Date;
}
export interface ISiteDocumentationDisplayAdminDto {
    siteDocumentationType: SiteDocumentationTypeEnum;
    sqlId: string;
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    publishedOn: Date;
}
export interface ISiteDocumentationDisplayAdminGridDto {
    totalRecords: number;
    siteDocumentationDisplayAdminDtos: ISiteDocumentationDisplayAdminDto[];
}
export interface ISiteDocumentationDisplayGridDto {
    totalRecords: number;
    siteDocumentationDisplayOutputDtos: ISiteDocumentationDisplayOutputDto[];
}
export interface ISiteDocumentationDisplayOutputDto {
    sqlId: string;
    sequence: number;
    title: string;
    subject: string;
    content: string;
    publishedOn: Date;
}
export interface ISiteDocumentationInputDto {
    siteDocumentationType: SiteDocumentationTypeEnum;
    sequence: number;
    title: string;
    subjectDraft: string;
    contentDraft: string;
    activeYn: boolean;
}
export interface ISiteDocumentationSearchInputDto {
    search: string;
    orderByEnum: OrderByEnum;
    page: number;
    pageSize: number;
}
export interface ISiteDownOutputDto {
    globalMessage: string;
    startDate: Date;
    endDate: Date;
    isVisible: boolean;
}
export interface ISiteDownUpdateDto {
    siteId: string;
    globalMessage: string;
    startDate: Date;
    endDate: Date;
}
export interface ISiteMessageOutputDto {
    globalMessage: string;
    startDate: Date;
    endDate: Date;
    announcement: boolean;
    warning: boolean;
    error: boolean;
    isVisible: boolean;
}
export interface ISiteMessageUpdateDto {
    siteId: string;
    globalMessage: string;
    startDate: Date;
    endDate: Date;
    announcement: boolean;
    warning: boolean;
    error: boolean;
}
export interface ISiteVersionOutputDto {
    versionNumber: string;
    forceReload: boolean;
}
export interface ISurveyChartDataOutputDto {
    ones: number;
    onesPercent: number;
    twos: number;
    twosPercent: number;
    threes: number;
    threesPercent: number;
    fours: number;
    foursPercent: number;
    fives: number;
    fivesPercent: number;
}
export interface ISurveyFilterInputDto {
    surveyDatePeriod: SurveyDatePeriodEnum;
    customerNumber: string;
    start: Date;
    end: Date;
}
export interface ISurveyGridDto {
    totalRecords: number;
    surveyGridItemDtos: ISurveyItemOutputDto[];
}
export interface ISurveyItemOutputDto {
    surveyToken: string;
    sentOn: Date;
    receivedOn: Date;
    deliveryRating: number;
    productQualityRating: number;
    customerServiceRating: number;
    onlineOrderingRating: number;
    salesRepRating: number;
    overallCompanyRating: number;
    comment: string;
    customerName: string;
    customerNumber: string;
    email: string[];
}
export interface ISurveySummaryOutputDto {
    deliveryChartData: ISurveyChartDataOutputDto;
    productChartData: ISurveyChartDataOutputDto;
    customerServiceChartData: ISurveyChartDataOutputDto;
    onlineChartData: ISurveyChartDataOutputDto;
    salesRepChartData: ISurveyChartDataOutputDto;
    overallCompanyChartData: ISurveyChartDataOutputDto;
}
export interface ISystemBatchActivityOutputDto {
    id: number;
    activity: string;
}
export interface ISystemLastActivityDto {
    activity: string;
    lastTimeProcessed: Date;
    displayInError: boolean;
}
export interface ITopCustomerGridOutputDto {
    totalRecords: number;
    topCustomerOutputDtos: ITopCustomerOutputDto[];
}
export interface ITopCustomerOutputDto {
    customerSqlId: number;
    erpCustomerNumber: string;
    customerName: string;
    amount: number;
}
export interface ITurnOffCustomerTwoFactorInputDto {
    customerNumber: string;
    emailType: EmailTypeEnum;
}
export interface ITypeAheadInputDto {
    search: string;
}
export interface ITypeAheadOutputDto {
    id: string;
    label: string;
}
export interface IUserAccessGridOutputDto {
    totalRecords: number;
    userAccessOutputDtos: IUserAccessOutputDto[];
}
export interface IUserAccessOutputDto {
    customerName: string;
    emailAddress: string;
    lastLoggedIn: Date;
}
export interface IUserActiveDirectorySearchInputDto {
    searchString: string;
}
export interface IUserInitialGridDto {
    totalRecords: number;
    userInitialOutputDto: IUserInitialOutputDto[];
}
export interface IUserInitialInsertDto {
    userRoleDto: IUserRoleDto;
    firstName: string;
    lastName: string;
    email: string;
}
export interface IUserInitialOutputDto {
    userRoleDto: IUserRoleDto;
    firstName: string;
    lastName: string;
    email: string;
    addedToAadOn: Date;
}
export interface IUserInitialSearchDto {
    search: string;
    orderByEnum: OrderByEnum;
    userListSearchActiveEnum: UserListSearchActiveEnum;
}
export interface IUserInitialUpdateDto {
    userRoleDto: IUserRoleDto;
    firstName: string;
    lastName: string;
}
export interface IUserListSearchInputDto {
    searchString: string;
    userListSearchActiveEnum: UserListSearchActiveEnum;
}
export interface IUserOutputDto {
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    userRoleDto: IUserRoleDto;
    dateUpdated: Date;
    id: string;
}
export interface IUserOutputGridDto {
    totalRecords: number;
    userOutputDtos: IUserOutputDto[];
}
export interface IUserRoleDto {
    none: boolean;
    isAdmin: boolean;
    isExecutive: boolean;
    isOeManager: boolean;
    isCustomerService: boolean;
    isSales: boolean;
    isSalesManager: boolean;
    isApplicationAdmin: boolean;
    isDocumentManager: boolean;
    isPurchasing: boolean;
    isLoginAsSalesRep: boolean;
}
export interface IUserSalesManagerAddSalesRepsDto {
    saleRepEmails: string[];
}
export interface IUserSalesRepsForSalesManagerDetailDto {
    email: string;
    personName: string;
}
export interface IUserUpdateDto {
    userRoleDto: IUserRoleDto;
    isActive: boolean;
    userId: string;
    firstName: string;
    lastName: string;
}
export interface IVendorGridOutputDto {
    totalRecords: number;
    vendorOutputDtos: IVendorOutputDto[];
}
export interface IVendorOutputDto {
    id: string;
    vendorId: string;
    vendorName: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    postalCode: string;
    telephone: string;
    fax: string;
    email: string;
    active: boolean;
    subsidiary: string;
}
export interface IVendorSearchInputDto {
    search: string;
}
export interface IWeeklyCustomerSalesSummaryBySalesRepGridOutputDto {
    totalRecords: number;
    weeklyCustomerSalesSummaryBySalesRepOutputDtos: IWeeklyCustomerSalesSummaryBySalesRepOutputDto[];
}
export interface IWeeklyCustomerSalesSummaryBySalesRepOutputDto {
    email: string;
    erpCustomerNumber: string;
    thisWeekAmount: number;
    lastWeekAmount: number;
    threeWeekAmount: number;
    fourWeekAmount: number;
    fiveWeekAmount: number;
    sixWeekAmount: number;
    customerName: string;
}
export interface IYearlyCustomerSalesSummaryBySalesRepGridOutputDto {
    totalRecords: number;
    yearlyCustomerSalesSummaryBySalesRepOutputDtos: IYearlyCustomerSalesSummaryBySalesRepOutputDto[];
}
export interface IYearlyCustomerSalesSummaryBySalesRepOutputDto {
    email: string;
    erpCustomerNumber: string;
    thisYearAmount: number;
    lastYearAmount: number;
    threeYearAmount: number;
    customerName: string;
}
export interface IFileParameter {
    data: any;
    fileName: string;
}
export interface IFileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
export enum ApiIntegrationScopesEnum {
    ReadCatalog = 1,
    ReadCustomer = 3,
    ReadCustomerOrderGuide = 2,
}
export enum CustomerOrderingSummaryDateRangeEnum {
    ThisMonth = 1,
    ThisQuarter = 2,
    ThisWeek = 0,
    ThisYear = 3,
}
export enum DashboardAnnouncementTypeEnum {
    Announcement = 1,
    FeaturedItems = 2,
    Recipe = 0,
}
export enum DatePeriodEnum {
    All = 3,
    DateRange = 4,
    ThisMonth = 0,
    ThisWeek = 1,
    Today = 2,
}
export enum FtpNameEnum {
    Atlas = 10,
    BirchStreet = 3,
    Crunchtime = 9,
    FreshConcepts = 4,
    ITradeInvoices = 1,
    ITradeOrdersFiles = 0,
    Npc = 7,
    OneSource = 5,
    ProAct = 2,
    ProduceAlliance = 12,
    Restaurant365 = 6,
    Restaurant365OOH = 11,
    SohoHouse = 8,
}
export enum MartenTypeEnum {
    CreditMemos = 1,
    Customers = 2,
    HowTos = 9,
    InventoryItems = 3,
    Invoices = 4,
    PurchaseOrders = 7,
    SalesOrders = 5,
    SalesRepOrders = 6,
    Vendors = 8,
}
export enum OrderByEnum {
    Ascending = 0,
    Descending = 1,
}
export enum SalesOrderGridOrderByEnum {
    DeliveryDate = 1,
    OrderDate = 0,
    PlacedBy = 2,
}
export enum UserRolesEnum {
    Admin = 1,
    ApplicationAdmin = 4096,
    CustomerService = 8,
    DocumentManager = 64,
    Executive = 2,
    LoginAsSalesRep = 256,
    OeManager = 4,
    Purchasing = 128,
    Sales = 16,
    SalesManager = 32,
}
export enum BatchJobScheduleTypeEnum {
    Scheduled = 0,
    Triggered = 1,
}
export enum DashboardAnnouncementActiveSearchEnum {
    Active = 1,
    All = 0,
    Inactive = 2,
}
export enum EmailTypeEnum {
    Alternate = 2,
    Both = 3,
    Main = 1,
}
export enum ExecutiveSummaryDatePeriodEnum {
    All = 8,
    DateRange = 7,
    LastMonth = 2,
    LastQuarter = 4,
    LastYear = 6,
    ThisMonth = 1,
    ThisQuarter = 3,
    ThisWeek = 0,
    ThisYear = 5,
}
export enum FtpObjectTypeEnum {
    Directory = 0,
    File = 1,
}
export enum IncomingOrderFilterByEnum {
    Accepted = 8,
    All = 0,
    Awaiting = 2,
    InError = 16,
    Pending = 1,
}
export enum IncomingOrderSortByEnum {
    PlacedOn = 0,
    RequestedFor = 1,
}
export enum OrdersInErrorFilterByEnum {
    All = 0,
    NotRead = 2,
    Read = 1,
}
export enum PortalInvoiceFilterEnum {
    All = 5,
    InError = 4,
    NotSubmitted = 2,
    ReSubmitted = 3,
    Submitted = 1,
}
export enum PurchaseOrderSummaryDateRangeEnum {
    All = 7,
    Custom = 6,
    LastMonth = 3,
    LastQuarter = 5,
    LastWeek = 1,
    ThisMonth = 2,
    ThisQuarter = 4,
    ThisWeek = 0,
}
export enum ReportMaintenanceActiveStatusEnum {
    Active = 1,
    All = 0,
    Inactive = 2,
}
export enum RuleRegimeEnum {
    Any = 9,
    Friday = 5,
    Monday = 1,
    Saturday = 6,
    Sunday = 0,
    Thursday = 4,
    Tuesday = 2,
    Wednesday = 3,
    Weekday = 8,
    Weekend = 7,
}
export enum SalesOrderInvoiceTimeEnum {
    FiveMonthsAgo = 9,
    FourMonthsAgo = 8,
    LastMonth = 6,
    LastWeek = 4,
    SixMonthsAgo = 10,
    ThisMonth = 5,
    ThisWeek = 3,
    ThreeMonthsAgo = 7,
    Today = 1,
    Yesterday = 2,
}
export enum SalesRepProjectionDatePeriodEnum {
    ThisMonth = 1,
    ThisQuarter = 2,
    ThisWeek = 0,
}
export enum SiteDocumentationTypeEnum {
    Customer = 1,
    Employee = 2,
    PortalDocumentation = 3,
}
export enum SurveyDatePeriodEnum {
    DateRange = 6,
    LastMonth = 1,
    LastQuarter = 3,
    LastYear = 5,
    ThisMonth = 0,
    ThisQuarter = 2,
    ThisYear = 4,
}
export enum UserListSearchActiveEnum {
    Active = 1,
    All = 0,
    Inactive = 2,
}
