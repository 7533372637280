import SysModels from "../../models";
import fetcher from "../Fetcher";

export class SurveyService {
  get = async (surveyToken: string) => {
    const url = `/Survey/${surveyToken}`;
    return fetcher.get<SysModels.ISurveyItemOutputDto>(url, { notoken: true });
  };

  unsubscribe = async (surveyToken: string) => {
    const url = `/Survey/${surveyToken}`;
    return fetcher.put<any>(url, {}, { notoken: true });
  };

  save = async (data: SysModels.ISurveyInputDto) => {
    const url = `/Survey`;
    return fetcher.post<any>(url, data, { notoken: true });
  };
}

const survey = new SurveyService();
export default survey;
