import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

interface IFormModalProps {
  children: any;
  customBodyFooter?: boolean;
  isOpen: boolean;
  close: () => void;
  submit?: (data: any) => void;
  deleteAction?: () => void;
  title: string;
  titleClass?: string;
  submitButtonLabel?: string;
  closeButtonLabel?: string;
  size?: "lg" | "sm" | "xl" | "md";
  titleIcon?: string;
  disableSubmit?: boolean;
  showDeleteButton?: boolean;
  deleteButtonLabel?: string;
  disabledDeleteButton?: boolean;
  moveBehind?: boolean;
  primaryButtonOnly?: boolean;
  dialogClassName?: string;
  onControlSave?: () => void;
  enforceFocus?: boolean;
}

function FormModal({
  children,
  customBodyFooter,
  isOpen,
  close,
  submit,
  deleteAction,
  title,
  titleClass,
  submitButtonLabel,
  closeButtonLabel,
  size,
  titleIcon,
  disableSubmit,
  showDeleteButton,
  deleteButtonLabel,
  disabledDeleteButton,
  moveBehind,
  primaryButtonOnly,
  dialogClassName,
  onControlSave,
  enforceFocus,
}: IFormModalProps) {
  const [animateDone, setAnimateDone] = useState(false);
  useEffect(() => {
    setAnimateDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uid] = useState(
    `uid${dayjs().format("YYYYMMDDhhmmss")}${(
      Math.random() * 123456789
    ).toFixed(0)}`
  );
  useEffect(() => {
    if (!moveBehind) {
      document.getElementById(uid)?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveBehind]);

  return (
    <Modal
      role="dialog"
      autoFocus={true}
      tabIndex={-1}
      keyboard={true}
      backdrop="static"
      size={(size as any) || "lg"}
      show={isOpen}
      className="sys-modal"
      onHide={() => close()}
      animation={false}
      dialogClassName={`dialog-init-position ${dialogClassName || ""} 
      ${animateDone ? "" : "animate"} ${moveBehind ? "hide-on-print" : ""}`}
      backdropClassName={`backdrop-init-position ${
        animateDone ? "" : "animate"
      }`}
      enforceFocus={enforceFocus === false ? false : true}
    >
      {moveBehind && <div className="formModalOverlay"></div>}
      <section
        id={uid}
        tabIndex={0}
        className="modal-content-wrapper"
        onKeyDown={(e) => {
          if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            e.stopPropagation();
            onControlSave && onControlSave();
          }
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {titleIcon && <i className={`pe-2 fa fa-${titleIcon} me-1`}></i>}
            <span
              className={titleClass || ""}
              dangerouslySetInnerHTML={{ __html: title }}
            ></span>
          </Modal.Title>
        </Modal.Header>

        {customBodyFooter && <>{children}</>}
        {!customBodyFooter && (
          <>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
              {showDeleteButton && !primaryButtonOnly && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    disabled={disabledDeleteButton}
                    onClick={deleteAction}
                  >
                    {deleteButtonLabel || "Delete"}
                  </button>
                </>
              )}
              {!primaryButtonOnly && (
                <Button type="button" onClick={close} variant="secondary">
                  {closeButtonLabel || "Close"}
                </Button>
              )}
              <Button
                type="button"
                onClick={submit}
                variant="primary"
                disabled={disableSubmit}
              >
                {submitButtonLabel || "Submit"}
              </Button>
            </ModalFooter>
          </>
        )}
      </section>
    </Modal>
  );
}

export default FormModal;
