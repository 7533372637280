import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import SearchBar, {
  GLOBAL_SEARCH_FILTER,
  GetAvailableFilters,
} from "../components/SearchBar";
import Pagination, { usePaging } from "../components/common/Pagination";
import systemStore, { usePreviewSearchedItem } from "../stores/SystemStore";
import { useNavigate, useParams } from "react-router-dom";
import commonService from "../services/CommonService";

function GlobalSearchResults(props: any) {
  const { query, types } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<SysModels.ILookupIntDto[]>([]);
  const [search, setSearch] = useState("");

  const [paging, setPaging] = usePaging<{ ignore: boolean }>(1, 50);
  const pageChange = (page: number, pageSize: number, ignore?: boolean) => {
    setPaging({
      ...paging,
      page: page,
      pageSize: pageSize,
      ignore: ignore || false,
    });
  };

  const grid = useFetchHelper(async () => {
    const list = filters.map((x) => x.value);
    return SysServices.http.search.globalSearchAll(
      paging.page,
      paging.pageSize - 2,
      {
        query: search,
        isCreditMemos: list.includes(SysModels.MartenTypeEnum.CreditMemos),
        isCustomers: list.includes(SysModels.MartenTypeEnum.Customers),
        isInventoryItems: list.includes(
          SysModels.MartenTypeEnum.InventoryItems
        ),
        isInvoices: list.includes(SysModels.MartenTypeEnum.Invoices),
        isOrders: list.includes(SysModels.MartenTypeEnum.SalesOrders),
        isHowTos: list.includes(SysModels.MartenTypeEnum.HowTos),
      }
    );
  }, "Search");

  const [lastSearch, setLastSearch] = useState("");
  const getData = () => {
    const curSearch = `${search}-${filters
      .map((x) => x.value)
      .sort()
      .join(",")}-${paging.page}-${paging.pageSize}`;
    if (curSearch !== lastSearch && filters.length > 0) {
      grid.getData();
      setLastSearch(curSearch);
      if (GetAvailableFilters().length === filters.length) {
        navigate(`/Search/${encodeURI(search)}`, { replace: true });
      } else {
        navigate(
          `/Search/${encodeURI(search)}/${filters
            .map((t) => t.value)
            .join(",")}`,
          {
            replace: true,
          }
        );
      }
    }
  };

  const doSearch = (tmoDuration = 500) => {
    const tmo = setTimeout(() => {
      if (search) {
        getData();
      }
    }, tmoDuration);
    return tmo;
  };

  useEffect(() => {
    if (paging.ignore) {
      return;
    }
    const tmo = doSearch(100);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  useEffect(() => {
    const tmo = doSearch(500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filters]);

  // useEffect(() => {
  //   const tmo = doSearch(1000);
  //   return () => {
  //     clearTimeout(tmo);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters]);

  const getDescription = (item: SysModels.IGlobalSearchOutputDto) => {
    if (
      item.searchType === SysModels.MartenTypeEnum.Customers ||
      item.searchType === SysModels.MartenTypeEnum.InventoryItems
    ) {
      return `${item.name} (${item.id})`;
    }
    if (item.searchType === SysModels.MartenTypeEnum.HowTos) {
      return item.name;
    }
    return `${item.id} (${item.name})`;
  };

  const selectedItem = usePreviewSearchedItem();
  const getLength = () => {
    if (grid.data?.globalSearchOutputDtos) {
      const custPageSize = paging.pageSize - 2;
      const length = grid.data.globalSearchOutputDtos.length;
      if (length > custPageSize) {
        return paging.pageSize * paging.page + 1;
      } else {
        return length + paging.pageSize * (paging.page - 1);
      }
    }
    return 0;
  };

  const isUnderMaintenance = (type: SysModels.MartenTypeEnum) => {
    if (!filters.find((f) => f.value === type)) {
      return false;
    }
    if (type === SysModels.MartenTypeEnum.CreditMemos) {
      return grid.data?.downSearchesDto?.isCreditMemoDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Customers) {
      return grid.data?.downSearchesDto?.isCustomerDown || false;
    }
    if (type === SysModels.MartenTypeEnum.InventoryItems) {
      return grid.data?.downSearchesDto?.isInventoryItemsDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Invoices) {
      return grid.data?.downSearchesDto?.isInvoiceDown || false;
    }
    if (type === SysModels.MartenTypeEnum.SalesOrders) {
      return grid.data?.downSearchesDto?.isSalesOrdersDown || false;
    }
    return false;
  };

  return (
    <>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Global Search</h4>
        <div className={`bg-white ${selectedItem ? "hide-on-print" : ""}`}>
          <div>
            <div className="p-3 flex flex-wrap gap-10">
              <div>
                <SearchBar
                  key={`search-${query || "-"}${types || "-"}`}
                  forPage={true}
                  query={query}
                  initialTypes={types}
                  onSearch={(search) => {
                    setSearch(search);
                    setPaging((p) => {
                      return {
                        ...p,
                        page: 1,
                        ignore: true,
                      };
                    });
                  }}
                  onFilter={(filters) => {
                    setFilters(filters);
                    setPaging((p) => {
                      return {
                        ...p,
                        page: 1,
                        ignore: true,
                      };
                    });
                  }}
                ></SearchBar>
              </div>
              <div className="flex-1"></div>
            </div>
            {grid.status === FetchStatus.InProgress && (
              <div className="p-3">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            {grid.status === FetchStatus.Complete && (
              <>
                {filters.find((x) => isUnderMaintenance(x.value)) && (
                  <div className="m-3 my-1 p-2 px-3 alert alert-sm alert-danger">
                    <i className="fa fa-warning me-1"></i> Global Search Under
                    Maintenance for:{" "}
                    {filters
                      .filter((x) => isUnderMaintenance(x.value))
                      .sort(commonService.sortByStringProperty("label"))
                      .map((x) => x.label)
                      .join(", ")}
                  </div>
                )}

                <table className="table table-hover pointer">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grid.data?.globalSearchOutputDtos?.map((item, i) => (
                      <tr
                        key={i}
                        className="break-word"
                        onClick={(e) => {
                          systemStore.setSearchedItemToPreview(item);
                        }}
                      >
                        <td>
                          {GLOBAL_SEARCH_FILTER.find(
                            (x) => x.value === item.searchType
                          )?.label2 || ""}
                        </td>
                        <td>{getDescription(item)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!grid.data?.globalSearchOutputDtos?.length && (
                  <div className="p-4">No Result(s)</div>
                )}
              </>
            )}
            <div
              className={`p-3 pt-0 ${
                grid.status === FetchStatus.InProgress ||
                !grid.data?.globalSearchOutputDtos?.length
                  ? "display-none"
                  : ""
              }`}
            >
              <Pagination
                length={getLength()}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                sizes={[10, 25, 50, 100]}
                noJumpToPage={true}
                showingOfWhatLabel="results"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GlobalSearchResults;
