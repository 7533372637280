import React from "react";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import SysServices from "../../services";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";

function UseAsTemplateConfirmation(props: {
  onClose: (action?: "clear" | "append" | "cancel") => void;
}) {
  return (
    <ConfirmDialog
      show={true}
      title="Use Order as Template"
      message={`You have existing item(s) in your cart. `}
      done={(rtn) => {
        if (rtn === "clear") {
          SysServices.http.catalog
            .cartDelete()
            .then((data) => {})
            .catch((error) => {})
            .finally(() => {
              systemStore.setSavedCartItems([]);              
              props.onClose(rtn);
            });
          return;
        }
        if (rtn === "append") {
          toastStore.showToast(
            "Please verify the quantities before submitting the order",
            "info"
          );
        }
        props.onClose(rtn);
      }}
      buttons={[
        {
          label: "Cancel",
          value: "cancel",
          variant: "secondary",
          size: "sm",
        },
        {
          label: "Clear and Use Template",
          value: "clear",
          variant: "danger",
          size: "sm",
        },
        {
          label: "Append Template to Cart",
          value: "append",
          variant: "primary",
          size: "sm",
        },
      ]}
    ></ConfirmDialog>
  );
}

export default UseAsTemplateConfirmation;
