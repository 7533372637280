import React, { useEffect, useState } from "react";
import FormModal from "../../components/common/FormModal";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import CommonSpinner from "../../components/common/CommonSpinner";
import { useCatalogFavorite } from "./CatalogItem";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";

function CatalogItemDialog(props: {
  itemId: any;
  customerNumber?: string;
  onClose: () => void;
}) {
  const item = useFetchHelper(async () => {
    if (commonService.isEmployeeSite && !systemStore.currentCustomer) {
      return SysServices.http.catalog.getItemEmployee(props.itemId);
    }
    return SysServices.http.catalog.getItem(props.itemId, props.customerNumber);
  }, "Product Detail");

  useEffect(() => {
    item.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fav = useCatalogFavorite(props.itemId);
  const [qty, setQty] = useState(1);

  const showCart = () => {
    if (!commonService.isEmployeeSite) {
      return true;
    }
    if (commonService.isEmployeeSite && !!systemStore.currentCustomer?.number) {
      return true;
    }
    return false;
  };

  return (
    <>
      <FormModal
        title="Product Detail"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
      >
        <ModalBody>
          {item.status === FetchStatus.Failed && (
            <div className="text-center p-4">
              <span className="text-danger">Failed Getting Product Detail</span>
              <br />
              <span
                className="text-primary pointer"
                onClick={() => item.getData()}
              >
                Try Again
              </span>
            </div>
          )}
          {item.status === FetchStatus.InProgress && (
            <CommonSpinner message="Getting Product Detail"></CommonSpinner>
          )}
          <div className="product-detail">
            {!!item.data && item.status === FetchStatus.Complete && (
              <div className="product-detail-container">
                <div>
                  {item.data.erpImageUrl && (
                    <img alt="" src={item.data.erpImageUrl}></img>
                  )}
                  {!item.data?.erpImageUrl && (
                    <div className="no-image">
                      <i className="fa fa-image"></i>
                    </div>
                  )}
                </div>
                <div>
                  <h3
                    dangerouslySetInnerHTML={{ __html: item.data.description }}
                  ></h3>
                  <div>
                    {showCart() && (
                      <h5 className="text-success my-4">
                        {commonService.toMoney(item.data?.unitPrice)}
                      </h5>
                    )}
                    <p
                      className="txt-grey"
                      dangerouslySetInnerHTML={{
                        __html:
                          item.data?.webLongDescription ||
                          item.data?.description ||
                          "Loading...",
                      }}
                    ></p>

                    {showCart() && (
                      <div className="flex flex-wrap gap-10 mt-4">
                        <input
                          key={item.data?.itemId}
                          type="number"
                          step="0"
                          min="0"
                          max="999999"
                          className="form-control"
                          style={{ width: "130px" }}
                          defaultValue={qty}
                          onChange={(e) => {
                            setQty(Number(e.target.value));
                          }}
                        ></input>
                        <div className="flex flex-wrap gap-10">
                          <button
                            className="btn btn-success btn-icon-label"
                            style={{ width: "180px" }}
                            onClick={(e) => {
                              if (item.data) {
                                systemStore.addItemToSavedCart({
                                  itemCode: item.data.itemId,
                                  name: item.data.description,
                                  unit: item.data.uom,
                                  price: 0, //TODO: PRICE,
                                  qty: qty,
                                });
                              }
                            }}
                          >
                            <i className="fa fa-shopping-cart"></i>
                            <span>Add To Order</span>
                            <em>Add To Order</em>
                          </button>
                          <button
                            className="btn btn-light txt-grey"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              fav.toggleFavorite();
                            }}
                          >
                            {!fav.savingFavorite && (
                              <i
                                className={`fa ${
                                  fav.isFavorite
                                    ? "fa-star text-warning"
                                    : "fa-star-o"
                                }`}
                              ></i>
                            )}
                            {fav.savingFavorite && (
                              <i className="fa fa-spin fa-circle-o-notch"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default CatalogItemDialog;
