import SysModels from "../../models";
import fetcher from "../Fetcher";

class ApiIntegrationForCustomerService {
  acceptTerms = async (
    id: string,
    data: SysModels.IApiTermsOfUsageInputDto
  ) => {
    const url = `/ApiIntegration/AcceptTerms/${id}`;
    return fetcher.put<SysModels.IApiIntegrationOutputDto>(url, data, {
      notoken: true,
    });
  };

  terms = async () => {
    const url = `/ApiIntegration/Terms`;
    return fetcher.get<string>(url, {
      notoken: true,
    });
  };
}

const apiIntegrationForCustomer = new ApiIntegrationForCustomerService();
export default apiIntegrationForCustomer;
