import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import dayjs from "dayjs";
import { protectedResources } from "../AuthConfig";
import systemStore from "../stores/SystemStore";
import commonService from "./CommonService";
import SysModels from "../models";
import SysServices from ".";

const TOKEN_KEY = "MWF_TOKEN";
const REUSE_FETCH = "REUSE_FETCH";
class Fetcher {
  public msalInstance: PublicClientApplication = null as any;
  private _tokenToUse: string = "";
  private _refreshToken: string = "";
  private _tokenExp: any;

  private _promiseCache: { [key: string]: Promise<any> } = {};
  private _promiseData: { [key: string]: any } = {};
  private _promiseError: { [key: string]: any } = {};

  /**
   * Use this to avoid multiple request to same API
   * @param url
   * @param options
   * @returns
   */
  fetchUseExisting = async <T>(url: string, options?: RequestInit) => {
    const matchFound = !!this._promiseCache[url];
    const self = this;
    if (matchFound) {
      //console.log(`1 ${url}`);
      const promise = new Promise<T>((resolve, reject) => {
        if (self._promiseData[url]) {
          //console.log(`2 ${url}`);
          resolve(self._promiseData[url]);
          return;
        }
        if (self._promiseError[url]) {
          //console.log(`2.X ${url}`);
          reject(self._promiseError[url]);
          return;
        }
        const onReuse = (e: any) => {
          if (self._promiseData[url]) {
            window.removeEventListener(REUSE_FETCH, onReuse);
            resolve(self._promiseData[url]);
            //console.log(`3 ${url}`);
          }
          if (self._promiseError[url]) {
            window.removeEventListener(REUSE_FETCH, onReuse);
            reject(self._promiseError[url]);
            //console.log(`3.X ${url}`);
          }
          //ensures this gets clean.
          if (!self._promiseCache[url]) {
            window.removeEventListener(REUSE_FETCH, onReuse);
          }
        };
        window.addEventListener(REUSE_FETCH, onReuse);
      });
      return promise;
    } else {
      delete this._promiseData[url];
      delete this._promiseError[url];
      const promise = this._doProcessRequest<T>(url, options)
        .then(
          (rtn) => {
            self._promiseData[url] = rtn;
            window.dispatchEvent(new Event(REUSE_FETCH));
            return rtn;
          },
          (err) => {
            delete this._promiseCache[url];
            delete this._promiseData[url];
            this._promiseError[url] = err;
            window.dispatchEvent(new Event(REUSE_FETCH));
            throw err;
          }
        )
        .finally(() => {
          setTimeout(() => {
            delete this._promiseCache[url];
            delete this._promiseData[url];
            delete this._promiseError[url];
          }, 3000);
        });
      this._promiseCache[url] = promise; //Comment this line to disable this feature
      return promise;
    }
  };

  storeToken = (data: SysModels.ITokenDto) => {
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(data || {}));
    const event = new Event("token_changed");
    window.dispatchEvent(event);
  };

  clearToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
    const event = new Event("token_changed");
    window.dispatchEvent(event);
  };

  getToken = () => {
    let data = {} as SysModels.ITokenDto;
    try {
      data = JSON.parse(window.localStorage.getItem(TOKEN_KEY) || "{}");
      if (data.token && (!this._tokenExp || this._tokenToUse !== data.token)) {
        this._tokenExp = dayjs(commonService.parseJwt(data.token).exp * 1000)
          //.add(-29, "minute") //Quick Test
          .toDate();
      }
    } catch {
      this._tokenExp = undefined;
      this._tokenToUse = "";
    }
    return {
      ...data,
      expiresOn: this._tokenExp,
    };
  };

  getRoles = () => {
    if (commonService.isEmployeeSite) {
      return [];
    }
    const data = commonService.parseJwt(this.getToken().token || "");
    if (data?.role) {
      if (typeof data.role === "string") {
        return [data];
      }
      return [...(data.role || [])];
    }
    return [];
  };

  getEmailFromToken = () => {
    const data = commonService.parseJwt(this.getToken().token || "");
    return data?.email || "";
  };

  getGivenNameFromToken = () => {
    const data = commonService.parseJwt(this.getToken().token || "");
    return data?.given_name || "";
  };

  getCustomerName() {
    const selCustomerId = localStorage.getItem("mwf-selected-customer");
    let name = "";
    this.getRoles().forEach((r) => {
      const arr = `${r || ""}`.split("~~~");
      if (arr[0] === selCustomerId) {
        name = arr[1];
      }
    });
    return name;
  }

  shouldAsk2fa = (token?: string) => {
    const data = commonService.parseJwt(token || this.getToken().token || "");
    if (
      (data?.requires_2fa || "").trim().toLowerCase() === "true" &&
      (data?.is_2fa_authenticated || "").trim().toLowerCase() === "false"
    ) {
      return true;
    }
    return false;
  };

  prompt2faDialog = (token?: string) => {
    if (commonService.isEmployeeSite) {
      return false;
    }
    const data = commonService.parseJwt(token || this.getToken().token || "");
    if (
      data?.email &&
      localStorage.getItem(`ask-2fa-${data?.email}`) === "yes"
    ) {
      return false;
    }
    if (
      (data?.requires_2fa || "").trim().toLowerCase() === "false" &&
      (data?.ask_about_2fa || "").trim().toLowerCase() === "true"
    ) {
      return true;
    }
    return false;
  };

  setPrompt2faAsked = () => {
    const data = commonService.parseJwt(this.getToken().token || "");
    if (data?.email) {
      localStorage.setItem(`ask-2fa-${data?.email}`, "yes");
    }
  };

  private _processReq = async <T>(url: string, options?: any) => {
    if (commonService.isEmployeeSite) {
      this._tokenToUse = systemStore.authenticationData?.accessToken || "";

      //FOR EMPLOYEE SITE
      if (this.msalInstance && systemStore.accountInfo) {
        await this.msalInstance
          .acquireTokenSilent({
            scopes: protectedResources.apiMwf.scopes,
            account: systemStore.accountInfo,
          })
          .then((response) => {
            this._tokenToUse = response.accessToken;
            systemStore.setAuthenticationData(response);
            console.log(
              `### EXPIRES ON: ${dayjs(response.expiresOn).format(
                "YYYY-MM-DD hh:mm:ss"
              )}`
            );
            //do more request here
          })
          .catch((error) => {
            this._tokenToUse = "";
            // in case if silent token acquisition fails, fallback to an interactive method
            if (error instanceof InteractionRequiredAuthError) {
              this.msalInstance
                .acquireTokenRedirect({
                  scopes: protectedResources.apiMwf.scopes,
                })
                .then((response) => {
                  //this._tokenToUse = response.accessToken;
                  //systemStore.setAuthenticationData(response);
                  //do more request here
                })
                .catch((error) => console.log(error));
            }
          });
      }

      if (!this._tokenToUse && !options.notoken) {
        return Promise.reject("Login required");
      }
    }
    //FOR CUSTOMER SITE
    else {
      const token = this.getToken(); //GET STORED TOKEN
      this._tokenToUse = token.token || "";
      this._refreshToken = token.refreshToken || "";

      if (!this._tokenToUse && !options.notoken) {
        return Promise.reject("Login required");
      }

      if (!options?.notoken) {
        if (token && token.token && token.refreshTokenExpiration) {
          if (
            dayjs() //.add(10, "day")
              .isBefore(token.refreshTokenExpiration)
          ) {
            //do nothing...
          } else {
            this.clearToken();
            return Promise.reject("Token Expired");
          }
        }

        if (!this._refreshToken) {
          return Promise.reject("Missing Refresh Token");
        }

        if (
          dayjs()
            //.add(29, "minute").add(30, "second") //for testing...
            .isAfter(this._tokenExp)
        ) {
          const jwt = SysServices.common.parseJwt(token.token);
          const refreshDto: SysModels.IRefreshTokenDto = {
            refreshToken: this._refreshToken, //  + "x-force-fail",
            sessionId: token.sessionId,
            email: jwt.email,
          };
          const refreshOpts = {
            method: "POST",
            body: JSON.stringify(refreshDto),
          };
          return this.fetchUseExisting<SysModels.ITokenDto>(
            "/CustomerUser/RefreshToken",
            refreshOpts
          )
            .then((newToken) => {
              //console.log(newToken);

              this.storeToken(newToken);
              this._tokenToUse = newToken.token || "";
              this._refreshToken = newToken.refreshToken || "";

              systemStore.setAuthDataForCustomerSite(newToken);
              return this._doProcessRequest<T>(url, options);
            })
            .catch((err) => {
              this.clearToken();
              //console.log(this._refreshToken);
              //console.error(JSON.stringify(err || {}));
              return Promise.reject("Failed to Refresh Token");
            });
        }
      }
    }

    //console.log(`### REQUEST: ${url}`);
    return this._doProcessRequest<T>(url, options);
  };

  private _doProcessRequest = async <T>(url: string, options?: any) => {
    url = `${process.env.REACT_APP_API_END_POINT}${url}`;
    options = options || {};
    options.headers = {
      ...this._getHeaders(), //set default header
      ...options.headers, //set custom headers
    };
    //console.log(options.headers);

    if (options?.headers["Content-Type"] === undefined) {
      delete options.headers["Content-Type"];
    }

    try {
      const request = new Request(url, {
        ...options,
        ...(commonService.isEmployeeSite ? {} : { credentials: "include" }),
      });
      const response = await fetch(request);

      if (!response.ok) {
        if (response.status === 401) {
          this.clearToken(); //this will force user to logout
        }

        const cloneReq = response.clone();
        try {
          const errMsg = await response.json();
          return Promise.reject(errMsg);
        } catch (err) {
          const errMsg = await cloneReq.text();
          //console.log(`!!! ===> ${errMsg}`);
          return Promise.reject(`${errMsg}`);
        }
      }

      if (
        response.redirected &&
        response.url.toLowerCase().indexOf("account/accessdenied") > -1
      ) {
        return Promise.reject("Access Denied");
      }

      const txt = await response.clone().text();
      if ((txt || "").trim() === "") {
        return {} as T;
      }

      try {
        const data = await response.json();
        return data as T;
      } catch (err) {
        return {} as T;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  private _getHeaders() {
    const hdr = {
      "Content-Type": "application/json",
      accept: "application/json",
    };

    if (this._tokenToUse) {
      return {
        ...hdr,
        authorization: "Bearer " + this._tokenToUse,
        "x-customerid": localStorage.getItem(`mwf-selected-customer`) || "",
        ...(systemStore.salesAccountViewing
          ? {
              "x-as-sales-rep-email": systemStore.salesAccountViewing.email,
            }
          : {}),
      };
    }
    return hdr;
  }

  get = async <T>(url: string, moreOptions?: any) => {
    return await this._processReq<T>(url, moreOptions);
  };

  post = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "POST",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  put = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "PUT",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  delete = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "DELETE",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  postFormData = async <T>(url: string, formData?: any) => {
    const options = {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": undefined,
      },
    };
    return await this._processReq<T>(url, options);
  };

  putFormData = async <T>(url: string, formData?: any) => {
    const options = {
      method: "PUT",
      body: formData,
      headers: {
        "Content-Type": undefined,
      },
    };
    return await this._processReq<T>(url, options);
  };

  mockPost = async (data: any) => {
    console.log(
      "Mock POST called. Make sure to replace this with correct API calls."
    );
    return await Promise.resolve(data);
  };

  mockGet = async <T>(data: any) => {
    console.log(
      "Mock GET called. Make sure to replace this with correct API calls."
    );
    return await new Promise<T>((resolve) =>
      setTimeout(() => resolve(data), 1000)
    ); // Promise.resolve<T>(data);
  };
}

const fetcher = new Fetcher();
export default fetcher;
