import SysModels from "../../models";
import fetcher from "../Fetcher";

export class SiteDocumentationService {
  getPublished = async (id?: string) => {
    let url = `/SiteDocumentation/Published/${id}`;
    return fetcher.get<SysModels.ISiteDocumentationDisplayOutputDto>(url);
  };

  // getContentPublished = async (id?: string) => {
  //   let url = `/SiteDocumentation/GetContentPublished/${id}`;
  //   return fetcher.get<string>(url);
  // };

  getPublishedItems = async (
    data: SysModels.ISiteDocumentationSearchInputDto,
    portalDoc?: boolean
  ) => {
    let url = `/SiteDocumentation/PublishedItems`;
    return fetcher.post<SysModels.ISiteDocumentationDisplayGridDto>(url, data);
  };
}

const siteDocumentation = new SiteDocumentationService();
export default siteDocumentation;
