import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";
import systemStore, { useWindowSize } from "../../stores/SystemStore";

function CreditMemoViewDialog(props: {
  onClose: () => void;
  memoId: any;
  customerNumber?: string;
}) {
  const memoDetail = useFetchHelper(async () => {
    return SysServices.http.sales.getCreditMemo(
      props.memoId,
      props.customerNumber
    );
  }, "Credit Memo");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    memoDetail.getData();
  }, [calledOnce, memoDetail]);

  const [isSwitching, setIsSwitching] = useState(false);
  const windowSize = useWindowSize();

  return (
    <>
      <FormModal
        title={"View Credit Memo"}
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
      >
        {isSwitching && (
          <CommonSpinner
            overlay={true}
            message="Switching to Customer..."
          ></CommonSpinner>
        )}
        <ModalBody>
          {memoDetail.status === FetchStatus.InProgress && (
            <CommonSpinner message="Getting Invoice Detail"></CommonSpinner>
          )}
          {memoDetail.status === FetchStatus.Failed && (
            <div className="text-center p-4">
              <span className="text-danger">Failed Getting Credit Memo</span>
              <br />
              <span
                className="text-primary pointer"
                onClick={() => memoDetail.getData()}
              >
                Try Again
              </span>
            </div>
          )}
          {memoDetail.status === FetchStatus.Complete && memoDetail.data && (
            <>
              <div className="row pb-3">
                <div className="col-sm-12">
                  <table className="no-format">
                    <tbody>
                      <tr>
                        <td className="pe-2">
                          <strong>Customer:</strong>
                        </td>
                        <td>
                          {commonService.isEmployeeSite ? (
                            <span
                              className="text-primary pointer"
                              onClick={(e) => {
                                setIsSwitching(true);
                                SysServices.http.customer
                                  .get(memoDetail.data?.erpCustomerNumber)
                                  .then((data) => {
                                    systemStore.setCurrentCustomer({
                                      name: data.customerName,
                                      number: data.erpCustomerNumber,
                                      id: data.sqlId,
                                      data: data,
                                    });
                                    props.onClose();
                                  })
                                  .catch((error) => {
                                    toastStore.showError(
                                      "Failed Getting Customer",
                                      error
                                    );
                                    setIsSwitching(false);
                                  });
                              }}
                            >
                              {memoDetail.data?.customerName} (
                              {memoDetail.data?.erpCustomerNumber})
                            </span>
                          ) : (
                            <span>
                              {memoDetail.data?.customerName} (
                              {memoDetail.data?.erpCustomerNumber})
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2">
                          <strong>Transaction ID:</strong>
                        </td>
                        <td>{memoDetail.data?.tranId}</td>
                      </tr>
                      <tr>
                        <td className="pe-2">
                          <strong>Reference Invoice Number:</strong>
                        </td>
                        <td>{memoDetail.data?.referenceInvoiceNumber}</td>
                      </tr>
                      <tr>
                        <td className="pe-2">
                          <strong>Transaction Amount:</strong>
                        </td>
                        <td>
                          <strong>
                            {commonService.toMoney(memoDetail.data?.tranAmount)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table className="table table-striped table-sm w-100 bg-white">
                <thead>
                  <tr>
                    {windowSize.isSmall ? (
                      <>
                        <th>Item</th>
                        <th>Price</th>
                      </>
                    ) : (
                      <>
                        <th>Item</th>
                        <th>Description</th>
                        <th className="text-center">Quantity</th>
                        <th> UOM</th>
                        <th className="text-right">Price</th>
                        <th className="text-right">Total</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(memoDetail.data?.orderLineOutputDtos || []).map((item) => (
                    <tr key={item.itemCode}>
                      {windowSize.isSmall ? (
                        <>
                          <td className="pe-4">
                            <div>{item.itemCode}</div>
                            {item.itemDescription}
                            <div className="txt-grey">
                              <small>{item.category}</small>
                            </div>
                          </td>
                          <td>
                            <table className="table-sm">
                              <tbody>
                                <tr>
                                  <td className="text-left">
                                    <small>QTY:</small>
                                  </td>
                                  <td className="text-right">
                                    {item.quantity.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>UOM:</small>
                                  </td>
                                  <td className="text-right">{item.uom}</td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>Price:</small>
                                  </td>
                                  <td className="text-right">
                                    {commonService.toMoney(item.unitPrice || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>Total:</small>
                                  </td>
                                  <td className="text-right">
                                    {commonService.toMoney(
                                      item.totalPrice || 0
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{item.itemCode}</td>
                          <td>
                            {item.itemDescription}

                            <div className="txt-grey">
                              <small>{item.category}</small>
                            </div>
                          </td>
                          <td className="text-center">
                            {item.quantity.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.uom}</td>
                          <td className="text-right">
                            {commonService.toMoney(item.unitPrice || 0)}
                          </td>
                          <td className="text-right">
                            {commonService.toMoney(item.totalPrice || 0)}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>
          <Button
            type="button"
            onClick={() => {
              window.print();
            }}
            variant="primary"
            size="sm"
            disabled={memoDetail.status === FetchStatus.InProgress}
          >
            <i className="fa fa-print"></i> Print
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default CreditMemoViewDialog;
