import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import systemStore, {
  useCartUpdatedOutsideOG,
  useSavedCartItems,
} from "../stores/SystemStore";
import { reaction } from "mobx";

import FormModal from "../components/common/FormModal";
import CommonSpinner from "../components/common/CommonSpinner";
import DateTimePicker from "../components/common/DateTimePicker";
import ConfirmDialog from "../components/common/ConfirmDialog";

import FindNotInOGDialog from "./orders/FindNotInOGDialog";
import SwitchButton from "../components/common/SwitchButton";

function OrderGuide(props: any) {
  const navigate = useNavigate();
  const [delDate, setDelDate] = useState<any>();
  const [filterBy, setFilterBy] = useState("All");
  const [orderDetail, setOrderDetail] = useState<{
    date?: Date;
    total: number;
    itemCount: number;
    poNumber: string;
    notes: string;
  }>({ total: 0, itemCount: 0, poNumber: "", notes: "" });
  const [items, setItems] = useState<
    {
      itemCode: string;
      qty: number;
      price: number;
      show?: boolean;
      leadDays: number;
    }[]
  >([]);
  const customer = useFetchHelper<SysModels.IErpCustomerCustomerSiteOutputDto>(
    SysServices.http.customer.get,
    "Customer"
  );
  const grid = useFetchHelper<SysModels.IOrderGuideGridDto>(() => {
    const template = systemStore.orderGuideTemplate;
    if (template?.id) {
      systemStore.setOrderGuideTemplate(undefined, false); //reset
      if (template.netSuite) {
        return SysServices.http.order.getOrderGuideFromNetSuiteTemplate(
          template.id
        );
      }
      return SysServices.http.order.getOrderGuideFromTemplate(template.id);
    }
    return SysServices.http.order.getNewOrderGuide();
  }, "Order Guide");

  const nonDeliveryDates = useFetchHelper<SysModels.INonDeliveryDateGridDto>(
    async () =>
      SysServices.http.order.getNotDeliveryDates(
        dayjs().format("YYYY-MM-DD"),
        dayjs().add(3, "month").format("YYYY-MM-DD")
      ),
    "Non Delivery Dates"
  );

  const savedCartItems = useSavedCartItems();
  // const [hasSavedCartItems, setHasSavedCartItems] = useState(
  //   savedCartItems && savedCartItems?.items?.length > 0
  // );
  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    grid.getData();
    customer.getData();
    nonDeliveryDates.getData();
  }, [calledOnce, grid, customer, nonDeliveryDates]);

  const getItemInfo = (itemCode: string) => {
    return [
      ...(grid.data?.orderGuideGridItemDtos || []).map((item) => {
        return {
          itemCode: item.itemCode,
          name: item.itemDescription,
          unit: item.uom,
          leadDays: item.leadDays || 0,
        };
      }),
      // ...(savedCartItems?.items || []).map((item) => {
      //   return { itemCode: item.itemCode, name: item.name, unit: item.unit };
      // }),
    ].find((x) => x.itemCode === itemCode);
  };

  const [gridDataReady, setGridDataReady] = useState(false);
  useEffect(() => {
    let total = 0;
    items.forEach((i) => {
      total = total + Number(i.price || 0);
    });

    setOrderDetail((val) => {
      return {
        ...val,
        total: total,
        itemCount: items.filter((i) => Number(i.qty || 0) > 0).length,
      };
    });

    // if (savedCartItems && hasSavedCartItems) {
    //   setHasSavedCartItems(false);
    //   setItems(
    //     savedCartItems.items.map((i) => {
    //       const info = getItemInfo(i.itemCode);
    //       return {
    //         itemCode: i.itemCode,
    //         name: info?.name || i.itemCode,
    //         unit: info?.unit || "",
    //         price: i.price,
    //         qty: i.qty || 0,
    //       };
    //     })
    //   );
    // }
    // else {
    //   systemStore.setSavedCartItems(
    //     items
    //       .filter((i) => i.qty > 0)
    //       .map((i) => {
    //         const info = getItemInfo(i.itemCode);
    //         return {
    //           itemCode: i.itemCode,
    //           name: info?.name || i.itemCode,
    //           unit: info?.unit || "",
    //           price: i.price,
    //           qty: i.qty,
    //         };
    //       })
    //   );
    // }

    if (gridDataReady) {
      systemStore.setSavedCartItems(
        items
          .filter((i) => i.qty > 0)
          .map((i) => {
            const info = getItemInfo(i.itemCode);
            return {
              itemCode: i.itemCode,
              name: info?.name || i.itemCode,
              unit: info?.unit || "",
              price: i.price,
              qty: i.qty,
              leadDays: info?.leadDays || 0,
            };
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, gridDataReady]);

  const [nonDelDateList, setNonDelDateList] = useState<
    SysModels.INonDeliveryDateDto[]
  >([]);
  const [refreshDeliveryDates, setRefreshDeliveryDates] = useState(
    commonService.getUniqueId()
  );

  useEffect(() => {
    if (
      customer.status === FetchStatus.Complete &&
      customer.data &&
      nonDeliveryDates.status === FetchStatus.Complete &&
      nonDeliveryDates.data &&
      nonDeliveryDates.data.nonDeliveryDateDtos
    ) {
      //GrabAndGo Customer
      let daysToAdd = 1;
      if (customer.data.grabAndGoCustomer) {
        //console.log(customer.data);
        let dayOfWeek = dayjs(nonDeliveryDates.data.companyTime).format("dddd");

        var isNextDay = false;
        if (
          dayjs(nonDeliveryDates.data.companyTime).isAfter(
            dayjs(nonDeliveryDates.data.companyTime)
              .startOf("day")
              .add(18, "hours")
          )
        ) {
          //is past 6pm?
          isNextDay = true;
        }
        if (dayOfWeek === "Thursday") {
          daysToAdd = isNextDay ? 5 : 4;
        } else if (dayOfWeek === "Friday") {
          daysToAdd = 4;
        } else {
          // +3days or +4days if on next day
          daysToAdd = isNextDay ? 4 : 3;
        }
      }

      const dtos = [...nonDeliveryDates.data.nonDeliveryDateDtos];
      let cnt = 0;
      while (cnt < daysToAdd) {
        const nxtDay = dayjs(nonDeliveryDates.data.companyTime) //(new Date())
          .add(cnt, "day")
          .format("YYYY-MM-DDT00:00:00");

        if (
          !dtos.find(
            (d) =>
              dayjs(d.nonDeliveryDate).format("YYYY-MM-DDT00:00:00") === nxtDay
          )
        ) {
          dtos.unshift({
            //sqlId: null as any,
            nonDeliveryDate: nxtDay as any,
          });
        }
        cnt++;
      }

      dtos.sort(commonService.sortByStringProperty("nonDeliveryDate"));
      setNonDelDateList(dtos);

      let dateToCheck = dayjs(nonDeliveryDates.data.companyTime).add(
        daysToAdd,
        "day"
      ); //new Date()
      let matchSet = false;

      while (!matchSet) {
        const dtc = dateToCheck.format("YYYY-MM-DD");
        if (
          !dtos.find(
            (d) => dtc === dayjs(d.nonDeliveryDate).format("YYYY-MM-DD")
          )
        ) {
          const dtc = dateToCheck.toDate();
          setDelDate(dtc);
          setOrderDetail((val) => {
            return { ...val, date: dtc };
          });
          matchSet = true;
        } else {
          dateToCheck = dateToCheck.add(1, "day");
        }
      }
    }
  }, [
    nonDeliveryDates.status,
    customer.status,
    nonDeliveryDates.data,
    customer.data,
    refreshDeliveryDates,
  ]);

  const [calledOnceInitialData, setCalledOnceInitialData] = useState(false);
  useEffect(() => {
    if (
      !calledOnceInitialData &&
      nonDeliveryDates.status === FetchStatus.Complete &&
      customer.status === FetchStatus.Complete &&
      grid.status === FetchStatus.Complete
    ) {
      //setShowLeadTimeDialog(true);
      setCalledOnceInitialData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calledOnceInitialData,
    nonDeliveryDates.status,
    customer.status,
    grid.status,
  ]);

  const cartUpdatedOutsideOG = useCartUpdatedOutsideOG();
  useEffect(() => {
    if (gridDataReady) {
      setGridDataReady(false);
      setItems((list) => {
        return [
          ...list.filter(
            (i) =>
              !!savedCartItems?.items.find((ii) => ii.itemCode === i.itemCode)
          ),
        ];
      });
      setTimeout(() => {
        setGridDataReady(true);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartUpdatedOutsideOG]);

  const [calledOnce2, setCalledOnce2] = useState(false);

  useEffect(() => {
    if (!calledOnce2 && grid.status === FetchStatus.Complete && grid.data) {
      setCalledOnce2(true);
      setTimeout(() => {
        setGridDataReady(true);
      }, 500);

      if (grid.data.orderGuideGridItemDtos) {
        setItems((list) => {
          return [
            ...list,
            ...(grid.data?.orderGuideGridItemDtos || [])
              .filter(
                (item) =>
                  item.quantity > 0 &&
                  list.filter((item2) => item2.itemCode === item.itemCode)
                    .length === 0
              )
              .map((item) => {
                return {
                  itemCode: item.itemCode,
                  qty: item.quantity,
                  price: Number(
                    (item.quantity * Number(item.unitPrice || 0)).toFixed(2)
                  ),
                  leadDays: item.leadDays || 0,
                };
              }),
          ];
        });

        // const itemsNotInOG = (savedCartItems?.items || []).filter(
        //   (item) =>
        //     grid.data?.orderGuideGridItemDtos.filter(
        //       (i) => i.itemCode === item.itemCode
        //     ).length === 0
        // );

        // if (itemsNotInOG.length > 0) {
        //   grid.setData((prev) => {
        //     if (!prev) return prev;
        //     return {
        //       ...prev,
        //       orderGuideGridItemDtos: [
        //         ...(prev.orderGuideGridItemDtos || []),
        //         ...itemsNotInOG
        //           .filter((r) => {
        //             return !(prev.orderGuideGridItemDtos || []).find(
        //               (i) => i.itemCode === r.itemCode
        //             );
        //           })
        //           .map((i) => {
        //             return {
        //               category: undefined as any,
        //               description: undefined as any,
        //               fromOg: true,
        //               itemCode: i.itemCode,
        //               itemId: i.itemCode,
        //               itemDescription: i.name,
        //               leadDays: 0, //i.leadDays, TODO
        //               mwfInvoiceCategory: undefined as any,
        //               orderGuideOrdersId: undefined as any,
        //               quantity: 0,
        //               totalPrice: 0,
        //               uniqueValue: undefined as any,
        //               unitPrice: i.price,
        //               uom: i.unit,
        //               uomChoices: undefined as any,
        //             } as SysModels.IOrderGuideGridItemDto;
        //           }),
        //       ],
        //     };
        //   });
        // }
      }
    }
  }, [calledOnce2, grid.status, grid.data]);

  const [isClearingCart, setIsClearingCart] = useState(false);
  const clearCart = async () => {
    if (isClearingCart) return;
    setIsClearingCart(true);
    await SysServices.http.catalog
      .cartDelete()
      .then((data) => {
        systemStore.setSavedCartItems([]);
      })
      .catch((error) => {})
      .finally(() => {
        setIsClearingCart(false);
      });
  };

  const [saving, setSaving] = useState(false);
  const [showLeadTimeDialog, setShowLeadTimeDialog] = useState(false);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [showNonDelDates, setShowNonDelDates] = useState(false);
  const [withQtyOnly, setWithQtyOnly] = useState(false);
  //const [allowLeavePage, setAllowLeavePage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [placeSameDay, setPlaceSameDay] = useState(false);
  const [respModel, setRespModel] = useState<SysModels.IOrderOutputDto>();

  const [checkingDate, setCheckingDate] = useState(false);
  const [showCompanyTimeDialog, setShowCompanyTimeDialog] = useState(false);

  const validateCompanyTimeBeforeSubmit = () => {
    setCheckingDate(true);
    SysServices.http.order
      .getCompanyTime()
      .then((companyTime) => {
        if (
          dayjs(companyTime).format("MM-DD-YYYY") ===
          dayjs(nonDeliveryDates.data?.companyTime).format("MM-DD-YYYY")
        ) {
          submit();
        } else {
          setShowCompanyTimeDialog(true);
          nonDeliveryDates.setData({
            ...(nonDeliveryDates.data || ({} as any)),
            companyTime: companyTime,
          });
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Delivery Dates", err);
      })
      .finally(() => {
        setCheckingDate(false);
      });
  };

  const submit = async () => {
    if (orderDetail.date && grid.data?.orderGuideGridItemDtos) {
      setSaving(true);
      const dtos = grid.data.orderGuideGridItemDtos
        .filter(
          (item) => items.filter((i) => i.itemCode === item.itemCode).length
        )
        .map((item) => {
          return {
            ...item,
            quantity: items.find((i) => i.itemCode === item.itemCode)?.qty || 0,
          };
        })
        .filter((item) => item.quantity > 0);

      await SysServices.http.order
        .create({
          orderGuideGridItemDtos: dtos,
          placedOn: new Date(),
          deliveryDate: dayjs(
            placeSameDay ? nonDeliveryDates.data?.companyTime : orderDetail.date
          ).format("YYYY-MM-DD") as any,
          lineCount: orderDetail.itemCount,
          poNumber: orderDetail.poNumber,
          notes: orderDetail.notes,
          placeSameDay: placeSameDay,
        })
        .then((data) => {
          toastStore.showToast("Order Accepted.", "success");
          setRespModel(data);
          //setAllowLeavePage(true);
          setShowOrderSuccess(true);
          setSaving(false);

          clearCart(); //Clear Cart
        })
        .catch((error) => {
          toastStore.showError("Failed Creating Order", error);
          setSaving(false);
        });
    }
  };

  const validateDate = (preventSetDate = false) => {
    !preventSetDate &&
      setOrderDetail((val) => {
        return {
          ...val,
          date: delDate,
        };
      });

    const ds = dayjs(delDate).format("YYYYMMDD");
    const ndd = (nonDelDateList || []).map((d) =>
      dayjs(d.nonDeliveryDate).format("YYYYMMDD")
    );
    let dt = delDate || undefined;
    if (ndd.indexOf(ds) > -1) {
      dt = new Date();
    }

    !preventSetDate &&
      setOrderDetail((val) => {
        return {
          ...val,
          date: dt,
        };
      });

    const minRef = nonDelDateList[0];
    const maxRef = nonDelDateList[nonDelDateList.length - 1];
    if (nonDelDateList && minRef && maxRef) {
      if (dayjs(dt).isBefore(minRef.nonDeliveryDate)) {
        return false;
      }
      if (dayjs(dt).isAfter(maxRef.nonDeliveryDate)) {
        return false;
      }
      return !nonDelDateList.find(
        (d) =>
          dayjs(dt).format("YYYY-MM-DD") ===
          dayjs(d.nonDeliveryDate).format("YYYY-MM-DD")
      );
    }

    return false;
  };

  const hasItemsWithLeadTime = () => {
    return !!grid.data?.orderGuideGridItemDtos?.find(
      (i) =>
        items.find((x) => x.itemCode === i.itemCode && x.qty > 0) &&
        i.leadDays > 0
    );
  };

  const isValidDate = useMemo(
    () => validateDate(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delDate]
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  const [systemMessageVisible, setSystemMessageVisible] = useState(
    systemStore.systemMessageVisible
  );
  const [mainContainerScrollTop, setMainContainerScrollTop] = useState(0);
  const [windowSize, setWindowSize] = useState(systemStore.windowSize);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    const disposer1 = reaction(
      () => systemStore.systemMessageVisible,
      (n, p, i) => {
        setSystemMessageVisible(n);
      }
    );
    const disposer2 = reaction(
      () => systemStore.mainContainerScrollTop,
      (n, p, i) => {
        setMainContainerScrollTop(n);
      }
    );
    const disposer3 = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setWindowSize(n);
      }
    );
    return () => {
      disposer();
      disposer1();
      disposer2();
      disposer3();
    };
  }, []);

  const toggleShowItemsWithQty = () => {
    setWithQtyOnly(!withQtyOnly);
    setItems((list) => {
      const copy = [...list];
      copy.forEach((i) => {
        if (Number(i.qty || 0) > 0) {
          i.show = !withQtyOnly;
        } else {
          i.show = false;
        }
      });
      return copy;
    });
  };

  const [lastCountOfItemsWithQty, setLastCountOfItemsWithQty] = useState(-1);
  useEffect(() => {
    if (lastCountOfItemsWithQty === 0) {
      console.log(`change lastCountOfItemsWithQty: ${lastCountOfItemsWithQty}`);
      clearCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCountOfItemsWithQty]);

  const toggleQty = (
    item: { itemCode: string; unitPrice: number; leadDays: number },
    qty: number
  ) => {
    //SysModels.IOrderGuideGridItemDto

    setItems((list) => {
      const copy = [...list];
      if (copy.find((i) => i.itemCode === item.itemCode)) {
        copy.forEach((i) => {
          if (i.itemCode === item.itemCode) {
            i.qty = qty;
            i.price = Number((qty * Number(item.unitPrice || 0)).toFixed(2));
          }
        });
        setLastCountOfItemsWithQty(copy.filter((i) => i.qty > 0).length);
        return copy;
      }

      const newList = [
        ...copy,
        {
          itemCode: item.itemCode,
          qty: qty,
          price: Number((qty * Number(item.unitPrice || 0)).toFixed(2)),
          leadDays: item.leadDays,
        },
      ];
      setLastCountOfItemsWithQty(newList.filter((i) => i.qty > 0).length);
      return newList;
    });
  };

  const [openFindNotInOG, setOpenFindNotInOG] = useState(false);
  const [systemBannerHeight, setSystemBannerHeight] = useState(0);

  useEffect(() => {
    setSystemBannerHeight(
      document.getElementById("system-banner")?.clientHeight || 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMessageVisible, windowSize]);

  // useEffect(() => {
  //   console.log(grid.data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [grid.data]);
  return (
    <>
      {saving && (
        <CommonSpinner overlay={true} message="Saving Order..."></CommonSpinner>
      )}
      {checkingDate && (
        <CommonSpinner
          overlay={true}
          message="Validating Dates..."
        ></CommonSpinner>
      )}

      {/* <RouteChangedGuard
        when={true}
        navigate={(path) => navigate(path)}
        shouldBlockNavigation={(location) => {
          if (allowLeavePage) {
            return false;
          }
          return (
            !!orderDetail.notes ||
            !!orderDetail.poNumber ||
            !!items.find((i) => i.qty > 0)
          );
        }}
        title="Leave Page"
        message={`If you leave the page you will lose your changes.<br/>
        Are you sure you want to continue?`}
        leaveWithAction={{
          buttonLabel: "Save Cart and Leave",
          action: () => {},
        }}
        onLeave={() => {
          systemStore.setSavedCartItems([]);
        }}
      /> */}

      {openFindNotInOG && (
        <FindNotInOGDialog
          onClose={(rtn) => {
            if (rtn && rtn.length > 0) {
              setItems((prev) => {
                return [
                  ...prev.map((i) => {
                    const match = rtn.find((r) => r.itemId === i.itemCode);
                    if (match) {
                      return {
                        ...i,
                        qty: match.quantity,
                        price: Number(
                          (match.unitPrice * match.quantity).toFixed(2)
                        ),
                        leadDays: match.leadDays || 0,
                      };
                    }
                    return i;
                  }),
                  ...rtn
                    .filter((r) => !prev.find((i) => i.itemCode === r.itemId))
                    .map((r) => {
                      return {
                        itemCode: r.itemId,
                        qty: r.quantity,
                        price: Number((r.unitPrice * r.quantity).toFixed(2)),
                        leadDays: r.leadDays || 0,
                      };
                    }),
                ];
              });

              grid.setData((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  orderGuideGridItemDtos: [
                    ...(prev.orderGuideGridItemDtos || []).map((i) => {
                      const match = rtn.find((r) => r.itemId === i.itemCode);
                      if (match) {
                        return {
                          ...i,
                          fromOg: false,
                        };
                      }
                      return i;
                    }),
                    ...rtn
                      .filter((r) => {
                        return !(prev.orderGuideGridItemDtos || []).find(
                          (i) => i.itemCode === r.itemId
                        );
                      })
                      .map((i) => {
                        return {
                          category: undefined as any,
                          description: undefined as any,
                          fromOg: false,
                          itemCode: i.itemId,
                          itemId: i.itemId,
                          itemDescription: i.description,
                          leadDays: i.leadDays,
                          mwfInvoiceCategory: undefined as any,
                          orderGuideOrdersId: undefined as any,
                          quantity: 0,
                          totalPrice: 0,
                          uniqueValue: undefined as any,
                          unitPrice: i.unitPrice,
                          uom: i.uom,
                          uomChoices: undefined as any,
                        } as SysModels.IOrderGuideGridItemDto;
                      }),
                  ],
                };
              });
            }
            setOpenFindNotInOG(false);

            setTimeout(() => {
              grid.setData((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  orderGuideGridItemDtos: [
                    ...(prev.orderGuideGridItemDtos || []).map((i) => {
                      return {
                        ...i,
                        fromOg: true,
                      };
                    }),
                  ],
                };
              });
            }, 500);
          }}
        ></FindNotInOGDialog>
      )}

      <ConfirmDialog
        show={showLeadTimeDialog}
        title="Lead Time Items"
        message={`
        <h5 class="mb-3 text-danger">Please pay attention to the items with lead times.</h5>
        <div style="font-size: 16px;">Those Items will be automatically shipped according to lead time, 
        <strong>UNLESS</strong> we hear from you by <strong>12:00 PM (noon) today</strong>.
        </div>
        `}
        buttons="okonly"
        done={(rtn) => {
          setShowLeadTimeDialog(false);
          setShowReviewDialog(true);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={showCompanyTimeDialog}
        title="Date Changed"
        message={`We have detected a change in the date since you started the order process. 
          We need to recalculate the dates. Please check your delivery date and re-submit the order.`}
        buttons="okonly"
        done={(rtn) => {
          setShowCompanyTimeDialog(false);
          setRefreshDeliveryDates(commonService.getUniqueId());
        }}
      ></ConfirmDialog>

      {respModel && (
        <ConfirmDialog
          show={showOrderSuccess}
          title="Order Accepted"
          message={`
        Thank you for your order. It was submitted<br/> 
        Total no of line items: ${Number(
          respModel.lineCount || 0
        ).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}<br/> 
        Total Amount: $${Number(respModel.totalOrderAmount || 0).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }
        )}
        <br/> 
        Placed On: ${
          respModel.placedOn
            ? commonService.toLocalDate(respModel.placedOn, "full")
            : ""
        } 
        <br/> 
        Delivery Date: ${
          respModel.deliveryDate
            ? commonService.toDateString(respModel.deliveryDate, "date")
            : ""
        } 
        <br/><br/> 
        <strong>If you do not receive a confirmation email, please call the Midwest Foods office at 773-927-8870</strong>        
        `}
          buttons="okonly"
          done={(rtn) => {
            navigate("/my-orders");
          }}
        ></ConfirmDialog>
      )}

      {showReviewDialog && (
        <>
          <FormModal
            title="Your Order is not Complete until you click 'Submit Order'"
            isOpen={true}
            close={() => {
              setShowReviewDialog(false);
            }}
            submitButtonLabel="Submit Order"
            submit={() => {
              setShowReviewDialog(false);
              validateCompanyTimeBeforeSubmit();
            }}
            size="lg"
          >
            <div className="row pb-3">
              <div className="col-sm-12 col-md-6 col-lg-5">
                <table className="no-format">
                  <tbody>
                    <tr>
                      <td className="pe-2">
                        <strong>Delivery Date:</strong>
                      </td>
                      <td>
                        {dayjs(
                          placeSameDay
                            ? nonDeliveryDates.data?.companyTime
                            : orderDetail.date
                        ).format("ddd, MMM DD, YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2">
                        <strong>PO Number:</strong>
                      </td>
                      <td>{orderDetail.poNumber}</td>
                    </tr>
                    <tr>
                      <td className="pe-2">
                        <strong>Total:</strong>
                      </td>
                      <td>{commonService.toMoney(orderDetail.total)}</td>
                    </tr>
                    {nonDeliveryDates.data &&
                      nonDeliveryDates.data?.minDeliveryAmount > 0 && (
                        <>
                          <tr>
                            <td colSpan={2} className="p-2"></td>
                          </tr>
                          <tr className="text-danger">
                            <td className="pe-2">
                              <strong>Minimum Delivery:</strong>
                            </td>
                            <td>
                              {commonService.toMoney(
                                nonDeliveryDates.data?.minDeliveryAmount
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-7">
                <strong>Comments:</strong>
                <br />
                {(orderDetail.notes || "").trim() === "" ? (
                  <span style={{ color: "#888" }}>
                    <i>Not Supplied</i>
                  </span>
                ) : (
                  orderDetail.notes
                )}
              </div>
            </div>

            <table className="table table-striped table-sm w-100 bg-white">
              <thead>
                <tr>
                  {isSmallScreen && (
                    <>
                      <th>Items</th>
                    </>
                  )}
                  {!isSmallScreen && (
                    <>
                      <th>Item</th>
                      <th>Description</th>
                      <th className="text-center">Quantity</th>
                      <th> UOM</th>
                      <th className="text-right">Price</th>
                      <th className="text-right">Total</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {grid.data?.orderGuideGridItemDtos &&
                  grid.data.orderGuideGridItemDtos
                    .filter((item) =>
                      items.find(
                        (i) => i.itemCode === item.itemCode && i.qty > 0
                      )
                    )
                    .map((item) => (
                      <tr
                        key={item.itemCode}
                        className={`${
                          (items.find((i) => i.itemCode === item.itemCode)
                            ?.qty || 0) > 10
                            ? "qty-gt-10"
                            : ""
                        }`}
                      >
                        {isSmallScreen && (
                          <>
                            <td>
                              <div>
                                <strong>
                                  <small>{item.itemCode}</small>
                                </strong>
                                <br />
                                {item.itemDescription}
                                <br />
                                <small>Category: </small>
                                {item.category}
                                <br />
                                <small>UOM: </small> {item.uom}
                              </div>
                              <div className="flex flex-row">
                                <div className="flex-1">
                                  Qty:{" "}
                                  {(
                                    items.find(
                                      (i) => i.itemCode === item.itemCode
                                    )?.qty || 0
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                                <div className="flex-1 text-right">
                                  $
                                  {(Number(item.unitPrice) || 0).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </div>
                                <div className="flex-1 text-right">
                                  <strong>
                                    $
                                    {(
                                      (Number(item.unitPrice) || 0) *
                                      (items.find(
                                        (i) => i.itemCode === item.itemCode
                                      )?.qty || 0)
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })}
                                  </strong>
                                </div>
                              </div>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td>{item.itemCode}</td>
                            <td>
                              {item.itemDescription}

                              <div className="txt-grey">
                                <small>{item.category}</small>
                              </div>
                            </td>
                            <td className="text-center">
                              {(
                                items.find((i) => i.itemCode === item.itemCode)
                                  ?.qty || 0
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td>{item.uom}</td>
                            <td className="text-right">
                              $
                              {(Number(item.unitPrice) || 0).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td className="text-right">
                              $
                              {(
                                (Number(item.unitPrice) || 0) *
                                (items.find((i) => i.itemCode === item.itemCode)
                                  ?.qty || 0)
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>

            {items.find((i) => i.qty > 10) && (
              <div className="text-right qty-gt-10-label">
                * Some rows have a quantity greater than 10. Please review your
                order carefully.
              </div>
            )}
          </FormModal>
        </>
      )}

      {showNonDelDates && (
        <>
          <FormModal
            title="Non Delivery Date"
            isOpen={true}
            close={() => {
              setShowNonDelDates(false);
            }}
            submit={() => {
              setShowNonDelDates(false);
            }}
            primaryButtonOnly={true}
            submitButtonLabel="Close"
          >
            <div className="pb-3">
              <strong>
                Midwest Foods will not deliver on the date(s) below. Additional
                dates might apply to your account.
              </strong>
            </div>

            <table className="table table-striped table-sm w-100 bg-white">
              <thead>
                <tr>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {nonDeliveryDates.data?.nonDeliveryDateDtosToDisplay &&
                  nonDeliveryDates.data.nonDeliveryDateDtosToDisplay.map(
                    (item, i) => (
                      <tr key={`${i}-${item.nonDeliveryDate}`}>
                        <td>
                          {dayjs(item.nonDeliveryDate).format(
                            "ddd, MMM DD, YYYY"
                          )}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </FormModal>
        </>
      )}

      <div
        className={`order-guide-widget flex flex-col ${
          systemMessageVisible ? "ogw-block-mode" : ""
        }`}
        style={
          systemMessageVisible
            ? {
                marginTop: `${Math.max(
                  0,
                  systemBannerHeight +
                    20 -
                    //+ (window.innerWidth < 992 ? 70 : 0)
                    mainContainerScrollTop
                )}px`,
              }
            : {}
        }
      >
        <div className="flex flex-row flex-center">
          <div>
            <Button
              variant="success"
              size="sm"
              className="me-2"
              disabled={
                saving ||
                !orderDetail.itemCount ||
                !orderDetail.total ||
                !isValidDate
              }
              onClick={() => {
                if (validateDate()) {
                  if (hasItemsWithLeadTime()) {
                    setShowLeadTimeDialog(true);
                  } else {
                    setShowReviewDialog(true);
                  }
                } else {
                  toastStore.showToast(
                    "Order cannot be delivered on selected delivery date",
                    "error"
                  );
                }
              }}
            >
              {saving ? "Saving..." : "Submit"}
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                navigate("/my-orders");
              }}
              disabled={saving}
            >
              Cancel
            </Button>
          </div>
          <div>
            <strong>
              <span className="px-2">|</span>
              <span>Total: </span>$
              <span className="pe-2">
                {orderDetail.total.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
              <span>Items: </span>
              {orderDetail.itemCount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </strong>
          </div>
        </div>

        <div className="flex flex-row gap-10 flex-center w-100">
          <div className="flex flex-1">
            <input
              className="form-control"
              placeholder="Search Item or Description"
              style={{ width: "100%", fontSize: "small" }}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value || "");
              }}
              disabled={saving}
            ></input>
          </div>
          <div>
            <Button
              variant="danger"
              size="sm"
              disabled={(searchTerm || "").trim() === ""}
              onClick={() => setSearchTerm("")}
            >
              Clear
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              size="sm"
              disabled={saving || grid.status === FetchStatus.InProgress}
              onClick={() => setOpenFindNotInOG(true)}
            >
              Find not in OG
            </Button>
          </div>
        </div>
      </div>
      <div className="default-page-layout order-guide">
        <h4>Order Guide</h4>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-5 col-xs-12 order-guide-detail">
            <section>
              <div>
                <div className="flex">
                  <div className="flex-1">
                    <label>
                      Delivery Date <small>(Required)</small>
                    </label>
                    <DateTimePicker
                      key={refreshDeliveryDates}
                      dateOnly={true}
                      onChange={(data) => {
                        setDelDate(data);
                      }}
                      data={
                        placeSameDay
                          ? nonDeliveryDates.data?.companyTime
                          : orderDetail.date //dayjs(orderDetail.date).isValid() ? orderDetail.date : null
                      }
                      dayPickerProps={
                        {
                          // disabledDays: [
                          //   {
                          //     before: new Date(),
                          //     after: dayjs(
                          //       nonDelDateList[(nonDelDateList.length || 0) - 1]
                          //         ?.nonDeliveryDate
                          //     ).toDate(),
                          //   },
                          //   ...nonDelDateList.map((d) =>
                          //     dayjs(d.nonDeliveryDate).toDate()
                          //   ),
                          // ],
                        }
                      }
                      excludeDateIntervals={[
                        {
                          start: new Date(1, 1, 1),
                          end: new Date(),
                        },
                        {
                          start: dayjs(
                            nonDelDateList[(nonDelDateList.length || 0) - 1]
                              ?.nonDeliveryDate
                          ).toDate(),
                          end: new Date(9999, 1, 1),
                        },
                      ]}
                      excludeDates={[
                        ...nonDelDateList.map((d) =>
                          dayjs(d.nonDeliveryDate).toDate()
                        ),
                      ]}
                      style={{
                        width: "100%",
                      }}
                      disabled={
                        nonDeliveryDates.status === FetchStatus.InProgress ||
                        saving ||
                        placeSameDay
                      }
                    ></DateTimePicker>
                    {!isValidDate && calledOnceInitialData && (
                      <div className="mb-2 text-danger">
                        <small>Invalid Delivery Date</small>
                      </div>
                    )}
                  </div>
                  {!!(
                    systemStore.extension_MWFRole &
                      SysModels.UserRolesEnum.Sales ||
                    systemStore.salesAccountViewing?.role === "Sales Rep"
                  ) && (
                    <div className="flex-0">
                      <div className="no-wrap mb-2">Same Day</div>
                      <div className="pt-2">
                        <SwitchButton
                          checkedLabel=""
                          uncheckedLabel=""
                          checked={placeSameDay}
                          onChange={(checked) => {
                            setPlaceSameDay(checked);
                          }}
                        ></SwitchButton>
                      </div>
                    </div>
                  )}
                </div>

                <div className="pt-2">
                  <small>
                    <span className="me-2">Eligible Days:</span>
                    <strong>
                      {nonDeliveryDates.status === FetchStatus.InProgress
                        ? "Loading..."
                        : nonDeliveryDates.data &&
                          [
                            nonDeliveryDates.data.mondayDelivery ? "M" : "_",
                            nonDeliveryDates.data.tuesdayDelivery ? "T" : "_",
                            nonDeliveryDates.data.wednesdayDelivery ? "W" : "_",
                            nonDeliveryDates.data.thursdayDelivery ? "Th" : "_",
                            nonDeliveryDates.data.fridayDelivery ? "F" : "_",
                            nonDeliveryDates.data.saturdayDelivery ? "S" : "_",
                            nonDeliveryDates.data.sundayDelivery ? "Su" : "_",
                          ].join(" ")}
                    </strong>
                  </small>
                  {nonDeliveryDates.status === FetchStatus.Complete && (
                    <div>
                      <small
                        className="text-success pointer"
                        onClick={() => setShowNonDelDates(true)}
                      >
                        <i className="fa fa-eye"></i> Non-Delivery Dates
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-2">
                <label>Purchase Order #:</label>
                <input
                  type="text"
                  placeholder="Purchase Order Number"
                  className="form-control"
                  disabled={saving}
                  onChange={(e) => {
                    setOrderDetail((val) => {
                      return {
                        ...val,
                        poNumber: e.target.value,
                      };
                    });
                  }}
                ></input>
              </div>
            </section>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-7 col-xs-12 order-guide-notes">
            <section className="h-100">
              <label>Order Notes:</label>
              <textarea
                className="form-control"
                rows={4}
                disabled={saving}
                onChange={(e) => {
                  setOrderDetail((val) => {
                    return {
                      ...val,
                      notes: e.target.value,
                    };
                  });
                }}
              ></textarea>
            </section>
          </div>
        </div>
      </div>
      {grid.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner message="Getting Order Guide"></CommonSpinner>
        </div>
      )}
      {grid.status === FetchStatus.Complete && (
        <div>
          <div className="row">
            <div className="col-sm-12">
              <label className="mb-2">Filter By:</label>
              <select
                className="form-control px-3 mb-3"
                value={filterBy}
                onChange={(e) => {
                  setFilterBy(e.target.value);
                }}
                disabled={saving}
              >
                {grid.data?.categories.map((cat) => (
                  <option value={cat} key={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              {/* <div className="input-group order-guide-search">
              <div className="input-group-prepend">
                <button className="btn btn-danger" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search product by name, brand, or item code"
                aria-label="Search product by name, brand, or item code"
              ></input>
            </div> */}
            </div>
          </div>

          <table
            className={`table w-100 bg-white ${
              isSmallScreen ? "" : "table-hover"
            }`}
          >
            <thead>
              <tr>
                {isSmallScreen && (
                  <>
                    <th>
                      <div
                        className="flex pointer"
                        onClick={toggleShowItemsWithQty}
                      >
                        <span>ITEMS / QUANTITY</span>
                        <i
                          className={`${
                            withQtyOnly
                              ? "fa fa-check-square"
                              : "zmdi zmdi-square-o"
                          }`}
                          style={{ fontSize: "17px", marginLeft: "8px" }}
                        ></i>
                      </div>
                    </th>
                  </>
                )}
                {!isSmallScreen && (
                  <>
                    <th style={{ width: "15%" }}>ITEM</th>
                    <th style={{ width: "35%" }}>DESCRIPTION</th>
                    <th style={{ width: "20%" }}>
                      <div
                        className="flex pointer"
                        onClick={toggleShowItemsWithQty}
                      >
                        <span>
                          QUANTITY{" "}
                          <strong className="alert alert-primary m-0 p-0 px-2">
                            {orderDetail.itemCount.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </strong>
                        </span>
                        <i
                          className={`${
                            withQtyOnly
                              ? "fa fa-check-square"
                              : "zmdi zmdi-square-o"
                          }`}
                          style={{
                            fontSize: "17px",
                            marginLeft: "8px",
                          }}
                        ></i>
                      </div>
                    </th>
                    <th style={{ width: "10%" }}>UOM</th>
                    <th style={{ width: "10%" }} className="text-right">
                      PRICE
                    </th>
                    <th style={{ width: "10%" }} className="text-right">
                      TOTAL
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {grid.data?.orderGuideGridItemDtos &&
                grid.data.orderGuideGridItemDtos
                  .filter(
                    (item) => filterBy === "All" || item.category === filterBy
                  )
                  .filter((item) => {
                    const term = (searchTerm || "").trim().toLowerCase();
                    if (term === "") {
                      return true;
                    }
                    return [
                      ...(item.itemCode || "").split(" "),
                      ...(item.itemDescription || "").split(" "),
                    ].find((s) => {
                      return (s || "").trim().toLowerCase().indexOf(term) === 0;
                    });
                  })
                  .map((item) => (
                    <tr
                      key={`${item.itemCode}-${gridDataReady}`}
                      className={`${
                        items.find(
                          (i) =>
                            i.itemCode === item.itemCode &&
                            (!withQtyOnly || (withQtyOnly && i.show))
                        )
                          ? ""
                          : withQtyOnly
                          ? "display-none"
                          : ""
                      }`}
                    >
                      {isSmallScreen && (
                        <>
                          <td>
                            <div className="pt-3">
                              <strong className="pe-2">{item.itemCode}</strong>
                              {!!item.category && (
                                <small className="alert alert-sm alert-secondary p-0 px-1">
                                  {item.category}
                                </small>
                              )}
                            </div>
                            <div
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              {item.itemDescription}
                            </div>
                            <div className="flex flex-row w-100 pt-2">
                              <div>
                                <small>UOM:</small> {item.uom}
                              </div>
                              <div className="flex-1 text-right">
                                <small>Price:</small> $
                                {(Number(item.unitPrice) || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </div>
                            </div>
                            <div className="flex flex-center pt-2 pb-3">
                              <div
                                className="flex-1 flex flex-center"
                                style={{ justifyContent: "start" }}
                              >
                                <span className="pe-2">
                                  <small>QTY:</small>
                                </span>
                                <input
                                  key={`${gridDataReady}`}
                                  type="number"
                                  step="0"
                                  min="0"
                                  max="999999"
                                  className="form-control py-0 px-1"
                                  style={{ width: "100px" }}
                                  disabled={saving}
                                  defaultValue={
                                    items.find(
                                      (i) => i.itemCode === item.itemCode
                                    )?.qty
                                  }
                                  onChange={(e) => {
                                    const qty = Number(e.target.value) || 0;
                                    toggleQty(item, qty);
                                  }}
                                ></input>
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                <small>Total:</small> $
                                {(
                                  (Number(item.unitPrice) || 0) *
                                  Number(
                                    items.find(
                                      (i) => i.itemCode === item.itemCode
                                    )?.qty || 0
                                  )
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </div>
                            </div>
                            {/* <div
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                                textAlign: "right",
                              }}
                            >
                              <small>Total:</small> $
                              {(
                                (Number(item.unitPrice) || 0) *
                                Number(
                                  items.find(
                                    (i) => i.itemCode === item.itemCode
                                  )?.qty || 0
                                )
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </div> */}
                          </td>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>{item.itemCode}</td>
                          <td>
                            {item.itemDescription}

                            <div className="txt-grey">
                              <small>{item.category}</small>
                            </div>
                          </td>
                          <td>
                            <input
                              key={`${gridDataReady}`}
                              type="number"
                              step="0"
                              min="0"
                              max="999999"
                              pattern=""
                              className="form-control"
                              style={{ width: "120px" }}
                              disabled={saving}
                              defaultValue={
                                items.find((i) => i.itemCode === item.itemCode)
                                  ?.qty
                              }
                              onChange={(e) => {
                                const qty = Number(e.target.value) || 0;
                                toggleQty(item, qty);
                              }}
                            ></input>
                          </td>
                          <td>{item.uom}</td>
                          <td className="text-right">
                            $
                            {(Number(item.unitPrice) || 0).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td className="text-right">
                            <div style={{ paddingLeft: "20px" }}>
                              $
                              {(
                                (Number(item.unitPrice) || 0) *
                                Number(
                                  items.find(
                                    (i) => i.itemCode === item.itemCode
                                  )?.qty || 0
                                )
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default OrderGuide;
