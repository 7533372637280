import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import SwitchButton from "../components/common/SwitchButton";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import { useParams } from "react-router-dom";

function Survey(props: any) {
  const { token } = useParams();
  const survey = useFetchHelper(async () => {
    return SysServices.http.survey.get(token || "");
  }, "Survey");
  const [model, setModel] = useState<SysModels.ISurveyInputDto>({
    deliveryRating: 1,
    productQualityRating: 1,
    customerServiceRating: 1,
    onlineOrderingRating: 1,
    salesRepRating: 1,
    overallCompanyRating: 1,
    comment: "",
    surveyToken: "",
  });

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    survey.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, survey]);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const save = async () => {
    setSaving(true);
    await SysServices.http.survey
      .save({
        ...model,
        surveyToken: survey.data?.surveyToken || "",
      })
      .then(() => {
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => {
        toastStore.showError("Failing Submitting Survey", error);
        setSaving(false);
      });
  };

  const RatingComponent = ({
    rating,
    title,
    icon,
    applicable,
    onChange,
  }: {
    rating: number;
    title: string;
    icon: string;
    applicable?: boolean;
    onChange: (val: any) => void;
  }) => {
    const [isApplicable, setIsApplicable] = useState(rating > 0);
    const [hovered, setHovered] = useState(0);
    const GetMessage = ({ val }: { val: number }) => {
      if (val === 1) return <span className="chip-red">Poor</span>;
      if (val === 5) return <span className="chip-green">Excellent</span>;
      return <span className="chip-orange">{val} Stars</span>;
    };
    return (
      <div className="star-rating">
        <div>
          <i className={`fa ${icon}`}></i>
        </div>
        <div>
          <section className="pb-2">
            <strong>{title}</strong>
          </section>
          {isApplicable &&
            [1, 2, 3, 4, 5].map((r) => {
              let star = r <= rating;
              if (hovered > 0) {
                star = r <= hovered;
              }
              return (
                <i
                  key={r}
                  className={`fa ${star ? "fa-star" : "fa-star-o"}`}
                  onMouseOver={(e) => {
                    setHovered(r);
                  }}
                  onMouseLeave={(e) => {
                    setHovered(0);
                  }}
                  onClick={(e) => {
                    onChange(r);
                  }}
                ></i>
              );
            })}
          <div className="mt-2">
            {applicable && (
              <SwitchButton
                checked={isApplicable}
                checkedLabel="Applicable"
                onChange={(val) => {
                  setIsApplicable(val);
                  onChange(val ? rating || 1 : 0);
                }}
              ></SwitchButton>
            )}
            <span className="px-2">
              {hovered > 0 && <GetMessage val={hovered}></GetMessage>}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="survey-page"
        style={{ backgroundImage: `url(img/bg-login.jpg)` }}
      >
        {survey.status === FetchStatus.InProgress && (
          <div className="p-4">
            <CommonSpinner></CommonSpinner>
          </div>
        )}
        {survey.status === FetchStatus.Failed && (
          <div className="p-4 text-danger">
            <i className="fa fa-warning me-1"></i> The Survey Code you provided
            has either been filled out, or it doesn't exist.
            <div className="mt-2">
              <a href="/">Return to Login Page</a>
            </div>
          </div>
        )}

        {saved && (
          <div className="p-4 text-primary">
            <h4>Thank you for your comments. We appreciate it.</h4>
            <div className="mt-2">
              <a href="/">Return to Login Page</a>
            </div>
          </div>
        )}

        {survey.status === FetchStatus.Complete && !saved && (
          <>
            <div className="mt-3">
              <h4>Midwest Foods Customer Satisfaction Survey</h4>
              <p>
                Please rate your experience with us on a scale from one to five,
                1 star being the most disappointing and 5 stars being the most
                exceptional.
              </p>
            </div>
            <div className="survey-form">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.deliveryRating}
                    title="Delivery"
                    icon="fa-truck"
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          deliveryRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.productQualityRating}
                    title="Product Quality"
                    icon="fa-lemon-o"
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          productQualityRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.customerServiceRating}
                    title="Customer Service"
                    icon="fa-phone-square"
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          customerServiceRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.onlineOrderingRating}
                    title="Online Ordering"
                    icon="fa-shopping-cart"
                    applicable={true}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          onlineOrderingRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.salesRepRating}
                    title="Sales Representative"
                    icon="fa-handshake-o"
                    applicable={true}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          salesRepRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
                <div className="col-sm-12 col-md-6">
                  <RatingComponent
                    rating={model.overallCompanyRating}
                    title="Overall Company Performance"
                    icon="fa-smile-o"
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          overallCompanyRating: val,
                        };
                      });
                    }}
                  ></RatingComponent>
                </div>
              </div>

              <div className="text-left">
                <strong>Comment</strong>
                <textarea
                  className="form-control mt-2"
                  rows={3}
                  onChange={(e) => {
                    setModel((p) => {
                      return {
                        ...p,
                        comment: e.target.value || "",
                      };
                    });
                  }}
                  disabled={saving}
                ></textarea>
              </div>
              <div className="mt-3 text-right flex">
                <Button
                  type="button"
                  variant="outline-secondary"
                  className="me-3"
                  onClick={(e) => {
                    window.location.href = "/";
                  }}
                  disabled={saving}
                >
                  Back to Login Page
                </Button>
                <Button
                  type="button"
                  variant="success"
                  className="flex-1"
                  disabled={saving}
                  onClick={(e) => {
                    save();
                  }}
                >
                  {saving ? "Submitting Survey..." : "Submit Survey"}
                </Button>
              </div>

              <div className="text-left mt-4 mb-2 text-danger">
                Problems? Call: <a href="tel:773-927-8870">773-927-8870</a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Survey;
