import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore, { useAllowOrderGuide } from "../stores/SystemStore";
import CatalogItem, { useCatalogFavorite } from "./catalog/CatalogItem";

function ProductDetail(props: any) {
  const { id } = useParams();
  const navigate = useNavigate();

  const item = useFetchHelper(async () => {
    return SysServices.http.catalog.getItem(id || "");
  }, "Product Detail");

  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const items = useFetchHelper(async () => {
    return SysServices.http.catalog.listProducts(paging.page, paging.pageSize, {
      categoryId: item.data?.categoryId || "",
      search: "",
      orderByEnum: SysModels.OrderByEnum.Ascending,
    });
  }, "Products");

  const fav = useCatalogFavorite(id || "");
  const [qty, setQty] = useState(1);

  useEffect(() => {
    if (item.status === FetchStatus.Complete) {
      items.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.status, paging]);

  useEffect(() => {
    item.getData();
    if (id !== fav.itemId) {
      fav.setItemId(id || "");
      setQty(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const allowOrderGuide = useAllowOrderGuide();

  return (
    <div className="default-page-layout product-detail">
      {item.status === FetchStatus.InProgress && (
        <CommonSpinner message="Getting Product Detail"></CommonSpinner>
      )}
      {item.status === FetchStatus.Complete && !!item.data && (
        <>
          <h4 className="flex flex-wrap flex-center">
            <span className="flex-1 mb-2" style={{ minWidth: "300px" }}>
              <span
                className="txt-grey"
                onClick={(e) => {
                  navigate("/catalog");
                }}
              >
                Product Catalog
              </span>{" "}
              <i className="fa fa-angle-right txt-grey mx-2"></i>{" "}
              <span className="no-wrap">Product Details</span>
            </span>
          </h4>
          <div className="product-detail-container">
            <div>
              {item.data.erpImageUrl && (
                <img alt="" src={item.data.erpImageUrl}></img>
              )}
              {!item.data?.erpImageUrl && (
                <div className="no-image">
                  <i className="fa fa-image"></i>
                </div>
              )}
            </div>
            <div>
              <h3
                dangerouslySetInnerHTML={{ __html: item.data.description }}
              ></h3>
              <div>
                <h5 className="text-success my-4">
                  {commonService.toMoney(item.data?.unitPrice)}
                </h5>
                <p
                  className="txt-grey"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.data?.webLongDescription ||
                      item.data?.description ||
                      "Loading...",
                  }}
                ></p>
                {/* <div className="flex flex-wrap gap-10 mt-4">
                  <div className="form-group" style={{ minWidth: "250px" }}>
                    <label>PRODUCT SIZE</label>
                    <select className="form-control mt-2">
                      <option>Large</option>
                    </select>
                  </div>
                  <div className="form-group" style={{ minWidth: "250px" }}>
                    <label>PACK SIZE</label>
                    <select className="form-control mt-2">
                      <option>{item.data.unit}</option>
                    </select>
                  </div>
                </div> */}
                <div
                  className={`flex flex-wrap gap-10 mt-4 ${
                    allowOrderGuide ? "" : "display-none"
                  }`}
                >
                  <input
                    key={id}
                    type="number"
                    step="0"
                    min="0"
                    max="999999"
                    className="form-control"
                    style={{ width: "130px" }}
                    defaultValue={qty}
                    onChange={(e) => {
                      setQty(Number(e.target.value));
                    }}
                  ></input>
                  <div className="flex flex-wrap gap-10">
                    <button
                      className="btn btn-success btn-icon-label"
                      style={{ width: "180px" }}
                      onClick={(e) => {
                        if (item.data) {
                          systemStore.addItemToSavedCart({
                            itemCode: item.data.itemId,
                            name: item.data.description,
                            unit: item.data.uom,
                            price: 0, //TODO: PRICE,
                            qty: qty,
                          });
                        }
                      }}
                    >
                      <i className="fa fa-shopping-cart"></i>
                      <span>Add To Order</span>
                      <em>Add To Order</em>
                    </button>
                    <button
                      className="btn btn-light txt-grey"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        fav.toggleFavorite();
                      }}
                    >
                      {!fav.savingFavorite && (
                        <i
                          className={`fa ${
                            fav.isFavorite
                              ? "fa-star text-warning"
                              : "fa-star-o"
                          }`}
                        ></i>
                      )}
                      {fav.savingFavorite && (
                        <i className="fa fa-spin fa-circle-o-notch"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 product-other-detail-container">
            <Tabs transition={false}>
              <Tab
                eventKey="frequentlypurchasedtogether"
                title="Frequently Purchased Together"
              >
                {items.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                {items.status === FetchStatus.Complete && items.data && (
                  <>
                    <div className="catalog-items">
                      {items.data?.erpInventoryItemOutputDtos
                        ?.filter((v) => v.itemId !== id)
                        ?.map((item) => (
                          <CatalogItem
                            key={item.itemId}
                            item={item}
                          ></CatalogItem>
                        ))}
                    </div>
                    <div className="p-3 pt-0">
                      <Pagination
                        length={(items.data as any)?.totalRecords || 0}
                        page={paging.page}
                        pageSize={paging.pageSize}
                        pageChange={pageChange}
                        showingOfWhatLabel="items"
                        sizes={[10, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </>
                )}
              </Tab>
              <Tab
                eventKey="additionalinformation"
                title="Additional Information"
              >
                <p
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.data?.webLongDescription ||
                      item.data?.description ||
                      "Loading...",
                  }}
                ></p>
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
