import SysModels from "../../models";
import fetcher from "../Fetcher";

export class GeneralDocumentationService {
  getPublishedItems = async () => {
    const url = `/GeneralDocumentation/PublishedItems`;
    return fetcher.get<SysModels.IGeneralDocumentationDisplayGridDto>(url);
  };

  getPublished = async (id: string) => {
    const url = `/GeneralDocumentation/Published/${id}`;
    return fetcher.get<SysModels.IGeneralDocumentationDisplayOutputDto>(url);
  };

  getContentPublished = async (id: string) => {
    const url = `/GeneralDocumentation/GetContentPublished/${id}`;
    return fetcher.get<string>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IGeneralDocumentationSearchDto
  ) => {
    const url = `/GeneralDocumentation/${page}/${pageSize}`;
    return fetcher.post<SysModels.IGeneralDocumentationDisplayGridDto>(
      url,
      data
    );
  };
}

const generalDocumentation = new GeneralDocumentationService();

export default generalDocumentation;
