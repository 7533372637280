import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class OrderService {
  getNotDeliveryDates = async (fromDate: string, toDate: string) => {
    let url = `/Ordering/NotDeliveryDates/${fromDate}/${toDate}`;
    if (systemStore.currentCustomer) {
      url = `/Ordering/NotDeliveryDates/${encodeURIComponent(
        systemStore.currentCustomer.number
      )}/${fromDate}/${toDate}`;
    }
    return fetcher.get<SysModels.INonDeliveryDateGridDto>(url);
  };

  getCompanyTime = async () => {
    let url = `/Ordering/CompanyTime`;
    if (systemStore.currentCustomer) {
      url = `/Ordering/CompanyTime`;
    }
    return fetcher.get<string>(url);
  };

  getNewOrderGuide = async () => {
    let url = `/Ordering/NewOrderGuide`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.IOrderGuideGridDto>(url);
  };

  getOrderGuideFromTemplate = async (templateId: any) => {
    let url = `/Ordering/OrderGuideFromTemplate/${templateId}`;
    if (systemStore.currentCustomer) {
      url = `/Ordering/OrderGuideFromTemplate/${encodeURIComponent(
        systemStore.currentCustomer.number
      )}/${templateId}`;
    }
    return fetcher.get<SysModels.IOrderGuideGridDto>(url);
  };

  getOrderGuideFromNetSuiteTemplate = async (templateId: any) => {
    let url = `/Ordering/OrderGuideFromNetSuiteTemplate/${templateId}`;
    if (systemStore.currentCustomer) {
      url = `/Ordering/OrderGuideFromNetSuiteTemplate/${encodeURIComponent(
        systemStore.currentCustomer.number
      )}/${templateId}`;
    }
    return fetcher.get<SysModels.IOrderGuideGridDto>(url);
  };

  create = async (dto: SysModels.IOrderGuideInputDto) => {
    let url = `/Ordering`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.IOrderOutputDto>(url, dto);
  };

  searchItemNotInOg = async (dto: SysModels.IErpInventorySearchDto) => {
    let url = `/Ordering/SearchItemNotInOg`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.IErpInventoryGridDto>(url, dto);
  };

  getNewOrderGuideForDownload = async () => {
    let url = `/Ordering/NewOrderGuideForDownload`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const order = new OrderService();
export default order;
