import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { loginRequest } from "../AuthConfig";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";
import SysServices from "../services";

function LoggedOut(props: any) {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="MWF Splash" src="img/midwestsplash.png" />
        <div>You've been logged out</div>
        <Button
          className="mt-3"
          variant="primary"
          onClick={() => {
            if (commonService.isEmployeeSite) {
              instance.loginRedirect(loginRequest);
            } else {
              SysServices.http.fetcher.clearToken();
              navigate("/");
            }
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default LoggedOut;
