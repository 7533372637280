import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class DashboardAnnouncementService {
  getContentForDisplay = async (customerNumber?: string) => {
    let url = `/DashboardAnnouncement`;
    if (customerNumber) {
      url = `${url}/ContentForDisplay/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/ContentForDisplay/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.IDashboardAnnouncementForDisplayDto>(url);
  };

  getFeaturedItemsForDisplay = async (customerNumber?: string) => {
    let url = `/DashboardAnnouncement/FeaturedItemsForDisplay`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.IDashboardAnnouncementForDisplayDto>(url);
  };

  leftHandSideCategories = async (customerNumber?: string) => {
    let url = `/DashboardAnnouncement/LeftHandSideCategories`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.ICatalogFeaturedCategoryOutputDto[]>(url);
  };

  leftHandSideFeaturedItemsForDisplay = async (
    categoryId: string,
    customerNumber?: string
  ) => {
    let url = `/DashboardAnnouncement/LeftHandSideFeaturedItemsForDisplay`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.IDashboardAnnouncementForDisplayDto>(
      `${url}/${categoryId}`
    );
  };
}

const dashboardAnnouncement = new DashboardAnnouncementService();
export default dashboardAnnouncement;
