import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import { Button } from "react-bootstrap";
import DocEditor from "../components/common/DocEditor";
import { useWindowSize } from "../stores/SystemStore";
import Pagination, { usePaging } from "../components/common/Pagination";

function CustomerDocumentation(props: any) {
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(
    async () =>
      SysServices.http.generalDocumentation.list(paging.page, paging.pageSize, {
        search: search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
        onlyPublished: true,
      }),
    "Documentation"
  );

  const [selectedDoc, setSelectedDoc] =
    useState<SysModels.IGeneralDocumentationDisplayOutputDto>();
  const docObj = useFetchHelper(
    async () =>
      SysServices.http.generalDocumentation.getPublished(
        selectedDoc?.sqlId || ""
      ),
    "Documentation"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  useEffect(() => {
    if (
      grid.status === FetchStatus.Complete &&
      grid.data &&
      grid.data.generalDocumentationDisplayOutputDtos.length > 0
    ) {
      setSelectedDoc(grid.data.generalDocumentationDisplayOutputDtos[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  useEffect(() => {
    if (!!selectedDoc) {
      docObj.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoc]);
  const [expand, setExpand] = useState(false);
  const windowSize = useWindowSize();

  return (
    <>
      <div className="default-page-layout h-100 flex flex-column">
        <h4 className="hide-on-print">How To</h4>
        <div className="bg-white col-sm-12 flex-1 mb-4">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div className="customer-documentation h-100">
              <div
                className={`flex flex-column hide-on-print ${
                  expand ? "display-none" : ""
                }`}
              >
                <div className="p-3 flex flex-wrap gap-10">
                  <div className="flex-0" style={{ maxWidth: "100%" }}>
                    <div className="input-group search-box">
                      <input
                        autoFocus={true}
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={search.typed}
                        onChange={(e) => {
                          setSearch((data) => {
                            return {
                              ...data,
                              typed: e.target.value,
                            };
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }
                        }}
                      ></input>
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              return { typed: "", used: "" };
                            });
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-0"></div>
                  <div className="flex-1"></div>
                </div>

                <div className="flex-1">
                  <table className="table table-hover pointer m-0">
                    <thead>
                      <tr>
                        <th>Title / Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grid.data?.generalDocumentationDisplayOutputDtos?.map(
                        (doc) => (
                          <tr
                            key={doc.sqlId}
                            onClick={(e) => {
                              setSelectedDoc(doc);
                              var anchor =
                                document.querySelector("#document-preview");
                              anchor?.scrollIntoView();
                            }}
                            className={`${
                              selectedDoc?.sqlId === doc.sqlId
                                ? "selected-document"
                                : ""
                            }`}
                          >
                            <td>
                              <div className="flex">
                                <div className="flex-1">{doc.title}</div>
                                <div>
                                  {!windowSize.isSmall && (
                                    <i
                                      title="Open in New Tab"
                                      className="fa fa-external-link txt-primary"
                                      onClick={(e) => {
                                        window.open(
                                          `/how-to/${doc.sqlId}`,
                                          "_blank"
                                        );
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                              <div>
                                <small>{doc.subject}</small>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    //showingOfWhatLabel="documents"
                    sizes={[10, 25, 50, 100]}
                    noJumpToPage={true}
                  ></Pagination>
                </div>
              </div>
              <div>
                <div id="document-preview">
                  {docObj.status === FetchStatus.InProgress && (
                    <div className="py-4">
                      <CommonSpinner></CommonSpinner>
                    </div>
                  )}
                  {docObj.status === FetchStatus.Failed && (
                    <div className="py-4 text-center">
                      <strong className="text-danger">
                        Failed Getting Document
                      </strong>
                    </div>
                  )}
                  {!!docObj.data &&
                    !!selectedDoc &&
                    docObj.status === FetchStatus.Complete && (
                      <div>
                        <div className="show-on-print">
                          <strong>{selectedDoc.title}</strong>
                          <div
                            className="py-2"
                            style={{ borderBottom: "solid 1px #ddd" }}
                          >
                            {selectedDoc.subject}
                          </div>
                        </div>

                        <div
                          className="hide-on-print px-4 py-2 flex flex-center"
                          style={{
                            background: "#eee",
                            borderBottom: "solid 1px #ddd",
                          }}
                        >
                          <div className="pe-4">
                            <Button
                              type="button"
                              variant={expand ? "danger" : "light"}
                              size="sm"
                              onClick={(e) => {
                                setExpand(!expand);
                              }}
                            >
                              {!expand && (
                                <i className="fa fa-expand text-primary"></i>
                              )}
                              {expand && <i className="fa fa-compress"></i>}
                            </Button>
                          </div>
                          <div className="flex-1">
                            <div className="mb-2">
                              <strong>{selectedDoc.title}</strong>
                            </div>
                            {!!`${selectedDoc.subject || ""}`.trim() && (
                              <div>{selectedDoc.subject}</div>
                            )}
                          </div>
                          <div>
                            <Button
                              type="button"
                              variant="default"
                              onClick={(e) => {
                                window.print();
                              }}
                            >
                              <i className="fa fa-print txt-primary"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="p-4">
                          <DocEditor
                            readonly={true}
                            content={docObj.data.content || ""}
                          ></DocEditor>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerDocumentation;
