import React from "react";

function SwitchButton({
  checked,
  checkedLabel,
  uncheckedLabel,
  onChange,
  disabled,
}: {
  checked: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <div className="switch-container">
      {checkedLabel && <label>{checkedLabel}</label>}
      <button
        className={`switch-button ${checked ? "switch-on" : ""}`}
        onClick={() => {
          onChange && onChange(!checked);
        }}
        disabled={disabled || false}
      ></button>
      {uncheckedLabel && <label>{uncheckedLabel}</label>}
    </div>
  );
}

export default SwitchButton;
