import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import PaymentStatusBadge from "../components/common/PaymentStatusBadge";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore, {
  useCurrentCustomer,
  useLHSCategories,
  useSavedCartItems,
} from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import CatalogItem from "./catalog/CatalogItem";
import CustomerDetail from "./customer/CustomerDetail";
import OrderDetailDialog from "./orders/OrderDetailDialog";
import UseAsTemplateConfirmation from "./orders/UseAsTemplateConfirmation";
import OrderSummaryWidget from "./widgets/OrderSummaryWidget";

function CustomerDashboard(props: any) {
  const navigate = useNavigate();
  const currentCustomer = useCurrentCustomer();
  const [hasOverDue, setHasOverDue] = useState(false);
  // const [showOverDueBanner, setShowOverDueBanner] = useState(false);
  // const [bannerDismissed, setBannerDismissed] = useState(
  //   systemStore.flags.dashboardBannerDismissed
  // );
  //const [customerInfo] = useState(systemStore.currentCustomer);
  const summary = useFetchHelper<SysModels.IAgingSummaryDto>(
    SysServices.http.sales.agingSummary,
    "Aging Summary"
  );
  const grid = useFetchHelper<SysModels.ISalesOrderGridDto>(
    () =>
      SysServices.http.sales.listSalesOrders(1, 10, {
        salesOrderGridOrderByEnum:
          SysModels.SalesOrderGridOrderByEnum.OrderDate,
        orderByEnum: SysModels.OrderByEnum.Descending,
        fromDate: null as any,
        toDate: null as any,
        search: "",
      }),
    "Latest Orders"
  );

  const htmlAnnouncement =
    useFetchHelper<SysModels.IDashboardAnnouncementForDisplayDto>(
      async () => SysServices.http.dashboardAnnouncement.getContentForDisplay(),
      "Announcement"
    );

  const featuredItemsForDisplay =
    useFetchHelper<SysModels.IDashboardAnnouncementForDisplayDto>(
      async () =>
        SysServices.http.dashboardAnnouncement.getFeaturedItemsForDisplay(),
      "Featured Items"
    );

  // useEffect(() => {
  //   const disposer = reaction(
  //     () => systemStore.flags,
  //     (val, prevVal, r) => {
  //       setBannerDismissed(val.dashboardBannerDismissed);
  //     }
  //   );
  //   return () => {
  //     disposer();
  //   };
  // }, []);

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);

    if (!commonService.isEmployeeSite) {
      summary.getData();
      htmlAnnouncement.getData();
      featuredItemsForDisplay.getData();

      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, summary, grid]);

  useEffect(() => {
    if (commonService.isEmployeeSite && currentCustomer) {
      summary.getData();
      htmlAnnouncement.getData();
      featuredItemsForDisplay.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer]);

  useEffect(() => {
    if (summary.status === FetchStatus.Complete) {
      const val = (summary.data?.totalOverDue || 0) > 0;
      //setShowOverDueBanner(val);
      setHasOverDue(val);
    }
  }, [summary.status, summary.data]);

  const [reportOrder, setReportOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<SysModels.ISalesOrderGridItemDto>();

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
  }, []);
  const [showSpinnerMessage, setShowSpinnerMessage] = useState<string>();
  const [useTempConfirm, setUseTempConfirm] = useState<{
    id: number;
    netSuite: boolean;
  }>();
  const savedCartItems = useSavedCartItems();

  const Actions = ({
    item,
    i,
  }: {
    i: number;
    item: SysModels.ISalesOrderGridItemDto;
  }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          size="sm"
          id={`dropdownProfile${i}${item.webOrderNumber}`}
          variant="outline-primary"
        >
          <i className="fa fa-bars"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu align="right">
          <Dropdown.Item
            onClick={() => {
              setSelectedOrder(item);
              setReportOrder(false);
            }}
          >
            View Detail
          </Dropdown.Item>
          {systemStore.allowOrderGuide && (
            <Dropdown.Item
              onClick={() => {
                if (item.portalSqlId || item.nsSqlId) {
                  if (savedCartItems && savedCartItems.items?.length > 0) {
                    setUseTempConfirm({
                      id: item.nsSqlId ? item.nsSqlId : item.portalSqlId,
                      netSuite: !!item.nsSqlId,
                    });
                  } else {
                    systemStore.setOrderGuideTemplate(
                      item.nsSqlId ? item.nsSqlId : item.portalSqlId,
                      !!item.nsSqlId
                    );
                    navigate("/order-guide");
                  }
                }
              }}
            >
              Use as Template
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              setShowSpinnerMessage("Sending Email...");
              (item.nsSqlId
                ? SysServices.http.sales.sendSalesOrderConfirmationEmail(
                    item.nsSqlId
                  )
                : SysServices.http.sales.sendOrderGuideOrderConfirmationEmail(
                    item.portalSqlId
                  )
              )
                .then(() => {
                  setShowSpinnerMessage(undefined);
                  toastStore.showToast("Email Sent.", "success");
                })
                .catch((error) => {
                  setShowSpinnerMessage(undefined);
                  toastStore.showError("Failed Sending Email", error);
                });
            }}
          >
            Email Me a Copy
          </Dropdown.Item>
          {!commonService.isEmployeeSite && (
            <Dropdown.Item
              onClick={() => {
                setSelectedOrder(item);
                setReportOrder(true);
              }}
            >
              Report a Problem
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const [activeTab, setActiveTab] = useState("OrderSummary");
  const categs = useLHSCategories();

  useEffect(() => {
    if (categs.length > 1) {
      setActiveTab("OrderSummary");
    } else {
      setActiveTab("RecentOrders");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categs]);

  return (
    <div>
      {!!useTempConfirm && (
        <UseAsTemplateConfirmation
          onClose={(rtn) => {
            if (rtn === "append" || rtn === "clear") {
              systemStore.setOrderGuideTemplate(
                useTempConfirm.id,
                useTempConfirm.netSuite
              );
              navigate("/order-guide");
            }
            setUseTempConfirm(undefined);
          }}
        ></UseAsTemplateConfirmation>
      )}
      {!!showSpinnerMessage && (
        <CommonSpinner
          overlay={true}
          message={showSpinnerMessage}
        ></CommonSpinner>
      )}

      {/* DISABLE BANNER FOR NOW */}
      {/* {!bannerDismissed && showOverDueBanner && (
        <AlertBanner
          type="warning"
          onClose={() => {
            setShowOverDueBanner(false);
            systemStore.setFlags({ dashboardBannerDismissed: true });
          }}
        >
          <strong>Did you forget about us?</strong> - We didn't forget about
          you. It looks like you have a past due balance on your account.
          Contact{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/customer-service");
            }}
          >
            customer service
          </a>
          .
        </AlertBanner>
      )} */}

      {selectedOrder && (
        <OrderDetailDialog
          order={selectedOrder}
          onClose={() => setSelectedOrder(undefined)}
          reportProblem={reportOrder}
        ></OrderDetailDialog>
      )}

      <div className="dashboard">
        <div className="dashboard-widgets">
          {(summary.status === FetchStatus.InProgress || hasOverDue) && (
            <div onClick={() => navigate("/billing-center/invoices")}>
              <div>
                <h4 className={`${hasOverDue ? "txt-danger" : ""}`}>
                  Billing Alert
                </h4>
                <span>
                  Overdue Balance: $
                  <span className="counter-anim">
                    {(summary.data?.totalOverDue || 0).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                    )}
                  </span>
                </span>
              </div>
              {summary.status === FetchStatus.Complete && (
                <i
                  className={`icon-bell ${hasOverDue ? "txt-danger" : ""}`}
                ></i>
              )}
              {summary.status === FetchStatus.InProgress && (
                <CommonSpinner></CommonSpinner>
              )}
            </div>
          )}

          {systemStore.allowOrderGuide && ( //(!currentCustomer || currentCustomer?.data?.orderGuideAllowedYn)
            <div onClick={() => navigate("/order-guide")}>
              <div style={{ position: "relative" }}>
                <h4>Place an Order </h4>
                <span>Order Guide </span>
                {!!savedCartItems?.items?.length && (
                  <span
                    className="chip-red px-2 mx-1"
                    style={{
                      color: "#fff",
                      position: "absolute",
                      top: "2px",
                      right: "-60px",
                      zIndex: 10,
                    }}
                  >
                    {savedCartItems?.items?.length}
                  </span>
                )}
              </div>
              <i className="icon-list"></i>
            </div>
          )}

          <div onClick={() => navigate("/my-orders")}>
            <div>
              <h4>
                {commonService.isEmployeeSite ? "Customer Orders" : "My Orders"}
              </h4>
              <span>View Recent Orders</span>
            </div>
            <i className="icon-social-dropbox"></i>
          </div>

          {((commonService.isEmployeeSite && !!currentCustomer) ||
            systemStore.customerInfo?.orderGuideAllowedYn) && (
            <div onClick={() => navigate("/print-guide")}>
              <div>
                <h4>Print Guide</h4>
                <span>Print Order Guide</span>
              </div>
              <i className="fa fa-print"></i>
            </div>
          )}

          {commonService.isEmployeeSite &&
            !!currentCustomer &&
            (!!htmlAnnouncement.data?.content ||
              !!featuredItemsForDisplay.data?.erpInventoryItemOutputDtos
                ?.length) && (
              <div
                onClick={() => {
                  document
                    .getElementById("linkCustomerDetail")
                    ?.scrollIntoView();
                }}
              >
                <div>
                  <h4>Customer Detail</h4>
                  <span>See Customer Detail</span>
                </div>
                <i className="fa fa-info-circle"></i>
              </div>
            )}
        </div>

        {commonService.isEmployeeSite && (
          <div className="flex flex-column flex-1">
            <FeaturedSectionComponent
              htmlAnnouncement={htmlAnnouncement}
              featuredItemsForDisplay={featuredItemsForDisplay}
            />

            <div className={`dashboard-feature ${isSmallScreen ? "p-0" : ""}`}>
              <div className="p-3 pt-0 pb-0">
                <h4 className="pt-3" id="linkCustomerDetail">
                  Customer Detail
                </h4>
              </div>
              <div className="p-3" key={currentCustomer?.id || ""}>
                <CustomerDetail></CustomerDetail>
              </div>
            </div>
          </div>
        )}

        {!commonService.isEmployeeSite && (
          <div className="flex flex-column flex-1">
            <FeaturedSectionComponent
              htmlAnnouncement={htmlAnnouncement}
              featuredItemsForDisplay={featuredItemsForDisplay}
            />

            <Tabs
              transition={false}
              activeKey={activeTab}
              onSelect={(tab) => {
                tab && setActiveTab(tab);
              }}
            >
              {categs.length > 1 && (
                <Tab eventKey="OrderSummary" title="Order Summary">
                  <div className="p-2 py-1 bg-white">
                    <OrderSummaryWidget
                      hide={activeTab !== "OrderSummary"}
                      action={SysServices.http.customer.customerOrderingSummary}
                    ></OrderSummaryWidget>
                  </div>
                </Tab>
              )}
              <Tab eventKey="RecentOrders" title="Recent Orders">
                <div
                  className={`dashboard-feature ${isSmallScreen ? "p-0" : ""}`}
                >
                  <div className="p-3 pt-0 pb-0">
                    <h4 className="pt-3">Recent Orders</h4>
                  </div>
                  <div className={isSmallScreen ? "" : "p-3"}>
                    {grid.status === FetchStatus.InProgress && (
                      <div>
                        <CommonSpinner message="Getting Latest Orders"></CommonSpinner>
                      </div>
                    )}
                    {grid.status === FetchStatus.Failed && (
                      <div className="bg-white p-3 text-danger">
                        <i className="fa fa-warning"></i> Failed getting latest
                        orders.
                      </div>
                    )}
                    {grid.status === FetchStatus.Complete && (
                      <table className="table bg-white">
                        <thead>
                          <tr>
                            {isSmallScreen && (
                              <>
                                <th></th>
                              </>
                            )}
                            {!isSmallScreen && (
                              <>
                                <th>ORDER ID</th>
                                <th>MWF ORDER ID</th>
                                <th>PLACED ON</th>
                                <th className="text-right">ORDER AMOUNT</th>
                                <th>STATUS</th>
                                <th className="text-center">ACTIONS</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {grid.data?.salesOrderGridItemDtos &&
                            grid.data.salesOrderGridItemDtos.map((item, i) => (
                              <tr key={`${i}-${item.webOrderNumber}`}>
                                {isSmallScreen && (
                                  <td>
                                    <table className="table-label-desc">
                                      <tbody>
                                        <TableRow label="Order ID:">
                                          <div className="flex gap-10">
                                            <div className="flex-1">
                                              {item.webOrderNumber}
                                            </div>
                                            <Actions
                                              i={i}
                                              item={item}
                                            ></Actions>
                                          </div>
                                        </TableRow>
                                        <TableRow label="MWF Order ID:">
                                          {item.tranId}
                                        </TableRow>
                                        <TableRow label="Placed On:">
                                          {item.tranDate &&
                                            commonService.toDateString(
                                              item.tranDate,
                                              "full",
                                              !item.nsSqlId
                                            )}
                                        </TableRow>
                                        <TableRow label="Delivery Date:">
                                          {item.deliveryDate &&
                                            commonService.toDateString(
                                              item.deliveryDate,
                                              "date"
                                            )}
                                        </TableRow>
                                        <TableRow label="Order Amount:">
                                          {commonService.toMoney(
                                            item.tranAmount
                                          )}
                                        </TableRow>
                                        <TableRow label="Placed By:">
                                          {item.orderPlacedBy && (
                                            <a
                                              href={`mailto:${item.orderPlacedBy}`}
                                            >
                                              {item.orderPlacedBy}
                                            </a>
                                          )}
                                        </TableRow>
                                        <TableRow label="Status:">
                                          <PaymentStatusBadge
                                            {...item}
                                          ></PaymentStatusBadge>
                                        </TableRow>
                                      </tbody>
                                    </table>
                                  </td>
                                )}
                                {!isSmallScreen && (
                                  <>
                                    <td>{item.webOrderNumber}</td>
                                    <td>{item.tranId}</td>
                                    <td
                                      title={commonService.toDateString(
                                        item.tranDate,
                                        "full",
                                        !item.nsSqlId
                                      )}
                                    >
                                      {commonService.toDateString(
                                        item.tranDate,
                                        "date",
                                        !item.nsSqlId
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {commonService.toMoney(item.tranAmount)}
                                    </td>
                                    <td>
                                      <PaymentStatusBadge
                                        {...item}
                                      ></PaymentStatusBadge>
                                    </td>
                                    <td className="text-center">
                                      <div>
                                        <Actions i={i} item={item}></Actions>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

const FeaturedSectionComponent = ({
  htmlAnnouncement,
  featuredItemsForDisplay,
}: {
  htmlAnnouncement: {
    data?: SysModels.IDashboardAnnouncementForDisplayDto;
    status: FetchStatus;
  };
  featuredItemsForDisplay: {
    data?: SysModels.IDashboardAnnouncementForDisplayDto;
    status: FetchStatus;
  };
}) => {
  return (
    <>
      {(htmlAnnouncement.status === FetchStatus.InProgress ||
        featuredItemsForDisplay.status === FetchStatus.InProgress) && (
        <div className="bg-white p-1 mb-4">
          <CommonSpinner></CommonSpinner>
        </div>
      )}

      {htmlAnnouncement.data?.content && (
        <>
          {htmlAnnouncement.data && (
            <div className={`dashboard-feature p-0 mb-4`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlAnnouncement.data.content,
                }}
              ></div>
            </div>
          )}
          {htmlAnnouncement.data &&
            !!htmlAnnouncement.data.erpInventoryItemOutputDtos?.length && (
              <>
                <h4 className="header-border pb-3 mt-2">
                  {htmlAnnouncement.data?.announcementType ===
                  SysModels.DashboardAnnouncementTypeEnum.Recipe
                    ? "Featured Recipe Items"
                    : "Featured Items"}
                </h4>
                <div className="py-4 mb-3 dashboard-featured-items">
                  <div className="catalog-items catalog-items-sm">
                    {htmlAnnouncement.data?.erpInventoryItemOutputDtos?.map(
                      (item) => (
                        <CatalogItem key={item.sqlId} item={item}></CatalogItem>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
        </>
      )}

      {featuredItemsForDisplay.status === FetchStatus.Complete &&
        featuredItemsForDisplay.data &&
        !!featuredItemsForDisplay.data?.erpInventoryItemOutputDtos?.length && (
          <>
            <h4 className="header-border pb-3 mt-2">
              {featuredItemsForDisplay.data?.title}
            </h4>
            <div className="py-4 mb-3 dashboard-featured-items">
              <div className="catalog-items catalog-items-sm">
                {featuredItemsForDisplay.data?.erpInventoryItemOutputDtos?.map(
                  (item) => (
                    <CatalogItem key={item.sqlId} item={item}></CatalogItem>
                  )
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default CustomerDashboard;
