import React, { useEffect, useState } from "react";
import FormModal from "../components/common/FormModal";
import CustomerDetail from "./customer/CustomerDetail";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import CommonSpinner from "../components/common/CommonSpinner";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";

function CustomerInfoDialog(props: {
  id: string;
  onClose: () => void;
  fetchFromId?: boolean;
}) {
  const agingSummary = useFetchHelper<SysModels.IAgingSummaryDto>(
    async () => SysServices.http.sales.agingSummary(props.id),
    "Aging Summary"
  );
  useEffect(() => {
    agingSummary.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [moveBehind, setMoveBehind] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);

  return (
    <FormModal
      title="Customer Detail"
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      submit={() => {
        setIsSwitching(true);
        SysServices.http.customer
          .get(props.id)
          .then((data) => {
            systemStore.setCurrentCustomer({
              name: data.customerName,
              number: data.erpCustomerNumber,
              id: data.sqlId,
              data: data,
            });
            setIsSwitching(false);
            props.onClose();
          })
          .catch((error) => {
            toastStore.showError("Failed Getting Customer", error);
          });
      }}
      submitButtonLabel={
        isSwitching ? "Switching..." : "Switch to this Customer"
      }
      size="xl"
      moveBehind={moveBehind}
      disableSubmit={
        agingSummary.status !== FetchStatus.Complete || isSwitching
      }
    >
      {isSwitching && (
        <CommonSpinner
          overlay={true}
          message={`Switching to Customer...`}
        ></CommonSpinner>
      )}
      <div className="row">
        <div
          className={`col-sm-12 col-md-${
            agingSummary.status === FetchStatus.InProgress ||
            (agingSummary.data && agingSummary.data.totalOverDue > 0)
              ? 7
              : 12
          }`}
        >
          <CustomerDetail
            customerId={props.id}
            onLoadingFailed={() => {
              props.onClose();
            }}
            hasPopDialog={(show) => setMoveBehind(show)}
            fetchFromId={props.fetchFromId}
          ></CustomerDetail>
        </div>
        <div className="col-sm-12 col-md-5">
          {agingSummary.status === FetchStatus.InProgress && (
            <>
              <CommonSpinner></CommonSpinner>
            </>
          )}
          {agingSummary.status === FetchStatus.Complete &&
            agingSummary.data &&
            agingSummary.data.totalOverDue > 0 && (
              <div className="row hide-on-print">
                <div className="cols-sm-12">
                  <div className="bg-white mb-4">
                    <div className="p-3 bg-red txt-light">
                      <h5 className="m-0">
                        <em className="zmdi zmdi-flag me-2"></em>Overdue Balance
                      </h5>
                    </div>
                    <div className="p-3" style={{ minHeight: "100px" }}>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 text-center">
                          <div>DUE</div>
                          <strong>
                            {commonService.toMoney(agingSummary.data.total)}
                          </strong>
                        </div>
                        <div className="col-sm-12 col-md-6 text-center">
                          <div>OVERDUE</div>
                          <strong className="text-danger">
                            {commonService.toMoney(
                              agingSummary.data.totalOverDue
                            )}
                          </strong>
                        </div>
                        <div className="col-sm-12 pt-3">
                          Your have overdue invoice(s) with a total balance due
                          of{" "}
                          <span className="text-danger">
                            {commonService.toMoney(
                              agingSummary.data.totalOverDue
                            )}
                          </span>
                          . Please pay all outstanding invoices to avoid any
                          interruption of service and further collection
                          attempts.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cols-sm-12 col-md-6 col-lg-4"></div>
                <div className="cols-sm-12 col-md-6 col-lg-4"></div>
              </div>
            )}
        </div>
      </div>
    </FormModal>
  );
}

export default CustomerInfoDialog;
