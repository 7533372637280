import React from "react";
function SystemBanner({
  message,
  error,
  warning,
}: {
  message: string;
  error?: boolean;
  warning?: boolean;
}) {
  const MessageElement = () => {
    return <span dangerouslySetInnerHTML={{ __html: message }}></span>;
  };

  return (
    <div
      id="system-banner"
      className={`system-banner hide-on-print ${
        error ? "system-banner-error" : ""
      } ${warning ? "system-banner-warning" : ""}`}
    >
      <div>
        <i className="fa fa-bullhorn"></i>
      </div>
      <div>
        <MessageElement></MessageElement>
      </div>
    </div>
  );
}

export default SystemBanner;
