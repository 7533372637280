import React from "react";
import ConfirmDialog from "./common/ConfirmDialog";
import commonService from "../services/CommonService";

function NewVersion(props: { version: string }) {
  return (
    <div
      className="login-page new-version-found"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <ConfirmDialog
        show={true}
        title={`New Version: ${props.version}`}
        message="We have updated the site with new features. Please click the Reload button to load the new site."
        buttons={[
          {
            label: "Reload",
            value: "reload",
            variant: "primary",
          },
        ]}
        done={(rtn) => {
          commonService.clearCacheAndReload();
        }}
      ></ConfirmDialog>
    </div>
  );
}

export default NewVersion;
