import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore from "../../stores/SystemStore";
import CommonSpinner from "../common/CommonSpinner";

function CategoriesMenu(props: any) {
  // const [menuOpen, setMenuOpen] = useState<{ [key: string]: boolean }>({});
  // const toggleMenuOpen = (code: string, open: boolean) => {
  //   setMenuOpen((val) => {
  //     const newVal = {};
  //     (newVal as any)[code] = open;
  //     return {
  //       ...val,
  //       ...newVal,
  //     };
  //   });
  // };

  const categories = useFetchHelper(async () => {
    return SysServices.http.catalog.categories();
  }, "Categories");

  const featuredCategories = useFetchHelper(
    async () => SysServices.http.dashboardAnnouncement.leftHandSideCategories(),
    "Featured Categories"
  );

  useEffect(() => {
    featuredCategories.getData();
    categories.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categories.status === FetchStatus.Complete && categories.data) {
      systemStore.setLHSCategories(
        categories.data.map((c) => {
          return {
            id: c.displayId,
            description: c.displayDescription,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.status]);

  return (
    <ul className="navbar-nav">
      {(categories.status === FetchStatus.InProgress ||
        featuredCategories.status === FetchStatus.InProgress) && (
        <li>
          <CommonSpinner color="light"></CommonSpinner>
        </li>
      )}

      {featuredCategories.status === FetchStatus.Complete && (
        <>
          {featuredCategories.data?.map((cat) => (
            <li
              //${menuOpen[cat.id] ? "open" : ""}
              className={`navbar-nav`}
              key={cat.id}
            >
              <NavLink
                //exact
                className="nav-link"
                //activeClassName="active"
                to={`/catalog/featured/${cat.id}`}
                onClick={props.toggle}
              >
                <i className={`fa fa-star text-warning`}></i>
                <span>{cat.displayDescription}</span>
              </NavLink>
            </li>
          ))}
          {!!featuredCategories.data?.length && (
            <hr className="light-grey-hr mb-10" />
          )}
        </>
      )}

      {categories.status === FetchStatus.Complete &&
        categories.data?.map((cat) => (
          <li
            //${menuOpen[cat.id] ? "open" : ""}
            className={`navbar-nav`}
            key={cat.id}
          >
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to={`/catalog/${cat.displayId}`}
              onClick={props.toggle}
            >
              <i className={`fa fa-pagelines`}></i>
              <span>{cat.displayDescription}</span>
            </NavLink>
            {/* {!cat.subCategories?.length && (
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={`/catalog/${cat.id}`}
                onClick={props.toggle}
              >
                <i className={`fa fa-${cat.icon}`}></i>
                <span>{cat.name}</span>
              </NavLink>
            )}
            {cat.subCategories?.length && (
              <>
                <a
                  onClick={() => {
                    //props.toggle();
                    toggleMenuOpen(cat.id, !menuOpen[cat.id]);
                  }}
                  className="pointer"
                >
                  <i className={`fa fa-${cat.icon}`}></i>
                  <span>{cat.name}</span>
                  <i className={`fa fa-caret-down`}></i>
                </a>
                <ul className="sub-menu">
                  {cat.subCategories.map((sub) => (
                    <li key={sub.id}>
                      <NavLink
                        exact
                        className="nav-link"
                        activeClassName="active"
                        to={`/catalog/${sub.id}`}
                        onClick={props.toggle}
                      >
                        <span>{sub.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            )} */}
          </li>
        ))}
    </ul>
  );
}

export default CategoriesMenu;
