import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { loginRequest } from "../AuthConfig";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import systemStore from "../stores/SystemStore";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";

function Login(props: any) {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (!commonService.isEmployeeSite) {
      commonService.focusInput("login_username");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && commonService.isEmployeeSite) {
      setTimeout(() => {
        instance.loginRedirect(loginRequest);
      }, 100);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, instance]);

  if (commonService.isEmployeeSite) {
    return (
      <div
        className="login-page"
        style={{ backgroundImage: `url(img/bg-login.jpg)` }}
      >
        <div>
          <img alt="MWF Splash" src="img/midwestsplash.png" />
          <div>redirecting...</div>
        </div>
      </div>
    );
  }

  const allowLogin = () => {
    return (
      !commonService.isNullOrEmpty(login.email) &&
      !commonService.isNullOrEmpty(login.password)
    );
  };

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="MWF Splash" src="img/midwestsplash.png" />
        <form
          className="pt-4"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (allowLogin()) {
              setInProgress(true);
              SysServices.http.customerUser
                .login(login)
                .then((data: any) => {
                  localStorage.removeItem("mwf-selected-customer");
                  SysServices.http.fetcher.storeToken(data);
                  systemStore.setAuthDataForCustomerSite(data);
                  //setInprogress(false);
                  if (SysServices.http.fetcher.shouldAsk2fa(data.token)) {
                    navigate("/2fa");
                  }
                })
                .catch((err: any) => {
                  toastStore.showError("Login Failed", err);
                  setInProgress(false);
                });
            }
          }}
        >
          <input
            id="login_username"
            type="input"
            name="email"
            autoComplete="new-password"
            placeholder="Username"
            className="form-control mb-3"
            value={login.email}
            onChange={(e) => {
              setLogin((p) => {
                return {
                  ...p,
                  email: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="form-control mb-3"
            value={login.password}
            onChange={(e) => {
              setLogin((p) => {
                return {
                  ...p,
                  password: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          <div className="flex flex-center">
            <div>
              <span
                className="text-danger pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/forgot-password");
                }}
              >
                Forgot Password?
              </span>
            </div>
            <div className="flex-1 text-right">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={inProgress || !allowLogin()}
              >
                <span className="px-3">
                  {inProgress ? "Logging In..." : "Login"}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
