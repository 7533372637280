import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SysModels from "../../models";
import commonService from "../../services/CommonService";
import systemStore, { useAllowOrderGuide } from "../../stores/SystemStore";

export const useCatalogFavorite = (itemId: string) => {
  const [id, setItemId] = useState(itemId);
  const [isFavorite, setIsFavorite] = useState(false);
  const [savingFavorite, setSavingFavorite] = useState(false);
  const toggleFavorite = () => {
    if (savingFavorite) return;
    setSavingFavorite(true);
    localStorage.setItem(`mwf-fav-item:${itemId}`, isFavorite ? "no" : "yes");
    setIsFavorite(!isFavorite);
    setTimeout(() => {
      setSavingFavorite(false);
    }, 300);
  };

  useEffect(() => {
    setIsFavorite(localStorage.getItem(`mwf-fav-item:${itemId}`) === "yes");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    toggleFavorite,
    isFavorite,
    savingFavorite,
    setItemId,
    itemId: id,
  };
};

function CatalogItem({ item }: { item: SysModels.IErpInventoryItemOutputDto }) {
  const navigate = useNavigate();
  //const fav = useCatalogFavorite(item.itemId);
  const allowOrderGuide = useAllowOrderGuide();

  return (
    <div className="catalog-item">
      <div className="flex">
        {/* <Dropdown>
          <Dropdown.Toggle
            size="sm"
            variant="outline-light"
            className="txt-grey"
          >
            <i className="fa fa-bars"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Add to Saved Order</Dropdown.Item>
            <Dropdown.Item>Add to Order Guide</Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item>Additional Pack Sizes</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <div className="flex-1"></div>
        <div>
          {/* <button
            type="button"
            className="flex-1 btn btn-sm btn-outline-light txt-grey"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              fav.toggleFavorite();
            }}
          >
            {!fav.savingFavorite && (
              <i
                className={`fa ${
                  fav.isFavorite ? "fa-star text-warning" : "fa-star-o"
                }`}
              ></i>
            )}
            {fav.savingFavorite && (
              <i className="fa fa-spin fa-circle-o-notch"></i>
            )}
          </button> */}
        </div>
      </div>
      <div
        className="catalog-item-image my-2"
        style={{
          backgroundImage: `url(${item.erpImageUrl})`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          navigate(`/productdetail/${item.itemId}`);
        }}
      >
        {!item.erpImageUrl && (
          <div className="no-image">
            <i className="fa fa-image"></i>
          </div>
        )}
      </div>
      <div
        className="catalog-item-desc"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          navigate(`/productdetail/${item.itemId}`);
        }}
      >
        <h5 dangerouslySetInnerHTML={{ __html: item.description }}></h5>
        <div>
          <small>{item.uom}</small>
        </div>
        <div>
          <small>{item.itemId}</small>
        </div>
        <div>
          <strong>
            {commonService.toMoney(item.unitPrice)}/{item.uom}
          </strong>
        </div>
      </div>
      {allowOrderGuide && (
        <div>
          <button
            className="flex-1 btn btn-sm btn-icon-label"
            type="button"
            onClick={(e) => {
              systemStore.addItemToSavedCart({
                itemCode: item.itemId,
                name: item.description,
                unit: item.uom,
                price: 0, //TOOD: UNIT PRICE
                qty: 1,
              });
            }}
          >
            <i className="fa fa-shopping-cart"></i>
            <span>Add To Order</span>
            <em>Add To Order</em>
          </button>
        </div>
      )}
    </div>
  );
}

export default CatalogItem;
