import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import FormModal from "../../components/common/FormModal";
import SysModels from "../../models";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";

function FindNotInOGDialog(props: {
  onClose: (items?: SysModels.IErpInventoryItemsDto[]) => void;
}) {
  const [addedItems, setAddedItems] = useState<
    SysModels.IErpInventoryItemsDto[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const items = useFetchHelper(async () => {
    return SysServices.http.order.searchItemNotInOg({
      search: searchTerm,
      orderByEnum: SysModels.OrderByEnum.Ascending,
    });
  }, "Items not in OG");

  const searchItems = () => {
    if (addedItems.find((i) => i.quantity > 0)) {
      setResetSearch(true);
    } else {
      items.getData();
    }
  };
  const [resetSearch, setResetSearch] = useState(false);

  useEffect(() => {
    if (items.status === FetchStatus.Complete) {
      if (items.data && items.data?.totalRecords > 50) {
        toastStore.showToast(
          "Only the first 50 records will be displayed",
          "warning"
        );
      }
    }
    setTimeout(() => {
      document.getElementById("txtFindNotOG")?.focus();
    }, 200);
  }, [items.status, items.data]);

  return (
    <>
      {resetSearch && (
        <ConfirmDialog
          show={true}
          title="Reset search"
          message={`Your current selections will be lost if you search again.<br/>Are you sure you want to continue?`}
          done={(rtn) => {
            if (rtn === "yes") {
              setAddedItems([]);
              items.getData();
            }
            setResetSearch(false);
          }}
          buttons="yesno"
        ></ConfirmDialog>
      )}

      <FormModal
        title="Find item not in your Order Guide"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
        moveBehind={resetSearch}
      >
        <ModalBody>
          <div className="flex">
            <input
              id="txtFindNotOG"
              className="form-control"
              placeholder="Find Item"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !!(searchTerm || "").trim()) {
                  searchItems();
                }
              }}
              disabled={items.status === FetchStatus.InProgress}
            ></input>
            <Button
              type="button"
              variant="primary"
              size="sm"
              className="no-wrap"
              onClick={() => searchItems()}
              disabled={
                items.status === FetchStatus.InProgress ||
                !(searchTerm || "").trim()
              }
            >
              <i className="fa fa-search"></i> Find
            </Button>
          </div>
          <div className="alert-sm alert-primary mt-2 p-1 px-2">
            <small>Only items NOT in your order guide will be displayed.</small>
          </div>

          {items.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner message="Searching..."></CommonSpinner>
            </div>
          )}

          <div
            className={`mt-2 ${
              items.status === FetchStatus.InProgress ? "display-none" : ""
            }`}
            style={{
              overflow: "auto",
              maxHeight: "500px",
              padding: "0",
              border: "solid 1px #ddd",
            }}
          >
            <table className="table table-sm m-0">
              <thead>
                <tr>
                  <th style={{ paddingLeft: "15px" }}>Item</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th className="text-center" style={{ paddingRight: "15px" }}>
                    UOM
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.data?.erpInventoryItemsDtos?.map((item) => (
                  <tr key={item.itemId}>
                    <td style={{ paddingLeft: "15px" }}>{item.itemId}</td>
                    <td>{item.description}</td>
                    <td>
                      <input
                        type="number"
                        step="0"
                        min="0"
                        max="999999"
                        className="form-control"
                        style={{ width: "100px" }}
                        defaultValue={
                          addedItems.find((i) => i.itemId === item.itemId)
                            ?.quantity
                        }
                        onChange={(e) => {
                          const qty = Number(e.target.value) || 0;
                          setAddedItems((prev) => {
                            if (!prev.find((i) => i.itemId === item.itemId)) {
                              return [
                                ...prev,
                                {
                                  ...item,
                                  quantity: qty,
                                },
                              ];
                            }
                            return [
                              ...prev.map((i) => {
                                if (i.itemId !== item.itemId) {
                                  return i;
                                }
                                return {
                                  ...i,
                                  quantity: qty,
                                };
                              }),
                            ];
                          });
                        }}
                      ></input>
                    </td>
                    <td
                      className="text-center"
                      style={{ paddingRight: "15px" }}
                    >
                      {item.uom}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>

          <Button
            type="button"
            onClick={() => {
              props.onClose(addedItems.filter((i) => i.quantity > 0));
            }}
            variant="primary"
            size="sm"
            disabled={!addedItems?.filter((i) => i.quantity > 0)?.length}
          >
            Add Items
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default FindNotInOGDialog;
