import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";

import Layout from "./components/Layout";
import Login from "./pages/Login";
import LoggedOut from "./pages/LoggedOut";
import CustomerDashboard from "./pages/CustomerDashboard";
import BillingCenter from "./pages/BillingCenter";
import CustomerService from "./pages/CustomerService";
import CustomerDocumentation from "./pages/CustomerDocumentation";
import CustomerDocumentationDetail from "./pages/CustomerDocumentationDetail";

import OrderGuide from "./pages/OrderGuide";
import PrintGuide from "./pages/PrintGuide";
import MyOrders from "./pages/MyOrders";
import Survey from "./pages/Survey";
import SurveyUnsubscribe from "./pages/SurveyUnsubscribe";

// import {
//   AuthenticatedTemplate,
//   MsalProvider,
//   UnauthenticatedTemplate,
// } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
// import { b2cPolicies, protectedResources } from "./AuthConfig";
// import { EventType } from "@azure/msal-browser";
import Toast from "./components/toast/Toast";
import systemStore, { useSiteDown } from "./stores/SystemStore";
import ProductCatalog from "./pages/ProductCatalog";
import ProductDetail from "./pages/ProductDetail";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import PublishedReleaseNotes from "./pages/PublishedReleaseNotes";
import PublishedReleaseNotesDetail from "./pages/PublishedReleaseNotesDetail";
import SysServices from "./services";
import dayjs from "dayjs";
import CommonSpinner from "./components/common/CommonSpinner";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { reaction } from "mobx";
import TwoFactorAuth from "./pages/TwoFactorAuth";
import PickCustomer from "./pages/PickCustomer";
import GlobalSearchResults from "./pages/GlobalSearchResults";
import GlobalSearchItemViewer from "./pages/GlobalSearchItemViewer";
import SiteDownPage from "./pages/SiteDownPage";
import { FetchStatus, useFetchHelper } from "./services/FetchHelper";
import SysModels from "./models";
import packageInfo from "../package.json";
import NewVersion from "./components/NewVersion";
ChartJS.register(ArcElement, Tooltip, Legend, Title);

function App({ instance }: { instance: IPublicClientApplication }) {
  useEffect(() => {
    window.addEventListener("resize", () => {
      systemStore.updateWindowSize();
    });
    systemStore.updateWindowSize();
  }, []);

  ////////OLD LOGIN PROCESS
  // /**
  //  * Using the event API, you can register an event callback that will do something when an event is emitted.
  //  * When registering an event callback in a react component you will need to make sure you do 2 things.
  //  * 1) The callback is registered only once
  //  * 2) The callback is unregistered before the component unmounts.
  //  * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
  //  */
  // useEffect(() => {
  //   const callbackId = instance.addEventCallback((event: any) => {
  //     if (event.eventType === EventType.LOGIN_FAILURE) {
  //       if (
  //         event.error &&
  //         event.error.errorMessage.indexOf("AADB2C90118") > -1
  //       ) {
  //         instance.loginRedirect(b2cPolicies.authorities.forgotPassword as any);
  //       }
  //     }

  //     if (
  //       event.eventType === EventType.LOGIN_SUCCESS ||
  //       event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
  //     ) {
  //       //event.payload.account.idTokenClaims.extension_website
  //       if (event?.payload) {
  //         /**
  //          * We need to reject id tokens that were not issued with the default sign-in policy.
  //          * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
  //          * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
  //          */
  //         if (
  //           protectedResources.isResetPasswordPolicy(
  //             event.payload.idTokenClaims
  //           )
  //         ) {
  //           window.alert(
  //             "Password has been reset successfully. \nPlease sign-in with your new password."
  //           );
  //           return instance.logoutRedirect({
  //             postLogoutRedirectUri: "/logout",
  //             authority: b2cPolicies.authorities.signUpSignIn.authority,
  //           });
  //         } else if (
  //           event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile
  //         ) {
  //           window.alert(
  //             "Profile has been edited successfully. \nPlease sign-in again."
  //           );
  //           return instance.logoutRedirect({
  //             postLogoutRedirectUri: "/logout",
  //             authority: b2cPolicies.authorities.signUpSignIn.authority,
  //           });
  //         }
  //       }
  //     }
  //   });

  //   return () => {
  //     if (callbackId) {
  //       instance.removeEventCallback(callbackId);
  //     }
  //   };
  // }, [instance]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    return () => {
      disposer();
    };
  }, []);

  const toggleLogin = () => {
    const login = SysServices.http.fetcher.getToken();
    if (login && login.token && login.refreshTokenExpiration) {
      systemStore.setAuthDataForCustomerSite(login);
      if (dayjs().isBefore(login.refreshTokenExpiration)) {
        setIsLoggedIn(true);

        const jwt = SysServices.common.parseJwt(login.token);
        //console.log(jwt);
        if (jwt && jwt.exp) {
          console.log(
            dayjs(jwt.exp * 1000)
              //.add(-29, "minute") //Quick Test
              .format("MMM DD, YYYY hh:mm:ss A")
          );
        }
      } else {
        SysServices.http.fetcher.clearToken();
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  const [ready, setReady] = useState(false);
  const siteDown = useSiteDown();
  const [tmoSystemMessage, setTmoSystemMessage] = useState(0);
  const getSiteDown = async () => {
    await SysServices.http.system
      .getSiteDown()
      .then((data) => {
        systemStore.setSiteDown(data);
        setReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [version, setVersion] = useState<SysModels.ISiteVersionOutputDto>();
  const getSiteVersion = async () => {
    if (showVersionDialog) {
      //do nothing...
      return;
    }
    await SysServices.http.system
      .getSiteVersion()
      .then((data) => {
        //console.log(data);
        setVersion(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showVersionDialog = useMemo(() => {
    if (version) {
      if (version.versionNumber === packageInfo.version) {
        return false;
      }
      return version.forceReload || false;
    }
    return undefined;
  }, [version]);

  useEffect(() => {
    let tmi: any;
    if (ready) {
      getSiteVersion();
      tmi = setInterval(() => {
        getSiteVersion();
      }, 15000);
    }
    return () => {
      clearInterval(tmi);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, siteDown]);

  useEffect(() => {
    toggleLogin();
    window.addEventListener(
      "token_changed",
      (event) => {
        //console.log(event);
        toggleLogin();
      },
      false
    );

    const tmi = setInterval(() => {
      toggleLogin();
    }, 5000);

    const disposer = reaction(
      () => systemStore.tmoSystemMessage,
      (val, prevVal, r) => {
        setTmoSystemMessage(val);
      }
    );

    return () => {
      clearInterval(tmi);
      disposer();
      //clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSiteDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmoSystemMessage]);

  const customerInfo = useFetchHelper(
    SysServices.http.customer.get,
    "Customer"
  );

  useEffect(() => {
    if (ready && isLoggedIn) {
      if (
        customerInfo.status !== FetchStatus.InProgress &&
        loginStatus.loggedIn &&
        loginStatus.canAccessWebsite &&
        !loginStatus.mustDoSomething
      ) {
        !customerInfo.data && customerInfo.getData();
      } else {
        customerInfo.setData(undefined);
      }
    } else {
      customerInfo.setData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus, ready, isLoggedIn]);

  useEffect(() => {
    systemStore.setCustomerInfo(customerInfo.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.data]);

  return (
    <>
      {showVersionDialog && (
        <NewVersion version={version?.versionNumber || ""}></NewVersion>
      )}
      <Toast />
      <BrowserRouter>
        <Routes>
          {ready && showVersionDialog === false ? (
            <>
              {siteDown?.isVisible ? (
                <>
                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" />}
                  ></Route>
                  <Route path="/sitedown" element={<SiteDownPage />}></Route>
                </>
              ) : (
                <>
                  <Route path="/sitedown" element={<Navigate to="/" />}></Route>
                  <Route path="/survey/:token" element={<Survey />}></Route>
                  <Route
                    path="/surveyunsubscribe/:token"
                    element={<SurveyUnsubscribe />}
                  ></Route>

                  {!isLoggedIn && (
                    <>
                      <Route path="/logout" element={<LoggedOut />}></Route>
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      ></Route>
                      <Route
                        path="/reset-password/:token"
                        element={<ResetPassword />}
                      ></Route>
                      <Route path="*" element={<Login />}></Route>
                    </>
                  )}
                  {ready && isLoggedIn && (
                    <Route
                      path="*"
                      element={
                        <>
                          {loginStatus.mustDoSomething ? (
                            <Layout>
                              <Routes>
                                <Route
                                  path="*"
                                  element={
                                    <>
                                      {loginStatus.mustDoSomething
                                        .need2faAuthentication && (
                                        <TwoFactorAuth
                                          must={true}
                                        ></TwoFactorAuth>
                                      )}
                                      {loginStatus.mustDoSomething
                                        .changePassword && (
                                        <ChangePassword
                                          must={true}
                                        ></ChangePassword>
                                      )}
                                      {loginStatus.mustDoSomething
                                        .shouldPickCustomer && (
                                        <PickCustomer></PickCustomer>
                                      )}
                                    </>
                                  }
                                ></Route>
                              </Routes>
                            </Layout>
                          ) : (
                            <Layout>
                              <GlobalSearchItemViewer></GlobalSearchItemViewer>
                              <Routes>
                                <Route
                                  path="/"
                                  element={<Navigate to="/dashboard" />}
                                ></Route>
                                <Route
                                  path="/dashboard"
                                  element={<CustomerDashboard />}
                                ></Route>
                                <Route
                                  path="/2fa"
                                  element={<TwoFactorAuth />}
                                ></Route>
                                <Route
                                  path="/change-password"
                                  element={<ChangePassword />}
                                ></Route>
                                <Route
                                  path="/billing-center"
                                  element={<BillingCenter />}
                                ></Route>
                                <Route
                                  path="/customer-service"
                                  element={<CustomerService />}
                                ></Route>
                                <Route
                                  path="/how-to"
                                  element={<CustomerDocumentation />}
                                ></Route>
                                <Route
                                  path="/how-to/:id"
                                  element={<CustomerDocumentationDetail />}
                                ></Route>
                                <Route
                                  path="/releasenotes"
                                  element={<PublishedReleaseNotes />}
                                ></Route>
                                <Route
                                  path="/releasenotes/:id"
                                  element={<PublishedReleaseNotesDetail />}
                                ></Route>
                                <Route
                                  path="/order-guide"
                                  element={<OrderGuide />}
                                ></Route>
                                <Route
                                  path="/print-guide"
                                  element={<PrintGuide />}
                                ></Route>
                                <Route
                                  path="/my-orders"
                                  element={<MyOrders />}
                                ></Route>
                                <Route
                                  path="/catalog"
                                  element={<ProductCatalog />}
                                ></Route>
                                <Route
                                  path="/catalog/:category"
                                  element={<ProductCatalog />}
                                ></Route>
                                <Route
                                  path="/catalog/:featured/:category"
                                  element={<ProductCatalog />}
                                ></Route>
                                <Route
                                  path="/productdetail/:id"
                                  element={<ProductDetail />}
                                ></Route>
                                <Route
                                  path="/Search/:query?/:types?"
                                  element={<GlobalSearchResults />}
                                ></Route>
                                <Route element={<div>Not Found</div>}></Route>
                              </Routes>
                            </Layout>
                          )}
                        </>
                      }
                    ></Route>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Route
                path="*"
                element={
                  <div className="p-4">
                    <CommonSpinner message="Loading..."></CommonSpinner>
                  </div>
                }
              ></Route>
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

// const RouteWrapper = ({ children }: any) => {
//   //This prevents rendering other routes/components
//   const match = true; // TODO: useRouteMatch(["/survey/:token", "/surveyunsubscribe/:token"]);
//   if (match) {
//     return <></>;
//   } else {
//     return <>{children}</>;
//   }
// };

export default App;
