import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import CustomerInfoDialog from "../CustomerInfoDialog";
import OrderGuideUpdateHistoryDialog from "./OrderGuideUpdateHistoryDialog";
import ViewMasterAccountChildrenDialog from "./ViewMasterAccountChildrenDialog";

function CustomerDetail(props: {
  customerId?: any;
  onLoadingFailed?: () => void;
  hasPopDialog?: (hasDialog: boolean) => void;
  fetchFromId?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] =
    useState<SysModels.IErpCustomerEmployeeSiteOutputDto>();
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );

    if (
      commonService.isEmployeeSite &&
      systemStore.currentCustomer?.data &&
      !props.fetchFromId
    ) {
      setCustomer(systemStore.currentCustomer.data);
    } else {
      setLoading(true);
      SysServices.http.customer
        .get(props.customerId)
        .then((data) => {
          setLoading(false);
          setCustomer(data);
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Customer", error);
          setLoading(false);
          props.onLoadingFailed && props.onLoadingFailed();
        });
    }

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updating, setUpdating] = useState(false);
  const [askUpdate, setAskUpdate] = useState(false);
  const doUpdate = async () => {
    if (customer?.erpCustomerNumber) {
      setUpdating(true);
      await SysServices.http.customer
        .scheduleCustomerUpdate({
          customerNumber: customer.erpCustomerNumber,
        })
        .then((data) => {
          toastStore.showToast("Customer update was scheduled", "success");
          setUpdating(false);
        })
        .catch((error) => {
          toastStore.showError("Update Failed", error);
          setUpdating(false);
        });
    }
  };

  const [relaoding, setRelaoding] = useState(false);
  const [askReload, setAskReload] = useState(false);
  const doReload = async () => {
    if (customer?.erpCustomerNumber) {
      setRelaoding(true);
      await SysServices.http.customer
        .scheduleOrderGuideReload({
          customerNumber: customer.erpCustomerNumber,
        })
        .then((data) => {
          toastStore.showToast("Customer OG Reload was scheduled", "success");
          setRelaoding(false);
        })
        .catch((error) => {
          toastStore.showError("OG Reload Failed", error);
          setRelaoding(false);
        });
    }
  };

  const [turnOff, setTurnOff] = useState(false);
  const [turningOff, setTurningOff] = useState(false);
  const [emailType, setEmailType] = useState(SysModels.EmailTypeEnum.Main);

  const doTurnOff2fa = async () => {
    if (customer?.erpCustomerNumber) {
      setTurningOff(true);
      await SysServices.http.customer
        .turnOffCustomersTwoFactorAuthentication({
          customerNumber: customer.erpCustomerNumber,
          emailType: emailType,
        })
        .then(() => {
          toastStore.showToast(
            "Two-Factor Authenticator has been Turned Off",
            "success"
          );
          setTurningOff(false);
        })
        .catch((error) => {
          toastStore.showError("Failed Turning Off 2FA", error);
          setTurningOff(false);
        });
    }
  };

  const getEmailTypeName = () => {
    if (emailType === SysModels.EmailTypeEnum.Main) {
      return "Main Email";
    }
    if (emailType === SysModels.EmailTypeEnum.Alternate) {
      return "Alternate Email";
    }
    return "Both Emails";
  };

  const [showCustomerInfoById, setShowCustomerInfoById] = useState<string>();
  const [showHistory, setShowHistory] = useState(false);
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (props.hasPopDialog) {
      props.hasPopDialog(askReload || askUpdate || turnOff || showHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askReload, askUpdate, turnOff, showHistory]);

  return (
    <div>
      <ConfirmDialog
        show={askReload}
        buttons="yesno"
        title="Update OG Reload"
        message="Are you sure you want to reload the Order Guide for this customer?"
        done={(rtn) => {
          if (rtn === "yes") {
            doReload();
          }
          setAskReload(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={askUpdate}
        buttons="yesno"
        title="Update Customer"
        message="Are you sure you want to update this customer?"
        done={(rtn) => {
          if (rtn === "yes") {
            doUpdate();
          }
          setAskUpdate(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={turnOff}
        buttons="yesno"
        title="Turn Off Two-Factor Authentication"
        message={`Are you sure you want to turn off Two-Factor Authentication for this customer (${getEmailTypeName()})?`}
        done={(rtn) => {
          if (rtn === "yes") {
            doTurnOff2fa();
          }
          setTurnOff(false);
        }}
      ></ConfirmDialog>

      {!!showCustomerInfoById && (
        <CustomerInfoDialog
          id={showCustomerInfoById}
          onClose={() => {
            setShowCustomerInfoById(undefined);
          }}
          fetchFromId={true}
        ></CustomerInfoDialog>
      )}

      {showHistory && customer?.erpCustomerNumber && (
        <OrderGuideUpdateHistoryDialog
          customerId={customer.erpCustomerNumber}
          close={() => {
            setShowHistory(false);
          }}
        ></OrderGuideUpdateHistoryDialog>
      )}
      {showChildren && customer?.hasOrderGuideChildren && (
        <ViewMasterAccountChildrenDialog
          customerId={customer.erpCustomerNumber}
          close={() => setShowChildren(false)}
        ></ViewMasterAccountChildrenDialog>
      )}

      {loading && <CommonSpinner></CommonSpinner>}

      {!loading && customer && (
        <div>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>
                  <strong>Id:</strong>
                </td>
                <td>{customer.erpCustomerNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Name:</strong>
                </td>
                <td>{customer.customerName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Phone:</strong>
                </td>
                <td>{customer.phone}</td>
              </tr>
              <tr>
                <td>
                  <strong>Email:</strong>
                </td>
                <td>
                  <a href={`mailto:${customer.email}`}>{customer.email}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Route Id:</strong>
                </td>
                <td>{customer.routeId}</td>
              </tr>
              <tr>
                <td className="pb-4">
                  <strong>Route Info:</strong>
                </td>
                <td className="pb-4">{customer.routeInfo}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>
                  <strong>Alt Email:</strong>
                </td>
                <td>
                  <a href={`mailto:${customer.alternateEmail}`}>
                    {customer.alternateEmail}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Last Update:</strong>
                </td>
                <td>
                  {customer.lastUpdatedFromErp &&
                    commonService.toLocalDate(
                      customer.lastUpdatedFromErp,
                      "full"
                    )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>OG Allowed:</strong>
                </td>
                <td>{customer.orderGuideAllowedYn ? "YES" : "NO"}</td>
              </tr>
              <tr>
                <td className="pb-4">
                  <strong>Web PWD:</strong>
                </td>
                <td className="pb-4">{customer.webPassword}</td>
              </tr>
            </tbody>

            {commonService.isEmployeeSite && (
              <tbody>
                <tr>
                  <td>
                    <strong>Buying Group:</strong>
                  </td>
                  <td>{customer.buyingGroup}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Outgoing Invoice Integration:</strong>
                  </td>
                  <td>{customer.outgoingInvoiceIntegration}</td>
                </tr>
                <tr>
                  <td className="pb-4">
                    <strong>Outgoing Sales Order Integration:</strong>
                  </td>
                  <td className="pb-4">
                    {customer.outgoingSalesOrderIntegration}
                  </td>
                </tr>
              </tbody>
            )}

            <tbody>
              <tr>
                <td className="pb-4">
                  <strong>Comment:</strong>
                </td>
                <td className="pb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: customer.erpComments || "",
                    }}
                  ></div>
                </td>
              </tr>
            </tbody>

            {customer.hasOrderGuideChildren && !props.fetchFromId && (
              <tbody>
                <tr>
                  <td>
                    <strong>Related Accounts:</strong>
                  </td>
                  <td>
                    <a
                      href="/"
                      className="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowChildren(true);
                      }}
                    >
                      View Master Account's Children
                    </a>
                  </td>
                </tr>
              </tbody>
            )}

            <tbody>
              <tr>
                <td>
                  <strong>OG Updated:</strong>
                </td>
                <td>
                  {commonService.isEmployeeSite ? (
                    <>
                      <a
                        href="/"
                        className="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowHistory(true);
                        }}
                      >
                        {customer.lastOrderGuideUpdate &&
                          commonService.toLocalDate(
                            customer.lastOrderGuideUpdate,
                            "full"
                          )}
                      </a>
                    </>
                  ) : (
                    <>
                      {customer.lastOrderGuideUpdate &&
                        commonService.toLocalDate(
                          customer.lastOrderGuideUpdate,
                          "full"
                        )}
                    </>
                  )}
                </td>
              </tr>

              {customer.masterAccount && (
                <tr>
                  <td>
                    <strong>Master Account:</strong>
                  </td>
                  <td>
                    {mwfRole === SysModels.UserRolesEnum.Sales ||
                    systemStore.salesAccountViewing ? (
                      <>{customer.masterAccount}</>
                    ) : (
                      <>
                        <a
                          href="/"
                          className="pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowCustomerInfoById(
                              customer.masterAccountCustomerNumber
                            );
                          }}
                        >
                          {customer.masterAccount}
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
            <tbody>
              {(mwfRole & SysModels.UserRolesEnum.OeManager) > 0 && (
                <tr>
                  <td>
                    <strong>Update Customer:</strong>
                  </td>
                  <td className="pt-2 pb-4">
                    {customer?.orderGuideAllowedYn && (
                      <Button
                        type="button"
                        variant="primary"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setAskReload(true);
                        }}
                        disabled={relaoding}
                      >
                        <i className="fa fa-cloud-download me-2"></i>
                        {relaoding ? "OG Reloading..." : "OG Reload"}
                      </Button>
                    )}
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        setAskUpdate(true);
                      }}
                      disabled={updating}
                    >
                      <i className="fa fa-upload me-2"></i>
                      {updating ? "Updating..." : "Update"}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>

            {(mwfRole & SysModels.UserRolesEnum.Admin) > 0 &&
              customer?.orderGuideAllowedYn && (
                <tbody>
                  <tr>
                    <td>
                      <strong>Two-Factor Authentication:</strong>
                    </td>
                    <td>
                      <Dropdown className="2fa-dropdown">
                        <Dropdown.Toggle
                          id="2fa-cart"
                          variant="danger"
                          size="sm"
                          disabled={turningOff}
                        >
                          <i className="fa fa-shield me-2"></i>
                          <span className="me-2">
                            {turningOff ? "Turning Off 2FA..." : "Turn Off 2FA"}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="right">
                          <Dropdown.Item
                            onClick={() => {
                              setEmailType(SysModels.EmailTypeEnum.Main);
                              setTurnOff(true);
                            }}
                          >
                            Main Email
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setEmailType(SysModels.EmailTypeEnum.Alternate);
                              setTurnOff(true);
                            }}
                          >
                            Alternate Email
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setEmailType(SysModels.EmailTypeEnum.Both);
                              setTurnOff(true);
                            }}
                          >
                            Both Emails
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setTurnOff(true);
                        }}
                        disabled={turningOff}
                      >
                        <i className="fa fa-shield me-2"></i>
                        {turningOff ? "Turning Off 2FA..." : "Turn Off 2FA"}
                      </Button> */}
                    </td>
                  </tr>
                </tbody>
              )}
          </table>

          <div>
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <strong>Bill To:</strong>
                  </td>
                  <td>
                    <strong>Ship To:</strong>
                  </td>
                </tr>
                <tr>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: customer.billingAddressText,
                    }}
                  ></td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: customer.shippingAddressText,
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerDetail;
