import { AccountInfo, AuthenticationResult } from "@azure/msal-common";
import dayjs from "dayjs";
import { makeAutoObservable, reaction } from "mobx";
import { useEffect, useState } from "react";
import SysModels from "../models";
import { ICatalogItem } from "./SampleData";
import toastStore from "./ToastStore";
import commonService from "../services/CommonService";

//const MWF = "mwf";

interface SystemFlags {
  dashboardBannerDismissed?: boolean;
}

interface ILoginStatus {
  loggedIn: boolean;
  canAccessWebsite: boolean;
  is2faRequired: boolean;
  mustDoSomething: {
    changePassword: boolean;
    need2faAuthentication: boolean;
    shouldPickCustomer: boolean;
  } | null;
}

class SystemStore {
  private _loginStatus: ILoginStatus = {
    loggedIn: false,
    canAccessWebsite: false,
    is2faRequired: false,
    mustDoSomething: {
      changePassword: false,
      need2faAuthentication: false,
      shouldPickCustomer: false,
    },
  };
  private _accountInfo: AccountInfo | null = null;
  private _authenticationData: AuthenticationResult | null = null;
  private _flags: SystemFlags = {
    dashboardBannerDismissed: false,
  };
  private _tmoSystemMessage = 0;
  private _tmo10sec = 0;
  private _systemMessageVisible = false;
  private _mainContainerScrollTop = 0;

  private _extension_MWFRole = 0;
  private _activeRole: SysModels.UserRolesEnum | 0 = 0;
  private _currentCustomer?: {
    name: string;
    number: string;
    id: number;
    data: SysModels.IErpCustomerEmployeeSiteOutputDto;
  };
  private _lastMsalError: any = null;
  private _lhsCategories = [] as { id: string; description: string }[];

  private _orderGuideTemplate?: {
    id: any;
    netSuite: boolean;
  };
  private _windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
    isSmall: window.innerWidth < 768,
  };

  private _savedCartItems?: {
    items: ICatalogItem[];
  };

  private _cartUpdatedOutsideOG?: string;
  private _searchedItemToPreview?: SysModels.IGlobalSearchOutputDto;

  private _siteDown?: SysModels.ISiteDownOutputDto;
  private _customerInfo?: SysModels.IErpCustomerEmployeeSiteOutputDto;
  private _allowInvoicePayment = false; //FOR EMPLOYEE SITE ONLY
  private _sideMenuReady = false; //FOR EMPLOYEE SITE ONLY
  private _salesAccountViewing:
    | {
        email: string;
        role: "Sales Rep" | "Sales Manager";
      }
    | undefined = undefined; //FOR EMPLOYEE SITE ONLY

  get cartStorageKey() {
    if (commonService.isEmployeeSite) {
      if (this.currentCustomer) {
        return `mwf-saved-order-${this.currentCustomer.id}-${this._accountInfo?.localAccountId}`;
      } else {
        return "";
      }
    }
    return `mwf-saved-order-${this._accountInfo?.localAccountId}`;
  }

  get loginStatus() {
    return this._loginStatus;
  }

  get accountInfo() {
    return this._accountInfo;
  }

  get authenticationData() {
    return this._authenticationData;
  }

  get flags() {
    return this._flags;
  }

  get orderGuideTemplate() {
    return this._orderGuideTemplate;
  }

  get windowSize() {
    return this._windowSize;
  }

  get tmoSystemMessage() {
    return this._tmoSystemMessage;
  }

  get tmo10sec() {
    return this._tmo10sec;
  }

  get systemMessageVisible() {
    return this._systemMessageVisible;
  }

  get mainContainerScrollTop() {
    return this._mainContainerScrollTop;
  }

  get extension_MWFRole() {
    return this._extension_MWFRole;
  }

  get activeRole() {
    return this._activeRole;
  }

  get currentCustomer() {
    return this._currentCustomer;
  }

  get allowOrderGuide() {
    if (this.activeRole === SysModels.UserRolesEnum.SalesManager) {
      return false; //Sales Managers can only have one role and not allowed to make orders
    }
    if (
      this.salesAccountViewing &&
      this.salesAccountViewing.role === "Sales Manager"
    ) {
      return false; //Sales Managers can only have one role and not allowed to make orders
    }
    if (this.currentCustomer && this.currentCustomer.data) {
      return this.currentCustomer.data.orderGuideAllowedYn;
    }
    if (!commonService.isEmployeeSite) {
      return this._customerInfo?.orderGuideAllowedYn || false;
    }
    return false;
  }

  get lastMsalError() {
    return this._lastMsalError;
  }

  get savedCartItems() {
    return this._savedCartItems;
  }

  get lhsCategories() {
    return this._lhsCategories;
  }

  get cartUpdatedOutsideOG() {
    return this._cartUpdatedOutsideOG;
  }

  get searchedItemToPreview() {
    return this._searchedItemToPreview;
  }

  get siteDown() {
    return this._siteDown;
  }

  get customerInfo() {
    return this._customerInfo;
  }

  get allowInvoicePayment() {
    return this._allowInvoicePayment;
  }

  get sideMenuReady() {
    return this._sideMenuReady;
  }

  get salesAccountViewing() {
    return this._salesAccountViewing;
  }

  constructor() {
    makeAutoObservable(this);
    setInterval(() => {
      this.setTmoSystemMessage(this.tmoSystemMessage + 1);
    }, 60000);
    setInterval(() => {
      this.setTmo10sec(this.tmo10sec + 1);
    }, 10000);
  }

  setAccountInfo(accountInfo: AccountInfo | null) {
    this._accountInfo = accountInfo;
  }

  setAuthenticationData(data: AuthenticationResult) {
    this._authenticationData = data;
    this._accountInfo = data?.account || null;

    const websites: string = `${
      (data.idTokenClaims as any)?.extension_website || ""
    }`;

    this.setExtension_MWFRole((data.idTokenClaims as any)?.extension_MWFRole);

    const reactWebsite = `${process.env.REACT_APP_WEBSITE || ""}`
      .toLowerCase()
      .trim();

    this._loginStatus = {
      loggedIn: data && !!data.accessToken && !!data.authority,
      canAccessWebsite:
        reactWebsite !== "" &&
        websites.toLowerCase().split(" ").indexOf(reactWebsite) > -1,
      is2faRequired: false,
      mustDoSomething: null,
    };

    //this.toggleSavedCartSource();
  }

  setAuthDataForCustomerSite(data: SysModels.ITokenDto) {
    const json = commonService.parseJwt(data.token || "");
    const changePassword =
      (json?.must_change_password || "").toLowerCase().trim() === "true";

    const is2faRequired =
      (json?.requires_2fa || "").trim().toLowerCase() === "true";
    const need2faAuthentication =
      is2faRequired &&
      (json?.is_2fa_authenticated || "").trim().toLowerCase() === "false";

    const roles =
      typeof json?.role === "string" ? [json?.role] : [...(json?.role || [])];

    let shouldPickCustomer = roles.length > 1;
    if (shouldPickCustomer) {
      const selCustId = localStorage.getItem("mwf-selected-customer");
      if (roles.map((r) => r.split("~~~")[0]).includes(selCustId)) {
        shouldPickCustomer = false;
      }
    } else {
      localStorage.setItem(
        "mwf-selected-customer",
        (roles[0] || "").split("~~~")[0]
      );
    }
    //prioritize 2fa first and change password first
    if (changePassword || need2faAuthentication) {
      shouldPickCustomer = false;
    }

    let doSomething = false;
    if (changePassword || need2faAuthentication || shouldPickCustomer) {
      doSomething = true;
      this.setLHSCategories([]);
    }

    this._loginStatus = {
      loggedIn: true,
      canAccessWebsite: true,
      is2faRequired: is2faRequired,
      mustDoSomething: doSomething
        ? { changePassword, need2faAuthentication, shouldPickCustomer }
        : null,
    };
  }

  pickCustomer(data: SysModels.ITokenDto, customerId: string) {
    localStorage.setItem("mwf-selected-customer", customerId);
    this.setAuthDataForCustomerSite(data);
  }

  resetPickedCustomer(data: SysModels.ITokenDto) {
    localStorage.removeItem("mwf-selected-customer");
    this.setAuthDataForCustomerSite(data);
  }

  setFlags(flags: SystemFlags) {
    this._flags = { ...this._flags, ...flags };
  }

  setOrderGuideTemplate(id: any, netSuite: boolean) {
    this._orderGuideTemplate = {
      id,
      netSuite,
    };
  }

  updateWindowSize() {
    this._windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
      isSmall: window.innerWidth < 768,
    };
  }

  setTmoSystemMessage(tmo: any) {
    this._tmoSystemMessage = tmo > 100 ? 1 : tmo;
  }

  setTmo10sec(tmo: any) {
    this._tmo10sec = tmo > 100 ? 1 : tmo;
  }

  setSystemMessageVisible(val: boolean) {
    this._systemMessageVisible = val;
  }

  setMainContainerScrollTop(val: number) {
    this._mainContainerScrollTop = val;
  }

  setExtension_MWFRole(role?: any) {
    this._extension_MWFRole = role || 0;
  }

  setActiveRole(role: SysModels.UserRolesEnum) {
    this._activeRole = role;
  }

  setCurrentCustomer(customer?: {
    name: string;
    number: string;
    id: number;
    data: SysModels.IErpCustomerEmployeeSiteOutputDto;
  }) {
    this._currentCustomer = customer;
  }

  setLastMsalError(data: any) {
    this._lastMsalError = data;
  }

  setSearchedItemToPreview(item?: SysModels.IGlobalSearchOutputDto) {
    this._searchedItemToPreview = item || undefined;
  }

  toggleSavedCartSource(force = false) {
    // const savedData = localStorage.getItem(this.cartStorageKey);
    // if (force || (savedData && !this._savedCartItems)) {
    //   if (savedData) {
    //     this.setSavedCartItems(JSON.parse(savedData)?.items || []);
    //   } else {
    //     this.setSavedCartItems([]);
    //   }
    // }
  }

  addItemToSavedCart(selItem: ICatalogItem) {
    const matches = (this._savedCartItems?.items || []).filter(
      (x) => x.itemCode === selItem.itemCode
    );
    if (matches.length > 0) {
      this.setSavedCartItems(
        [...(this._savedCartItems?.items || [])].map((item) => {
          if (item.itemCode === matches[0].itemCode) {
            return {
              ...item,
              qty: (item.qty || 0) + (selItem.qty || 1),
            };
          }
          return item;
        })
      );
      toastStore.showToast(
        `${selItem.name} (${selItem.itemCode}) quantity has been updated in the cart.`,
        "success"
      );
    } else {
      this.setSavedCartItems([...(this._savedCartItems?.items || []), selItem]);
      toastStore.showToast(
        `${selItem.name} (${selItem.itemCode}) has been added to cart.`,
        "success"
      );
    }
  }

  setSavedCartItems(items: ICatalogItem[]) {
    this._savedCartItems = {
      items: [...items],
    };
    localStorage.setItem(
      this.cartStorageKey,
      JSON.stringify(this._savedCartItems)
    );
  }

  setLHSCategories(categories: { id: string; description: string }[]) {
    this._lhsCategories = [...categories];
  }

  setCartUpdatedOutsideOG() {
    setTimeout(() => {
      this._cartUpdatedOutsideOG = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
    }, 200);
  }

  setSiteDown(data?: SysModels.ISiteDownOutputDto) {
    this._siteDown = data || undefined;
  }

  setCustomerInfo(data?: SysModels.IErpCustomerEmployeeSiteOutputDto) {
    this._customerInfo = data || undefined;
  }

  setAllowInvoicePayment(val: boolean) {
    this._allowInvoicePayment = val;
  }

  setSideMenuReady(val: boolean) {
    this._sideMenuReady = val;
  }

  setSalesAccountViewing(
    data:
      | {
          email: string;
          role: "Sales Rep" | "Sales Manager";
        }
      | undefined
  ) {
    this._salesAccountViewing = data;
  }
}

const systemStore = new SystemStore();
export default systemStore;

export const useSavedCartItems = () => {
  const [savedCartItems, setSavedCartItems] = useState(
    systemStore.savedCartItems
  );
  // const [currentCustomer, setCurrentCustomer] = useState(
  //   systemStore.currentCustomer
  // );
  // useEffect(() => {
  //   //systemStore.toggleSavedCartSource(true);
  // }, [currentCustomer]);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.savedCartItems,
      (n, p, i) => {
        setSavedCartItems(n);
      }
    );
    // const disposer2 = reaction(
    //   () => systemStore.currentCustomer,
    //   (n, p, i) => {
    //     setCurrentCustomer(n);
    //   }
    // );
    return () => {
      disposer();
      //disposer2();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return savedCartItems;
};

export const useCurrentCustomer = () => {
  const [currentCustomer, setCurrentCustomer] = useState(
    systemStore.currentCustomer
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.currentCustomer,
      (n, p, i) => {
        setCurrentCustomer(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return currentCustomer;
};

export const useAllowOrderGuide = () => {
  const [allowOrderGuide, setAllowOrderGuide] = useState(
    systemStore.allowOrderGuide
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.allowOrderGuide,
      (n, p, i) => {
        setAllowOrderGuide(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return allowOrderGuide;
};

export const useActiveRole = () => {
  const [activeRole, setActiveRole] = useState(systemStore.activeRole);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.activeRole,
      (n, p, r) => {
        setActiveRole(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activeRole;
};

export const useLHSCategories = () => {
  const [lhsCategories, setLHSCategories] = useState(systemStore.lhsCategories);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.lhsCategories,
      (n, p, r) => {
        setLHSCategories(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lhsCategories;
};

export const useCartStorageKey = () => {
  const [cartStorageKey, setCartStorageKey] = useState(
    systemStore.cartStorageKey
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.cartStorageKey,
      (n, p, r) => {
        setCartStorageKey(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cartStorageKey;
};

export const useCartUpdatedOutsideOG = () => {
  const [cartUpdatedOutsideOG, setCartUpdatedOutsideOG] = useState(
    systemStore.cartUpdatedOutsideOG
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.cartUpdatedOutsideOG,
      (n, p, r) => {
        setCartUpdatedOutsideOG(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cartUpdatedOutsideOG;
};

export const useMwfRole = () => {
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mwfRole;
};

export const usePreviewSearchedItem = () => {
  const [searchedItemToPreview, setSearchedItemToPreview] = useState(
    systemStore.searchedItemToPreview
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.searchedItemToPreview,
      (n, p, r) => {
        setSearchedItemToPreview(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return searchedItemToPreview;
};

export const useSiteDown = () => {
  const [siteDown, setSiteDown] = useState(systemStore.siteDown);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.siteDown,
      (n, p, r) => {
        setSiteDown(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return siteDown;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(systemStore.windowSize);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, r) => {
        setWindowSize(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};

export const useSalesAccountViewing = () => {
  const [account, setAccount] = useState(systemStore.salesAccountViewing);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.salesAccountViewing,
      (n, p, r) => {
        setAccount(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return account;
};
