import React from "react";
import { observer } from "mobx-react-lite";
import toastStore from "../../stores/ToastStore";

import "./Toast.scss";

function Toast(props: any) {
  return (
    <div
      className="toastContainer"
      onMouseEnter={() => {
        toastStore.toggleHover(true);
      }}
      onMouseLeave={() => {
        toastStore.toggleHover(false);
      }}
    >
      {toastStore.items.map((item) => (
        <div
          key={item.id}
          className={`toast-${item.type} ${item.remove ? "remove-toast" : ""}`}
        >
          <small dangerouslySetInnerHTML={{ __html: item.message }}></small>
          <i
            className="fa fa-times"
            onClick={() => {
              toastStore.removeToast(item);
            }}
          ></i>
        </div>
      ))}
      {toastStore.items.filter((i) => !i.remove).length > 1 && (
        <div className="clearAll" onClick={() => toastStore.clearAll()}>
          <strong>Clear All</strong>
        </div>
      )}
    </div>
  );
}

export default observer(Toast);
