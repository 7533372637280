import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

class CatalogService {
  categories = async (customerNumber?: string) => {
    let url = `/Catalog/Categories`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.ICatalogCategoryOutputDto[]>(url);
  };

  listProducts = async (
    page: number,
    pageSize: number,
    data: SysModels.ICatalogProductSearchDto,
    customerNumber?: string
  ) => {
    let url = `/Catalog/Products`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.post<SysModels.ICatalogGridDto>(
      `${url}/${page}/${pageSize}`,
      data
    );
  };

  getItem = async (itemId: string, customerNumber?: string) => {
    let url = `/Catalog/Item`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.IErpInventoryItemCatalogDetailOutputDto>(
      `${url}/${itemId}`
    );
  };

  getItemEmployee = async (itemId: string) => {
    let url = `/Catalog/GetItemEmployee`;
    return fetcher.get<SysModels.IErpInventoryItemCatalogDetailOutputDto>(
      `${url}/${itemId}`
    );
  };

  cart = async (customerNumber?: string) => {
    let url = `/Catalog/Cart`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.ICartDto>(url);
  };

  cartSave = async (data: SysModels.ICartDto, customerNumber?: string) => {
    let url = `/Catalog/Cart/Save`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.post<any>(url, data);
  };

  cartDelete = async (customerNumber?: string) => {
    let url = `/Catalog/Cart/Delete`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.delete<any>(url, {});
  };
}

const catalog = new CatalogService();
export default catalog;
