import React from "react";

function TableRow(props: { label: string; children: any }) {
  return (
    <tr>
      <td style={{ width: "15%" }}>
        <small
          dangerouslySetInnerHTML={{
            __html: props.label,
          }}
        ></small>
      </td>
      <td className="break-word">{props.children}</td>
    </tr>
  );
}

export default TableRow;
