import { useMsal } from "@azure/msal-react";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import systemStore, {
  useAllowOrderGuide,
  useCartStorageKey,
  useCurrentCustomer,
  useSavedCartItems,
} from "../stores/SystemStore";
import packageInfo from "../../package.json";
import commonService from "../services/CommonService";
import { b2cPolicies, protectedResources } from "../AuthConfig";
import ConfirmDialog from "./common/ConfirmDialog";
import SysServices from "../services";
import CommonSpinner from "./common/CommonSpinner";
import { ICatalogItem } from "../stores/SampleData";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import SearchBar from "./SearchBar";

function NavBar(props: { toggle: () => void }) {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savedCartItems = useSavedCartItems();
  const cartStorageKey = useCartStorageKey();
  const currentCustomer = useCurrentCustomer();
  const allowOrderGuide = useAllowOrderGuide();

  const [itemToDelete, setItemToDelete] = useState<ICatalogItem>();
  const [showClearAll, setShowClearAll] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [canSwitch, setCanSwitch] = useState(false);

  const [lastSavedCart, setLastSavedCart] = useState<any[]>([]);

  useEffect(() => {
    setCanSwitch(SysServices.http.fetcher.getRoles().length > 1);
    if (!cartStorageKey || loginStatus.mustDoSomething) {
      return;
    }
    SysServices.http.catalog
      .cart()
      .then((data) => {
        const items = data.cartItemDtos.map((item) => {
          return {
            itemCode: item.itemId,
            name: item.description,
            qty: item.quantity || 1,
            price: 0,
            unit: "",
          };
        });

        setLastSavedCart(
          items
            .filter((item) => item.qty && item.qty > 0)
            .map((item) => {
              return {
                itemId: item.itemCode,
                description: item.name,
                quantity: item.qty || 1,
              } as SysModels.ICartItemDto;
            })
        );

        setTimeout(() => {
          systemStore.setSavedCartItems(items);
        }, 200);
        //console.log(data);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus.mustDoSomething, cartStorageKey]);

  const saveCart = async () => {
    if (
      !loginStatus.loggedIn ||
      loginStatus.mustDoSomething?.shouldPickCustomer
    ) {
      return;
    }
    if (savedCartItems && savedCartItems.items?.length > 0) {
      const newItems = savedCartItems.items
        .filter((item) => item.qty && item.qty > 0)
        .map((item) => {
          return {
            itemId: item.itemCode,
            description: item.name,
            quantity: item.qty || 1,
          } as SysModels.ICartItemDto;
        });

      if (JSON.stringify(lastSavedCart) === JSON.stringify(newItems)) {
        console.log("*** SAME CART ITEMS ****");
        return;
      }

      await SysServices.http.catalog
        .cartSave({
          cartItemDtos: newItems,
        })
        .then((data) => {
          setLastSavedCart(newItems);
          console.info("Cart Saved");
        })
        .catch((error) => {});
    }
  };

  const clearCart = async () => {
    setInProgress(true);
    await SysServices.http.catalog
      .cartDelete()
      .then((data) => {})
      .catch((error) => {})
      .finally(() => {
        systemStore.setSavedCartItems([]);
        systemStore.setCartUpdatedOutsideOG();
        setInProgress(false);
      });
  };

  useEffect(() => {
    if (!savedCartItems || savedCartItems.items.length === 0) {
      return;
    }

    const tmo = setTimeout(() => {
      saveCart();
    }, 1000);
    return () => {
      clearTimeout(tmo);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCartItems]);

  return (
    <>
      {inProgress && <CommonSpinner overlay={true}></CommonSpinner>}
      <ConfirmDialog
        show={showClearAll}
        title={`Confirm Clear Cart`}
        message={`Do you really want to clear cart items?`}
        buttons={"yesno"}
        done={(rtn) => {
          if (rtn === "yes") {
            clearCart();
          }
          setShowClearAll(false);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={!!itemToDelete}
        title={`Confirm Remove Item`}
        message={`Do you really want to remove '${itemToDelete?.name || ""} - ${
          itemToDelete?.itemCode
        }' from cart?`}
        buttons={"yesno"}
        done={(rtn) => {
          if (rtn === "yes") {
            systemStore.setSavedCartItems([
              ...(savedCartItems?.items || []).filter(
                (i) => i.itemCode !== itemToDelete?.itemCode
              ),
            ]);
            systemStore.setCartUpdatedOutsideOG();
          }
          setItemToDelete(undefined);
        }}
      ></ConfirmDialog>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="sideNavToggle btn btn-sm me-1"
            onClick={props.toggle}
          >
            <i className="fa fa-bars"></i>
          </button>
          <span className="navbar-brand">
            <NavLink
              //exact
              className="navbar-brand"
              //activeClassName="active"
              to="/"
            >
              <img src="/mw1.png" alt="MWF" style={{ width: "214px" }}></img>
            </NavLink>
          </span>
          <div className="collapse navbar-collapse" id="navbarNav">
            <button
              className="sideNavToggle btn btn-sm me-1"
              onClick={props.toggle}
            >
              <i className="fa fa-bars"></i>
            </button>

            <div className="navbar-center flex flex-center py-2">
              {/* <SearchBar></SearchBar> */}
            </div>
            <div className="navbar-end flex gap-10">
              {allowOrderGuide &&
                !loginStatus.mustDoSomething?.shouldPickCustomer &&
                (!!currentCustomer || !commonService.isEmployeeSite) && (
                  <Dropdown className="cart-dropdown">
                    <Dropdown.Toggle id="dropdown-cart">
                      <i className="fa fa-shopping-cart"></i>
                      {savedCartItems && savedCartItems?.items?.length > 0 && (
                        <span className="cart-counter">
                          {savedCartItems?.items?.length || 0}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right">
                      {savedCartItems && savedCartItems?.items?.length > 0 && (
                        <>
                          <div>
                            {savedCartItems?.items?.map((item) => (
                              <div className="flex" key={item.itemCode}>
                                <div>
                                  {item.name} <br />{" "}
                                  <small className="txt-grey">
                                    {item.itemCode}
                                  </small>
                                </div>
                                <div className="no-wrap">
                                  <span className="me-2">
                                    {commonService.toNumberWithComma(item.qty)}
                                  </span>
                                  <span
                                    className="text-danger pointer"
                                    onClick={(e) => {
                                      if (savedCartItems.items.length === 1) {
                                        clearCart();
                                      } else {
                                        setItemToDelete(item);
                                      }
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                          <Dropdown.Divider></Dropdown.Divider>
                        </>
                      )}
                      <div className="px-3 flex no-wrap gap-10">
                        <strong
                          className="text-primary pointer flex-1"
                          onClick={(e) => {
                            navigate("/order-guide");
                          }}
                        >
                          Place Your Order...
                        </strong>
                        {!!savedCartItems?.items?.length && (
                          <strong
                            className="text-danger pointer"
                            onClick={(e) => {
                              setShowClearAll(true);
                            }}
                          >
                            Clear Cart
                          </strong>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

              <Dropdown>
                <Dropdown.Toggle id="dropdown-profile">
                  <i className="fa fa-user-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right">
                  {loginStatus.canAccessWebsite && (
                    <>
                      <Dropdown.Header>
                        <i className="zmdi zmdi-account mx-1 me-3"></i>
                        {commonService.isEmployeeSite ? (
                          <span>{systemStore.accountInfo?.name}</span>
                        ) : (
                          <span>
                            {SysServices.http.fetcher.getCustomerName() ||
                              SysServices.http.fetcher.getGivenNameFromToken()}
                          </span>
                        )}
                      </Dropdown.Header>
                      <Dropdown.Divider></Dropdown.Divider>
                      {!commonService.isEmployeeSite &&
                        !loginStatus.mustDoSomething?.shouldPickCustomer && (
                          <>
                            <Dropdown.Item
                              onClick={() => navigate("/billing-center")}
                            >
                              <i className="zmdi zmdi-card me-3"></i>
                              <span>My Balance</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => navigate("/customer-service")}
                            >
                              <i className="zmdi zmdi-account-box-phone me-3"></i>
                              <span>Contact Us</span>
                            </Dropdown.Item>
                          </>
                        )}
                    </>
                  )}

                  {loginStatus.loggedIn && (
                    <>
                      {!commonService.isEmployeeSite && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              if (!commonService.isEmployeeSite) {
                                navigate("/change-password", { replace: true });
                                return;
                              }
                              instance.loginRedirect({
                                scopes: protectedResources.apiMwf.scopes,
                                authority:
                                  b2cPolicies.authorities.forgotPassword
                                    .authority,
                              });
                            }}
                          >
                            <i className="fa fa-lock me-3"></i>
                            Change Password
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/2fa", { replace: true });
                            }}
                          >
                            <i className="fa fa-shield me-3"></i>
                            Two-Factor Authentication
                          </Dropdown.Item>
                          {canSwitch && (
                            <Dropdown.Item
                              onClick={() => {
                                systemStore.resetPickedCustomer(
                                  SysServices.http.fetcher.getToken()
                                );
                              }}
                            >
                              <i className="fa fa-retweet me-3"></i>
                              Switch Customer
                            </Dropdown.Item>
                          )}
                        </>
                      )}

                      <Dropdown.Item
                        onClick={() => {
                          if (!commonService.isEmployeeSite) {
                            setInProgress(true);
                            SysServices.http.customerUser
                              .logout(
                                SysServices.http.fetcher.getToken().sessionId
                              )
                              .then((data) => {
                                //do nothing...
                              })
                              .catch((err) => {
                                toastStore.showError("Logout Error", err);
                              })
                              .finally(() => {
                                SysServices.http.fetcher.clearToken();
                                systemStore.setSavedCartItems([]);
                                setInProgress(false);
                                navigate("/", { replace: true });
                              });
                            return;
                          }
                          const account = systemStore.accountInfo?.homeAccountId
                            ? instance.getAccountByHomeId(
                                systemStore.accountInfo.homeAccountId
                              )
                            : null;
                          instance.logoutRedirect({
                            account: account,
                            postLogoutRedirectUri: "/logout",
                          });
                        }}
                      >
                        <i className="zmdi zmdi-power me-3"></i>
                        <span>Log Out</span>
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Header className="text-center">
                    <small>
                      v<span>{packageInfo.version}</span>
                    </small>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="search-box">
              <input type="text" placeholder="Search"></input>
              <i className="fa fa-search"></i>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
