import React, { useState } from "react";
import FormModal from "../../components/common/FormModal";
import SysModels from "../../models";
import commonService from "../../services/CommonService";
import ConfirmDialog from "../../components/common/ConfirmDialog";

function PayInvoiceDialog(props: {
  onClose: () => void;
  onRemoveInvoice: (item: SysModels.IInvoiceGridItemDto) => void;
  invoices: SysModels.IInvoiceGridItemDto[];
}) {
  const [invoices, setInvoices] = useState([...props.invoices]);
  const [invToDelete, setInvToDelte] = useState<number>();

  return (
    <>
      {!!invToDelete && (
        <ConfirmDialog
          show={true}
          title="Remove Invoice"
          message={`Do you really want to remove this invoice: ${
            invoices.find((x) => x.sqlId === invToDelete)?.tranId || ""
          }?`}
          buttons="yesno"
          done={(val) => {
            if (val === "yes") {
              const toDel = invoices.find((x) => x.sqlId === invToDelete);
              toDel && props.onRemoveInvoice(toDel);
              setInvoices((prev) => {
                return [...prev.filter((x) => x.sqlId !== invToDelete)];
              });
            }
            setInvToDelte(undefined);
          }}
        ></ConfirmDialog>
      )}
      <FormModal
        title="Pay Invoice"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
        moveBehind={!!invToDelete}
      >
        <div className="p-4">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Date</th>
                <th className="text-right">Amount</th>
                {invoices.length > 1 && <th></th>}
              </tr>
            </thead>
            <tbody>
              {invoices.map((item) => (
                <tr key={item.sqlId}>
                  <td>{item.tranId}</td>

                  <td>
                    {commonService.toDateString(
                      item.tranDate,
                      "ddd, MMM DD, YYYY"
                    )}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(item.tranAmount)}
                  </td>
                  {invoices.length > 1 && (
                    <td className="text-right">
                      <i
                        className="fa fa-trash text-danger pointer"
                        onClick={(e) => {
                          setInvToDelte(item.sqlId);
                        }}
                      ></i>
                    </td>
                  )}
                </tr>
              ))}
              <tr className="text-right px-1">
                <td
                  colSpan={3}
                  className="py-2"
                  style={{
                    border: "none",
                  }}
                >
                  <h5>
                    <strong className="me-4">Total:</strong>
                    <strong>
                      {commonService.toMoney(
                        invoices
                          .map((x) => x.tranAmount)
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                          }, 0)
                      )}
                    </strong>
                  </h5>
                </td>
                {invoices.length > 1 && (
                  <td
                    style={{
                      border: "none",
                    }}
                  ></td>
                )}
              </tr>
            </tbody>
          </table>

          <div className="pb-4">
            <strong className="text-primary">Choose Payment Method</strong>
          </div>
          <div className="pb-4 alert alert-sm alert-secondary">
            <div className="flex flex-row">
              <div className="p-2">
                <button type="button" className="btn btn-light">
                  <i className="fa fa-cc-visa text-primary fa-3x pointer"></i>
                </button>
              </div>
              <div className="p-2">
                <button type="button" className="btn btn-light">
                  <i className="fa fa-cc-mastercard text-primary fa-3x pointer"></i>
                </button>
              </div>
              <div className="p-2">
                <button type="button" className="btn btn-light">
                  <i className="fa fa-cc-amex text-primary fa-3x pointer"></i>
                </button>
              </div>
              <div className="p-2">
                <button type="button" className="btn btn-light">
                  <i className="fa fa-cc-paypal text-primary fa-3x pointer"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={(e) => {
                props.onClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </FormModal>
    </>
  );
}

export default PayInvoiceDialog;
