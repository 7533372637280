import React from "react";
import SysModels from "../../models";

function ColumnHeaderSortComponent(props: {
  name: string;
  mode: SysModels.OrderByEnum | null;
  onClick: (mode: SysModels.OrderByEnum | null) => void;
}) {
  return (
    <strong
      className="pointer text-success"
      onClick={() => props.onClick(props.mode)}
    >
      {props.mode === SysModels.OrderByEnum.Ascending && (
        <i className="fa fa-long-arrow-up me-1"></i>
      )}
      {props.mode === SysModels.OrderByEnum.Descending && (
        <i className="fa fa-long-arrow-down me-1"></i>
      )}
      {props.name}
    </strong>
  );
}

export default ColumnHeaderSortComponent;
