import React, { useEffect, useState } from "react";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import CommonSpinner from "../components/common/CommonSpinner";
import { useNavigate } from "react-router-dom";

function ChangePassword(props: { must?: boolean }) {
  const navigate = useNavigate();
  const [pwData, setPwData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [inProgress, setInProgress] = useState(false);

  const allowSave = () => {
    return (
      !commonService.isNullOrEmpty(pwData.currentPassword) &&
      !commonService.isNullOrEmpty(pwData.newPassword) &&
      !commonService.isNullOrEmpty(pwData.confirmPassword) &&
      passwordMatch()
    );
  };

  const passwordMatch = () => {
    if (
      !commonService.isNullOrEmpty(pwData.newPassword) &&
      !commonService.isNullOrEmpty(pwData.confirmPassword)
    ) {
      return pwData.newPassword === pwData.confirmPassword;
    }
    return true;
  };

  useEffect(() => {
    commonService.focusInput("login_password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {inProgress && (
        <CommonSpinner
          overlay={true}
          message="Changing Password..."
        ></CommonSpinner>
      )}

      <div className="row">
        {props.must && <div className="cols-sm-12 col-md-6 col-lg-4"></div>}
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="default-page-layout">
            <h4>Change Password</h4>
            {props.must && (
              <div className="text-danger mb-4">
                <label>
                  <i className="fa fa-lock me-1"></i> Please reset your password
                </label>
              </div>
            )}
          </div>
          <div className="bg-white mb-2">
            <div className="p-3 bg-red txt-light">
              <h5 className="m-0">
                <em className="zmdi zmdi-lock me-2"></em>Enter Passwords
              </h5>
            </div>
            <div className="p-3" style={{ minHeight: "230px" }}>
              <form
                className="pt-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (allowSave()) {
                    setInProgress(true);
                    SysServices.http.customerUser
                      .changePassword(pwData)
                      .then((data: any) => {
                        toastStore.showToast(
                          "Password Updated. Please re-login.",
                          "success"
                        );
                        SysServices.http.customerUser
                          .logout(SysServices.http.fetcher.getToken().sessionId)
                          .finally(() => {
                            SysServices.http.fetcher.clearToken();
                            navigate("/");
                          });
                      })
                      .catch((err: any) => {
                        toastStore.showError("Change Password Failed", err);
                        setInProgress(false);
                      });
                  }
                }}
              >
                <input
                  id="login_password"
                  type="password"
                  name="current-password"
                  placeholder="Current Password"
                  className="form-control mb-3"
                  value={pwData.currentPassword}
                  onChange={(e) => {
                    setPwData((p) => {
                      return {
                        ...p,
                        currentPassword: e.target.value,
                      };
                    });
                  }}
                  disabled={inProgress}
                />
                <hr className="my-4" />
                <input
                  type="password"
                  name="password"
                  placeholder="New Password"
                  className="form-control mb-3"
                  value={pwData.newPassword}
                  onChange={(e) => {
                    setPwData((p) => {
                      return {
                        ...p,
                        newPassword: e.target.value,
                      };
                    });
                  }}
                  disabled={inProgress}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Confirm New Password"
                  className="form-control mb-2"
                  value={pwData.confirmPassword}
                  onChange={(e) => {
                    setPwData((p) => {
                      return {
                        ...p,
                        confirmPassword: e.target.value,
                      };
                    });
                  }}
                  disabled={inProgress}
                />
                {!passwordMatch() && (
                  <div className="text-danger mb-2">
                    <span>New Passwords does not match</span>
                  </div>
                )}

                <button
                  className="btn btn-primary mt-3"
                  type="submit"
                  disabled={inProgress || !allowSave()}
                >
                  {inProgress ? "Saving..." : "Save Changes"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-6 col-lg-4"></div>
        {!props.must && <div className="cols-sm-12 col-md-6 col-lg-4"></div>}
      </div>
    </>
  );
}

export default ChangePassword;
