import React, { useEffect, useState } from "react";
import SysServices from "../services";
import systemStore from "../stores/SystemStore";

function PickCustomer(props: any) {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    setRoles(SysServices.http.fetcher.getRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row" id="pick-customer">
        <div className="cols-sm-12 col-md-6 col-lg-4"></div>
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="bg-white mb-2">
            <div className="p-3 bg-green txt-light">
              <h5 className="m-0">
                <em className="fa fa-building me-2"></em>Pick a Customer
              </h5>
            </div>
            <div className="p-3" style={{ minHeight: "230px" }}>
              {roles.map((role) => {
                const arr = role.split("~~~");
                return (
                  <div
                    key={role}
                    className="btn btn-secondary mb-2 pointer flex flex-center pick-customer-chip"
                    onClick={(e) => {
                      systemStore.setSavedCartItems([]);
                      systemStore.pickCustomer(
                        SysServices.http.fetcher.getToken(),
                        arr[0]
                      );
                    }}
                  >
                    <div className="flex-1 text-left">
                      {arr[1]} (<strong>{arr[0]}</strong>)
                    </div>
                    <div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-6 col-lg-4"></div>
      </div>
    </>
  );
}

export default PickCustomer;
