import React from "react";

function PaymentStatusBadge(props: { amountDue: number; daysOverDue: number }) {
  return (
    <>
      {props.amountDue === 0 && <span className="chip-olive">Paid</span>}
      {props.amountDue > 0 && props.daysOverDue > 0 && (
        <span className="chip-red">Overdue</span>
      )}
    </>
  );
}

export default PaymentStatusBadge;
