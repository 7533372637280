import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { Button } from "react-bootstrap";
import DocEditor from "../components/common/DocEditor";
import { useParams } from "react-router-dom";

function CustomerDocumentationDetail(props: any) {
  const { id } = useParams();
  const docObj = useFetchHelper(async () => {
    return SysServices.http.generalDocumentation.getPublished(id || "");
  }, "Document");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    docObj.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, docObj]);

  return (
    <>
      {docObj.status === FetchStatus.InProgress && (
        <CommonSpinner></CommonSpinner>
      )}
      {docObj.status === FetchStatus.Complete && !!docObj.data && (
        <div style={{ background: "#fff" }}>
          <div className="show-on-print">
            <strong>{docObj.data.title}</strong>
            <div className="py-2" style={{ borderBottom: "solid 1px #ddd" }}>
              {docObj.data.subject}
            </div>
          </div>

          <div
            className="hide-on-print px-4 py-2 flex flex-center"
            style={{ background: "#eee", borderBottom: "solid 1px #ddd" }}
          >
            <div className="flex-1">
              <div className="mb-2">
                <strong>{docObj.data.title}</strong>
              </div>
              {!!`${docObj.data.subject || ""}`.trim() && (
                <div>{docObj.data.subject}</div>
              )}
            </div>
            <div>
              <Button
                type="button"
                variant="default"
                onClick={(e) => {
                  window.print();
                }}
              >
                <i className="fa fa-print txt-primary"></i>
              </Button>
            </div>
          </div>
          <div className="px-4 py-3">
            <DocEditor
              readonly={true}
              content={docObj.data.content}
            ></DocEditor>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerDocumentationDetail;
