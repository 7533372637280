import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";

function CustomerService(props: any) {
  const navigate = useNavigate();
  const salesTeam = useFetchHelper<SysModels.ICustomerSalesPersonDto[]>(
    SysServices.http.sales.salesTeam,
    "Sales Team"
  );

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    salesTeam.getData();
  }, [calledOnce, salesTeam]);

  return (
    <>
      <div className="default-page-layout">
        <h4>Customer Service</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="bg-white mb-2">
            <div className="p-3 bg-red txt-light">
              <h5 className="m-0">
                <em className="zmdi zmdi-account me-2"></em>Your Sales Team
              </h5>
            </div>
            <div className="p-3" style={{ minHeight: "230px" }}>
              {salesTeam.status === FetchStatus.InProgress && (
                <div>
                  <CommonSpinner message="Getting Your Sales Team"></CommonSpinner>
                </div>
              )}
              {salesTeam.status === FetchStatus.Failed && (
                <div className="bg-white p-3 text-danger">
                  <i className="fa fa-warning"></i> Failed getting your team.
                </div>
              )}
              {/* {salesTeam.status === FetchStatus.Complete &&
                salesTeam.data?.length === 0 && <div>No Sales Team Found</div>} */}
              {salesTeam.status === FetchStatus.Complete && (
                <>
                  {salesTeam.data?.map((item) => (
                    <div key={item.email} className="pb-2">
                      <div
                        className="flex flex-center"
                        style={{ background: "#eee", padding: "8px 12px" }}
                      >
                        <i className="fa fa-user me-2 txt-primary"></i>
                        <div className="flex-1">
                          <span className="pe-2">{item.personName}</span>
                          <div>
                            <small>BDA</small>
                          </div>
                          <small>
                            <a
                              className="mail-link"
                              href={`mailto:${item.email}`}
                            >
                              {item.email}
                            </a>
                          </small>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="pb-2">
                    <div
                      className="flex flex-center"
                      style={{ background: "#eee", padding: "8px 12px" }}
                    >
                      <i className="fa fa-user me-2 txt-primary"></i>
                      <div className="flex-1">
                        <div>
                          <span className="pe-2">IN HOUSE</span>
                        </div>
                        <small>
                          <a
                            className="mail-link"
                            href="mailto:sa@midwestfoods.com"
                          >
                            sa@midwestfoods.com
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="bg-white mb-2">
            <div className="p-3 bg-blue txt-light">
              <h5 className="m-0">
                <em className="zmdi zmdi-account-box-phone me-2"></em>Customer
                Service Info
              </h5>
            </div>
            <div className="p-3" style={{ minHeight: "230px" }}>
              <div>
                <div className="pb-1">
                  <h5>Midwest Foods</h5>
                </div>
                <div className="pb-1">3100 W. 36th Street </div>
                <div className="pb-1">Chicago, IL 60632</div>
                <div className="pb-1">
                  <a className="mail-link" href="tel:(773) 927-8870">
                    (773) 927-8870
                  </a>
                </div>
                <div className="pb-1">
                  <a
                    className="mail-link"
                    href="mailto:orders@midwestfoods.com"
                  >
                    orders@midwestfoods.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-6 col-lg-4">
          <div className="bg-white mb-2">
            <div className="p-3 bg-grey txt-light">
              <h5 className="m-0">
                <em className="zmdi zmdi-link me-2"></em>Quick Links
              </h5>
            </div>
            <div style={{ padding: "16px", minHeight: "230px" }}>
              <p className="text-primary mb-10">
                <span
                  className="pointer"
                  onClick={() => navigate("/order-guide")}
                >
                  <em className="zmdi zmdi-collection-text me-2"></em> Place an
                  Order
                </span>
              </p>
              <p className="text-primary m-0">
                <span
                  className="pointer"
                  onClick={() => navigate("/billing-center")}
                >
                  <em className="zmdi zmdi-book me-2"></em> Billing Center
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerService;
