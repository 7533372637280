import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import { useNavigate } from "react-router";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore, {
  useSavedCartItems,
  useWindowSize,
} from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import UseAsTemplateConfirmation from "./UseAsTemplateConfirmation";

function OrderDetailDialog(props: {
  order: SysModels.ISalesOrderGridItemDto;
  csrOrder?: { orderId: number; customerId: string };
  salesRepOrder?: { orderId: number; customerId: string };
  onClose: () => void;
  reportProblem?: boolean;
  customerNumber?: string; //SET ONLY FROM GLOBAL SEARCH
}) {
  const navigate = useNavigate();
  const orderDetail = useFetchHelper<SysModels.IOrderOutputDto>(async () => {
    if (props.csrOrder) {
      return SysServices.http.sales.getOrderGuideOrder(
        props.csrOrder.orderId,
        props.csrOrder.customerId
      );
    }
    if (props.salesRepOrder) {
      return SysServices.http.sales.getNsOrder(
        props.salesRepOrder.orderId,
        props.salesRepOrder.customerId
      );
    }
    if (props.order.portalSqlId) {
      return SysServices.http.sales.getOrderGuideOrder(props.order.portalSqlId);
    } else {
      return SysServices.http.sales.getNsOrder(
        props.order.nsSqlId,
        props.customerNumber
      );
    }
  }, "Order Detail");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    orderDetail.getData();
  }, [calledOnce, orderDetail]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [useTempConfirm, setUseTempConfirm] = useState<{
    id: number;
    netSuite: boolean;
  }>();
  const savedCartItems = useSavedCartItems();

  const [msg, setMsg] = useState({
    subject: "",
    message: "",
  });

  const [sendingReport, setSendingReport] = useState(false);
  const sendReport = async () => {
    if (orderDetail.data) {
      setSendingReport(true);
      await SysServices.http.sales
        .sendSupportEmail({
          ...msg,
          orderGuideId: props.order.portalSqlId,
          nsId: props.order.nsSqlId,
        })
        .then((data) => {
          setSendingReport(false);
          toastStore.showToast("Report Sent", "success");
          props.onClose();
        })
        .catch((error) => {
          setSendingReport(false);
          toastStore.showError("Failed Sending Report", error);
        });
    }
  };

  const [isSwitching, setIsSwitching] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (props.reportProblem && orderDetail.status === FetchStatus.Complete) {
      commonService.focusInput("txt-subject", 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail.status]);

  return (
    <>
      {!!useTempConfirm && (
        <UseAsTemplateConfirmation
          onClose={(rtn) => {
            if (rtn === "append" || rtn === "clear") {
              systemStore.setOrderGuideTemplate(
                useTempConfirm.id,
                useTempConfirm.netSuite
              );
              navigate("/order-guide");
            }
            setUseTempConfirm(undefined);
          }}
        ></UseAsTemplateConfirmation>
      )}
      <FormModal
        title={props.reportProblem ? "Report a Problem" : "Order Detail"}
        titleClass={props.reportProblem ? "text-danger" : ""}
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        submitButtonLabel="Submit Order"
        size="lg"
        customBodyFooter={true}
        moveBehind={!!useTempConfirm}
      >
        {isSwitching && (
          <CommonSpinner
            overlay={true}
            message="Switching to Customer..."
          ></CommonSpinner>
        )}
        <ModalBody>
          {orderDetail.status === FetchStatus.InProgress && (
            <CommonSpinner message="Getting Order Detail"></CommonSpinner>
          )}
          {orderDetail.status === FetchStatus.Failed && (
            <div className="text-center p-4">
              <span className="text-danger">Failed Getting Order Detail</span>
              <br />
              <span
                className="text-primary pointer"
                onClick={() => orderDetail.getData()}
              >
                Try Again
              </span>
            </div>
          )}
          {orderDetail.status === FetchStatus.Complete && orderDetail.data && (
            <>
              <div className="row pb-3">
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <table className="no-format">
                    <tbody>
                      {/* {(!!props.csrOrder || commonService.isEmployeeSite) && (
                        <> */}
                      <tr>
                        <td className="pe-2" style={{ verticalAlign: "top" }}>
                          <strong>Customer:</strong>
                        </td>
                        <td>
                          {commonService.isEmployeeSite ? (
                            <span
                              className="text-primary pointer"
                              onClick={(e) => {
                                setIsSwitching(true);
                                SysServices.http.customer
                                  .get(orderDetail.data?.erpCustomerNumber)
                                  .then((data) => {
                                    systemStore.setCurrentCustomer({
                                      name: data.customerName,
                                      number: data.erpCustomerNumber,
                                      id: data.sqlId,
                                      data: data,
                                    });
                                    props.onClose();
                                  })
                                  .catch((error) => {
                                    toastStore.showError(
                                      "Failed Getting Customer",
                                      error
                                    );
                                    setIsSwitching(false);
                                  });
                              }}
                            >
                              {orderDetail.data?.customerName} (
                              {orderDetail.data?.erpCustomerNumber})
                            </span>
                          ) : (
                            <span>
                              {orderDetail.data?.customerName} (
                              {orderDetail.data?.erpCustomerNumber})
                            </span>
                          )}
                        </td>
                      </tr>
                      {/* </>
                      )} */}
                      <tr>
                        <td className="pe-2 no-wrapx">
                          <strong>
                            {orderDetail.data?.tranId
                              ? "Order Number"
                              : "Preliminary Order Number"}
                            :
                          </strong>
                        </td>
                        <td>
                          <span>
                            {orderDetail.data?.tranId ||
                              orderDetail.data?.sqlId}
                          </span>
                        </td>
                      </tr>
                      {!!props.salesRepOrder && (
                        <>
                          <tr>
                            <td className="pe-2 no-wrap">
                              <strong>PO Number:</strong>
                            </td>
                            <td>
                              <span>{orderDetail.data?.poNumber}</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="pe-2">
                              <strong>Delivery Date:</strong>
                            </td>
                            <td>
                              {orderDetail.data?.placedOn &&
                                commonService.toDateString(
                                  orderDetail.data?.placedOn,
                                  "date"
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                      {!props.salesRepOrder && (
                        <>
                          <tr>
                            <td className="pe-2">
                              <strong>Placed On:</strong>
                            </td>
                            <td>
                              {orderDetail.data?.placedOn &&
                                commonService.toLocalDate(
                                  orderDetail.data?.placedOn,
                                  "date"
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pe-2"></td>
                            <td>
                              {orderDetail.data?.placedOn &&
                                commonService.toLocalDate(
                                  orderDetail.data?.placedOn,
                                  "time"
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pe-2">
                              <strong>Delivery Date:</strong>
                            </td>
                            <td>
                              {orderDetail.data?.deliveryDate &&
                                commonService.toDateString(
                                  orderDetail.data?.deliveryDate,
                                  "date"
                                )}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className="pe-2">
                          <strong>Total:</strong>
                        </td>
                        <td>
                          {commonService.toMoney(
                            orderDetail.data?.totalOrderAmount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-7">
                  <strong>Comments:</strong>
                  <br />
                  {(orderDetail.data?.notes || "").trim() === "" ? (
                    <span style={{ color: "#888" }}>
                      <i>Not Supplied</i>
                    </span>
                  ) : (
                    orderDetail.data?.notes
                  )}
                </div>
              </div>

              <table className="table table-striped table-sm w-100 bg-white">
                <thead>
                  <tr>
                    {windowSize.isSmall ? (
                      <>
                        <th>Item</th>
                        <th>Price</th>
                      </>
                    ) : (
                      <>
                        <th>Item</th>
                        <th>Description</th>
                        <th className="text-center">Quantity</th>
                        <th> UOM</th>
                        <th className="text-right">Price</th>
                        <th className="text-right">Total</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(orderDetail.data?.orderLineOutputDtos || []).map((item) => (
                    <tr key={item.itemCode}>
                      {windowSize.isSmall ? (
                        <>
                          <td className="pe-4">
                            <div>{item.itemCode}</div>
                            {item.itemDescription}
                            <div className="txt-grey">
                              <small>{item.category}</small>
                            </div>
                          </td>
                          <td className="text-right no-wrap">
                            <table className="table-sm">
                              <tbody>
                                <tr>
                                  <td className="text-left">
                                    <small>QTY:</small>
                                  </td>
                                  <td className="text-right">
                                    {item.quantity.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>UOM:</small>
                                  </td>
                                  <td className="text-right">{item.uom}</td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>Price:</small>
                                  </td>
                                  <td className="text-right">
                                    {commonService.toMoney(item.unitPrice || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <small>Total:</small>
                                  </td>
                                  <td className="text-right">
                                    {commonService.toMoney(
                                      item.totalPrice || 0
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{item.itemCode}</td>
                          <td>
                            {item.itemDescription}
                            <div className="txt-grey">
                              <small>{item.category}</small>
                            </div>
                          </td>
                          <td className="text-center">
                            {item.quantity.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.uom}</td>
                          <td className="text-right">
                            {commonService.toMoney(item.unitPrice || 0)}
                          </td>
                          <td className="text-right">
                            {commonService.toMoney(item.totalPrice || 0)}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              {props.reportProblem && (
                <div className="pt-2">
                  <div className="pb-2 text-danger">
                    <strong>Subject</strong>{" "}
                    <small style={{ color: "#777" }}>
                      {msg.subject.length}/128
                    </small>
                  </div>
                  <input
                    id="txt-subject"
                    type="text"
                    placeholder="Subject"
                    className="form-control"
                    value={msg.subject}
                    onChange={(e) => {
                      setMsg((prev) => {
                        return {
                          ...prev,
                          subject: e.target.value,
                        };
                      });
                    }}
                    maxLength={128}
                    disabled={sendingReport}
                  />
                  <div className="py-2 text-danger">
                    <strong>Message</strong>{" "}
                    <small style={{ color: "#777" }}>
                      {msg.message.length}/4000
                    </small>
                  </div>
                  <textarea
                    rows={4}
                    placeholder="Message"
                    className="form-control"
                    value={msg.message}
                    onChange={(e) => {
                      setMsg((prev) => {
                        return {
                          ...prev,
                          message: e.target.value,
                        };
                      });
                    }}
                    maxLength={4000}
                    disabled={sendingReport}
                  ></textarea>
                </div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!!props.customerNumber && (
            <Button
              type="button"
              onClick={() => {
                window.print();
              }}
              variant="primary"
              size="sm"
              disabled={orderDetail.status !== FetchStatus.Complete}
            >
              <i className="fa fa-print"></i> Print
            </Button>
          )}

          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
            disabled={orderDetail.status === FetchStatus.InProgress}
          >
            Close
          </Button>

          {props.reportProblem && (
            <>
              <Button
                type="button"
                onClick={() => {
                  sendReport();
                }}
                variant="success"
                size="sm"
                disabled={
                  sendingReport ||
                  orderDetail.status === FetchStatus.InProgress ||
                  !orderDetail.data ||
                  msg.subject.trim() === "" ||
                  msg.message.trim() === ""
                }
              >
                {sendingReport ? "Sending Message..." : "Send Message"}
              </Button>
            </>
          )}
          {!props.csrOrder &&
            !props.salesRepOrder &&
            !props.reportProblem &&
            !props.customerNumber && (
              <>
                {systemStore.allowOrderGuide && (
                  <Button
                    type="button"
                    onClick={() => {
                      if (props.order.portalSqlId || props.order.nsSqlId) {
                        if (
                          savedCartItems &&
                          savedCartItems.items?.length > 0
                        ) {
                          setUseTempConfirm({
                            id: props.order.nsSqlId
                              ? props.order.nsSqlId
                              : props.order.portalSqlId,
                            netSuite: !!props.order.nsSqlId,
                          });
                        } else {
                          systemStore.setOrderGuideTemplate(
                            props.order.nsSqlId
                              ? props.order.nsSqlId
                              : props.order.portalSqlId,
                            !!props.order.nsSqlId
                          );
                          navigate("/order-guide");
                        }
                      }
                    }}
                    variant="primary"
                    size="sm"
                    disabled={
                      sendingEmail ||
                      orderDetail.status === FetchStatus.InProgress ||
                      !orderDetail.data
                    }
                  >
                    Use as Template
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => {
                    setSendingEmail(true);
                    (props.order.nsSqlId
                      ? SysServices.http.sales.sendSalesOrderConfirmationEmail(
                          props.order.nsSqlId
                        )
                      : SysServices.http.sales.sendOrderGuideOrderConfirmationEmail(
                          props.order.portalSqlId
                        )
                    )
                      .then(() => {
                        setSendingEmail(false);
                        toastStore.showToast("Email Sent.", "success");
                      })
                      .catch((error) => {
                        setSendingEmail(false);
                        toastStore.showError("Failed Sending Email", error);
                      });
                  }}
                  variant="primary"
                  size="sm"
                  disabled={
                    sendingEmail ||
                    orderDetail.status === FetchStatus.InProgress ||
                    !orderDetail.data
                  }
                >
                  {sendingEmail ? "Sending Email..." : "Email Me a Copy"}
                </Button>
              </>
            )}
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default OrderDetailDialog;
