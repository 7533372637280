import React, { useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import InvoiceViewDialog from "./invoices/InvoiceViewDialog";
import CreditMemoViewDialog from "./invoices/CreditMemoViewDialog";
import dayjs from "dayjs";
import toastStore from "../stores/ToastStore";
import FileSaver from "file-saver";
import PaymentStatusBadge from "../components/common/PaymentStatusBadge";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../components/common/DateRangePicker";
import { Doughnut } from "react-chartjs-2";
import PayInvoiceDialog from "./invoices/PayInvoiceDialog";
import FormModal from "../components/common/FormModal";
import { useParams } from "react-router-dom";

function BillingCenter(props: any) {
  const agingSummary = useFetchHelper<SysModels.IAgingSummaryDto>(
    SysServices.http.sales.agingSummary,
    "Aging Summary"
  );
  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    agingSummary.getData();
    allowInvoicePayment.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce]);

  const [invoiceToView, setInvoiceToView] = useState<any>();
  const [invTabLoaded, setInvoiceTabLoaded] = useState(false);
  const [invPaging, setInvoicePaging] = usePaging(1, 50);
  const invPageChange = (page: number, pageSize: number) => {
    setInvoicePaging({ ...invPaging, page: page, pageSize: pageSize });
  };
  const invoices = useFetchHelper(
    () =>
      SysServices.http.sales.listInvoices(invPaging.page, invPaging.pageSize, {
        salesOrderGridOrderByEnum:
          SysModels.SalesOrderGridOrderByEnum.OrderDate,
        orderByEnum: SysModels.OrderByEnum.Descending,
        fromDate: null as any,
        toDate: null as any,
        search: "",
      }),
    "Invoices"
  );
  useEffect(() => {
    if (invTabLoaded) {
      invoices.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invTabLoaded, invPaging]);

  const allowInvoicePayment = useFetchHelper(
    SysServices.http.customerInvoicePayment.allowInvoicePayment,
    "Allow Invoice Payment"
  );

  const [onlinePaymentToView, setOnlinePaymentToView] =
    useState<SysModels.IPaymentInformationDto>();
  const [onlinePayLoaded, setOnlinePayTabLoaded] = useState(false);
  const [onlinePayPaging, setOnlinePayPaging] = usePaging(1, 50);
  const onlinePayPageChange = (page: number, pageSize: number) => {
    setOnlinePayPaging({ ...invPaging, page: page, pageSize: pageSize });
  };
  const onlinePayments = useFetchHelper(
    () =>
      SysServices.http.customerInvoicePayment.list(
        onlinePayPaging.page,
        onlinePayPaging.pageSize
      ),
    "Online Payments"
  );
  useEffect(() => {
    if (onlinePayLoaded) {
      onlinePayments.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlinePayLoaded, onlinePayPaging]);

  const [memoToView, setMemoToView] = useState<any>();
  const [memoTabLoaded, setMemoTabLoaded] = useState(false);
  const [memoPaging, setMemoPaging] = usePaging(1, 50);
  const memoPageChange = (page: number, pageSize: number) => {
    setMemoPaging({ ...memoPaging, page: page, pageSize: pageSize });
  };
  const memos = useFetchHelper(
    async () =>
      SysServices.http.sales.listCreditMemos(
        memoPaging.page,
        memoPaging.pageSize,
        {
          salesOrderGridOrderByEnum:
            SysModels.SalesOrderGridOrderByEnum.OrderDate,
          orderByEnum: SysModels.OrderByEnum.Descending,
          fromDate: null as any,
          toDate: null as any,
          search: "",
        }
      ),
    "Credt Memos"
  );
  useEffect(() => {
    if (memoTabLoaded) {
      memos.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoTabLoaded, memoPaging]);

  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: GetPeriodValues(DatePeriod.ThisYear).from,
    to: GetPeriodValues(DatePeriod.ThisYear).to,
  });
  const [spendByLoaded, setSpendByLoaded] = useState(false);
  const spendBySource = useFetchHelper(
    async () =>
      SysServices.http.sales.getLocalSpendBySource(
        dayjs(period.from).format("YYYY-MM-DD"),
        dayjs(period.to).format("YYYY-MM-DD")
      ),
    "Spend By Source"
  );
  useEffect(() => {
    if (spendByLoaded) {
      spendBySource.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendByLoaded, period]);

  const [graphPeriod, setGraphPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: GetPeriodValues(DatePeriod.ThisYear).from,
    to: GetPeriodValues(DatePeriod.ThisYear).to,
  });
  const [graphLoaded, setGraphLoaded] = useState(false);
  const summaryGraph = useFetchHelper(
    async () =>
      SysServices.http.sales.localVsAllComparison({
        fromDate: dayjs(graphPeriod.from).startOf("day").toDate(),
        toDate: dayjs(graphPeriod.to).endOf("day").toDate(),
      }),
    "Summary Graph"
  );
  useEffect(() => {
    if (graphLoaded) {
      summaryGraph.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphLoaded, graphPeriod]);

  const [downloading, setDownloading] = useState(false);
  const startDownload = async () => {
    setDownloading(true);
    await SysServices.http.sales
      .getLocalSpendBySourceDownload(
        dayjs(period.from).format("YYYY-MM-DD"),
        dayjs(period.to).format("YYYY-MM-DD")
      )
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  const getTotalOrder = () => {
    if (summaryGraph.data) {
      return (
        (summaryGraph.data.totalLocalAmount || 0) +
        (summaryGraph.data.totalOrderAmount || 0)
      );
    }
    return 0;
  };

  const [selectedInvoices, setSelectedInvoices] = useState<
    SysModels.IInvoiceGridItemDto[]
  >([]);
  const [showPayDialog, setShowPayDialog] = useState(false);

  const getOnlinePaymentClass = (message: string) => {
    if (message === "Denied") {
      return "table-danger";
    }
    return "";
  };

  const params = useParams();
  const [activeTab, setActiveTab] = useState("AgingSummary");
  useEffect(() => {
    if ((params as any).tab === "invoices") {
      setActiveTab("Invoices");
      setInvoiceTabLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!invoiceToView && (
        <InvoiceViewDialog
          onClose={() => {
            setInvoiceToView(undefined);
          }}
          invoiceId={invoiceToView}
        ></InvoiceViewDialog>
      )}
      {!!memoToView && (
        <CreditMemoViewDialog
          onClose={() => {
            setMemoToView(undefined);
          }}
          memoId={memoToView}
        ></CreditMemoViewDialog>
      )}
      {showPayDialog && (
        <PayInvoiceDialog
          invoices={[...selectedInvoices]}
          onClose={() => {
            setShowPayDialog(false);
          }}
          onRemoveInvoice={(item) => {
            setSelectedInvoices((prev) => {
              return [...prev.filter((x) => x.sqlId !== item.sqlId)];
            });
          }}
        ></PayInvoiceDialog>
      )}

      {!!onlinePaymentToView && (
        <FormModal
          title="Payment Detail"
          isOpen={true}
          close={() => {
            setOnlinePaymentToView(undefined);
          }}
          submit={(data) => {
            setOnlinePaymentToView(undefined);
          }}
          size="md"
          primaryButtonOnly={true}
          submitButtonLabel="Done"
        >
          <div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th className="text-right">Invoice Amount</th>
                  <th className="text-right">Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                {onlinePaymentToView.invoicesPayments.map((row) => {
                  return (
                    <tr key={row.invoiceNumber}>
                      <td>{row.invoiceNumber}</td>
                      <td className="text-right">
                        {commonService.toMoney(row.invoiceAmount)}
                      </td>
                      <td className="text-right">
                        {commonService.toMoney(row.amountPaid)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td
                    className="pt-2"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>Totals:</strong>
                  </td>
                  <td
                    className="pt-2 text-right"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>
                      {commonService.toMoney(
                        onlinePaymentToView.invoicesPayments
                          .map((x) => x.invoiceAmount)
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                          }, 0)
                      )}
                    </strong>
                  </td>
                  <td
                    className="pt-2 text-right"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>
                      {commonService.toMoney(
                        onlinePaymentToView.invoicesPayments
                          .map((x) => x.amountPaid)
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                          }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </FormModal>
      )}

      <div
        className={`default-page-layout billing-center ${
          !!invoiceToView || !!memoToView ? "hide-on-print" : ""
        }`}
      >
        {downloading && (
          <CommonSpinner
            overlay={true}
            message="Downloading..."
          ></CommonSpinner>
        )}

        <h4 className="hide-on-print">Billing Center</h4>

        {agingSummary.status === FetchStatus.Complete &&
          agingSummary.data &&
          agingSummary.data.totalOverDue > 0 && (
            <div className="row hide-on-print">
              <div className="cols-sm-12 col-md-6 col-lg-4">
                <div className="bg-white mb-4">
                  <div className="p-3 bg-red txt-light">
                    <h5 className="m-0">
                      <em className="zmdi zmdi-flag me-2"></em>Overdue Balance
                    </h5>
                  </div>
                  <div className="p-3" style={{ minHeight: "100px" }}>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 text-center">
                        <div>DUE</div>
                        <strong>
                          {commonService.toMoney(agingSummary.data.total)}
                        </strong>
                      </div>
                      <div className="col-sm-12 col-md-6 text-center">
                        <div>OVERDUE</div>
                        <strong className="text-danger">
                          {commonService.toMoney(
                            agingSummary.data.totalOverDue
                          )}
                        </strong>
                      </div>
                      <div className="col-sm-12 pt-3">
                        Your have overdue invoice(s) with a total balance due of{" "}
                        <span className="text-danger">
                          {commonService.toMoney(
                            agingSummary.data.totalOverDue
                          )}
                        </span>
                        . Please pay all outstanding invoices to avoid any
                        interruption of service and further collection attempts.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cols-sm-12 col-md-6 col-lg-4"></div>
              <div className="cols-sm-12 col-md-6 col-lg-4"></div>
            </div>
          )}

        <div className="row">
          <div className="col-sm-12">
            <Tabs
              transition={false}
              activeKey={activeTab}
              onSelect={(tab) => {
                tab && setActiveTab(tab);
                if (tab === "Invoices") {
                  !invTabLoaded && setInvoiceTabLoaded(true);
                }
                if (tab === "CreditMemos") {
                  !memoTabLoaded && setMemoTabLoaded(true);
                }
                if (tab === "SpendBySource") {
                  !spendByLoaded && setSpendByLoaded(true);
                }
                if (tab === "SummaryGraph") {
                  !graphLoaded && setGraphLoaded(true);
                }
                if (tab === "OnlinePayments") {
                  !onlinePayLoaded && setOnlinePayTabLoaded(true);
                }
              }}
            >
              <Tab eventKey="AgingSummary" title="Aging Summary">
                {agingSummary.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                {agingSummary.status === FetchStatus.Complete && (
                  <div>
                    <div className="print-wrapper hide-on-print">
                      <a
                        href="/"
                        className="no-decoration no-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i> Print
                      </a>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <strong className="show-on-print">Aging Summary</strong>
                    </div>
                    <div className="overflow-auto">
                      <table className="table billing-table">
                        <thead>
                          <tr>
                            <th className="text-center">0-30 Days</th>
                            <th className="text-center">31-60 Days</th>
                            <th className="text-center">61-90 Days</th>
                            <th className="text-center">91-120 Days</th>
                            <th className="text-center">120+ Days</th>
                            <th className="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">
                              {commonService.toMoney(agingSummary.data?.days30)}
                            </td>
                            <td className="text-center">
                              {commonService.toMoney(agingSummary.data?.days60)}
                            </td>
                            <td className="text-center">
                              {commonService.toMoney(agingSummary.data?.days90)}
                            </td>
                            <td className="text-center">
                              {commonService.toMoney(
                                agingSummary.data?.days120
                              )}
                            </td>
                            <td className="text-center">
                              {commonService.toMoney(
                                agingSummary.data?.daysOver120
                              )}
                            </td>
                            <td className="text-center">
                              {commonService.toMoney(agingSummary.data?.total)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey="Invoices" title="Invoices">
                {invoices.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                {invoices.status === FetchStatus.Complete && (
                  <div>
                    <div className="print-wrapper hide-on-print">
                      <a
                        href="/"
                        className="no-decoration no-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i> Print
                      </a>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <strong className="show-on-print">Invoices</strong>
                    </div>
                    <div
                      className="overflow-auto"
                      style={{
                        minHeight: "150px",
                      }}
                    >
                      <table className="table table-hover billing-table">
                        <thead>
                          <tr>
                            <th>Invoice Number</th>
                            <th>Date</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right">Paid Amount</th>
                            <th className="text-right">Amount Remaining</th>
                            <th className="text-right">Days Overdue</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            {!!invoices.data?.allowInvoicePayment && (
                              <th className="hide-on-print text-center">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    disabled={!selectedInvoices?.length}
                                    onClick={(e) => {
                                      setShowPayDialog(true);
                                    }}
                                  >
                                    {selectedInvoices.length ? (
                                      <>Pay ({selectedInvoices.length})</>
                                    ) : (
                                      <>Pay</>
                                    )}
                                  </button>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      disabled={
                                        !invoices.data.invoiceGridItemDtos
                                          .length
                                      }
                                    ></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          setSelectedInvoices((prev) => {
                                            return [
                                              ...prev.filter(
                                                (x) =>
                                                  !(
                                                    invoices.data
                                                      ?.invoiceGridItemDtos ||
                                                    []
                                                  )
                                                    .map((i) => i.sqlId)
                                                    .includes(x.sqlId)
                                              ),
                                              ...(invoices.data
                                                ?.invoiceGridItemDtos || []),
                                            ];
                                          });
                                        }}
                                      >
                                        <small>Select All (on this page)</small>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          setSelectedInvoices((prev) => {
                                            return [
                                              ...prev.filter(
                                                (x) =>
                                                  !(
                                                    invoices.data
                                                      ?.invoiceGridItemDtos ||
                                                    []
                                                  )
                                                    .map((i) => i.sqlId)
                                                    .includes(x.sqlId)
                                              ),
                                            ];
                                          });
                                        }}
                                      >
                                        <small>
                                          Unselect All (on this page)
                                        </small>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </th>
                            )}
                            <th className="hide-on-print"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoices?.data?.invoiceGridItemDtos?.map((item) => (
                            <tr
                              key={item.sqlId}
                              className="pointer"
                              onClick={() => {
                                setInvoiceToView(item.sqlId);
                              }}
                            >
                              <td>{item.tranId}</td>
                              <td className="no-wrap">
                                <span className="show-on-print">
                                  {commonService.toDateString(
                                    item.tranDate,
                                    "MM-DD-YYYY"
                                  )}
                                </span>
                                <span className="hide-on-print">
                                  {commonService.toDateString(item.tranDate)}
                                </span>
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.tranAmount)}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.paidAmount)}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.amountRemaining)}
                              </td>
                              <td className="text-right">
                                {commonService.toNumberWithComma(
                                  item.daysOverDue
                                )}
                              </td>
                              <td className="no-wrap">
                                <span className="show-on-print">
                                  {commonService.toDateString(
                                    item.dueDate,
                                    "MM-DD-YYYY"
                                  )}
                                </span>
                                <span className="hide-on-print">
                                  {commonService.toDateString(item.dueDate)}
                                </span>
                              </td>
                              <td>
                                <PaymentStatusBadge
                                  amountDue={item.amountRemaining}
                                  daysOverDue={item.daysOverDue}
                                ></PaymentStatusBadge>
                              </td>
                              {!!invoices.data?.allowInvoicePayment && (
                                <td
                                  className="hide-on-print text-center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  {!!(
                                    invoices.data?.allowInvoicePayment &&
                                    item.amountRemaining > 0
                                  ) && (
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                      onClick={(e) => {
                                        if (
                                          selectedInvoices.find(
                                            (i) => item.sqlId === i.sqlId
                                          )
                                        ) {
                                          setSelectedInvoices((invs) => {
                                            return [
                                              ...invs.filter(
                                                (i) => i.sqlId !== item.sqlId
                                              ),
                                            ];
                                          });
                                        } else {
                                          setSelectedInvoices((invs) => {
                                            return [...invs, { ...item }];
                                          });
                                        }
                                      }}
                                    >
                                      {selectedInvoices.find(
                                        (i) => item.sqlId === i.sqlId
                                      ) ? (
                                        <i className="fa fa-check-square pointer text-primary"></i>
                                      ) : (
                                        <i className="fa fa-square-o pointer text-primary"></i>
                                      )}
                                    </button>
                                  )}
                                </td>
                              )}
                              <td className="hide-on-print text-right">
                                <i className="fa fa-eye pointer text-primary"></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="hide-on-print p-3 pt-0">
                      <Pagination
                        length={invoices.data?.totalRecords || 0}
                        page={invPaging.page}
                        pageSize={invPaging.pageSize}
                        pageChange={invPageChange}
                        showingOfWhatLabel="Invoices"
                        sizes={[10, 15, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </div>
                )}
              </Tab>
              {!!allowInvoicePayment.data && (
                <Tab eventKey="OnlinePayments" title="Online Payments">
                  {onlinePayments.status === FetchStatus.InProgress && (
                    <CommonSpinner></CommonSpinner>
                  )}
                  {onlinePayments.status === FetchStatus.Complete && (
                    <div>
                      <div className="print-wrapper hide-on-print">
                        <a
                          href="/"
                          className="no-decoration no-wrap"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.print();
                          }}
                        >
                          <i className="fa fa-print"></i> Print
                        </a>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <strong className="show-on-print">
                          Online Payments
                        </strong>
                      </div>
                      <div
                        className="overflow-auto"
                        style={{
                          minHeight: "150px",
                        }}
                      >
                        <table className="table table-hover billing-table">
                          <thead>
                            <tr>
                              <th>Payment Date</th>
                              <th>Confirmation Number</th>
                              <th>Message</th>
                              <th className="text-right">Total Paid</th>
                              <th className="hide-on-print"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {onlinePayments?.data?.customerInvoicePaymentDtos?.map(
                              (item) => (
                                <tr
                                  key={item.sqlId}
                                  className={`pointer ${getOnlinePaymentClass(
                                    item.paymentInformation.returnMessage || ""
                                  )}`}
                                  onClick={() => {
                                    setOnlinePaymentToView({
                                      ...item.paymentInformation,
                                    });
                                  }}
                                >
                                  <td className="no-wrap">
                                    <span className="show-on-print">
                                      {commonService.toLocalDate(
                                        item.transactionDate,
                                        "MM-DD-YYYY"
                                      )}
                                    </span>
                                    <span className="hide-on-print">
                                      {commonService.toLocalDate(
                                        item.transactionDate
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {item.paymentInformation
                                      .confirmationNumber || ""}
                                  </td>
                                  <td>
                                    {item.paymentInformation.returnMessage ||
                                      ""}
                                  </td>
                                  <td className="text-right">
                                    {commonService.toMoney(
                                      item.paymentInformation.totalPaid || 0
                                    )}
                                  </td>
                                  <td className="hide-on-print text-right">
                                    <i className="fa fa-eye pointer text-primary"></i>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="hide-on-print p-3 pt-0">
                        <Pagination
                          length={onlinePayments.data?.totalRecords || 0}
                          page={onlinePayPaging.page}
                          pageSize={onlinePayPaging.pageSize}
                          pageChange={onlinePayPageChange}
                          showingOfWhatLabel="Online Payments"
                          sizes={[10, 15, 25, 50, 100]}
                        ></Pagination>
                      </div>
                    </div>
                  )}
                </Tab>
              )}
              <Tab eventKey="CreditMemos" title="Credit Memos">
                {memos.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                {memos.status === FetchStatus.Complete && (
                  <div>
                    <div className="print-wrapper hide-on-print">
                      <a
                        href="/"
                        className="no-decoration no-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i> Print
                      </a>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <strong className="show-on-print">Credit Memos</strong>
                    </div>
                    <div className="overflow-auto">
                      <table className="table table-hover billing-table">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Date</th>
                            <th className="text-right">Amount</th>
                            <th className="hide-on-print"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {memos?.data?.creditMemoGridItemDtos?.map((item) => (
                            <tr
                              key={item.sqlId}
                              className="pointer"
                              onClick={() => {
                                setMemoToView(item.sqlId);
                              }}
                            >
                              <td>{item.tranId}</td>
                              <td className="no-wrap">
                                <span className="show-on-print">
                                  {commonService.toDateString(
                                    item.tranDate,
                                    "MM-DD-YYYY"
                                  )}
                                </span>
                                <span className="hide-on-print">
                                  {commonService.toDateString(item.tranDate)}
                                </span>
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.tranAmount)}
                              </td>
                              <td className="hide-on-print text-right">
                                <i className="fa fa-eye pointer text-primary"></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="hide-on-print p-3 pt-0">
                      <Pagination
                        length={memos.data?.totalRecords || 0}
                        page={memoPaging.page}
                        pageSize={memoPaging.pageSize}
                        pageChange={memoPageChange}
                        showingOfWhatLabel="Memos"
                        sizes={[10, 15, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey="SpendBySource" title="Spend By Source">
                {spendBySource.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}

                <div>
                  <div
                    className={`print-wrapper hide-on-print flex flex-center flex-wrap gap-15 ${
                      spendBySource.status === FetchStatus.InProgress
                        ? "display-none"
                        : ""
                    }`}
                  >
                    <div className="flex">
                      <DateRangePicker
                        from={period.from}
                        to={period.to}
                        onChanged={(from, to) => {
                          setPeriod({ from: from, to: to });
                        }}
                        requireBothDates={true}
                        periods={[
                          DatePeriod.ThisYear,
                          DatePeriod.LastYear,
                          DatePeriod.ThisMonth,
                          DatePeriod.LastMonth,
                          DatePeriod.ThisWeek,
                          DatePeriod.LastWeek,
                          DatePeriod.Custom,
                        ]}
                      ></DateRangePicker>
                    </div>
                    <div className="flex-1 now-wrap p-2">
                      <a
                        href="/"
                        className="no-decoration no-wrap me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i> Print
                      </a>{" "}
                      <a
                        href="/"
                        className="no-decoration no-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          startDownload();
                        }}
                      >
                        <i className="fa fa-download"></i> Download CSV
                      </a>
                    </div>
                  </div>
                  {spendBySource.status === FetchStatus.Complete && (
                    <div className="overflow-auto">
                      <table className="table table-hover billing-table">
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th className="hide-on-print">Category</th>
                            <th className="hide-on-print no-wrap">
                              Farm{" "}
                              <a
                                className="no-decoration hide-on-print"
                                href="http://www.localveloccity.com/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Learn more...
                              </a>
                            </th>
                            <th className="hide-on-print">Description</th>
                            <th>UoM</th>
                            <th className="text-right no-wrap">Qty Sold</th>
                            <th className="text-right no-wrap">Weight</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right no-wrap">Avg. Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spendBySource?.data?.map((item, idx) => (
                            <tr key={`${item.itemCode}-${idx}`}>
                              <td>
                                <strong>{item.itemCode}</strong>
                                <div
                                  className="show-on-print"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></div>
                                {item.itemCategory && (
                                  <div className="show-on-print">
                                    <small>Category: {item.itemCategory}</small>
                                  </div>
                                )}
                                {item.farm && (
                                  <div className="show-on-print">
                                    <small>Farm: {item.farm}</small>
                                  </div>
                                )}
                              </td>
                              <td className="hide-on-print">
                                {item.itemCategory}
                              </td>
                              <td className="hide-on-print">{item.farm}</td>
                              <td
                                className="hide-on-print"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></td>
                              <td>{item.packTypeUom}</td>
                              <td className="text-right">
                                {commonService.toNumberWithComma(item.quantity)}
                              </td>
                              <td className="text-right no-wrap">
                                {commonService.toNumberWithComma(
                                  item.purchaseWeight,
                                  2
                                )}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.amount)}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.averagePrice)}
                              </td>
                            </tr>
                          ))}
                          {!!spendBySource?.data?.length && (
                            <tr>
                              <td colSpan={4}></td>
                              <td colSpan={1}>
                                <strong>Totals:</strong>
                              </td>
                              <td colSpan={1} className="text-right no-wrap">
                                <strong>
                                  {commonService.toNumberWithComma(
                                    spendBySource?.data
                                      ?.map((x) => x.quantity)
                                      ?.reduce((prev, a) => prev + a, 0)
                                  )}
                                </strong>
                              </td>
                              <td colSpan={1} className="text-right no-wrap">
                                <strong>
                                  {commonService.toNumberWithComma(
                                    spendBySource?.data
                                      ?.map((x) => x.purchaseWeight)
                                      ?.reduce((prev, a) => prev + a, 0),
                                    2
                                  )}
                                </strong>
                              </td>
                              <td colSpan={1} className="text-right no-wrap">
                                <strong>
                                  {commonService.toMoney(
                                    spendBySource?.data
                                      ?.map((x) => x.amount)
                                      ?.reduce((prev, a) => prev + a, 0)
                                  )}
                                </strong>
                              </td>
                              <td colSpan={1} className="text-right no-wrap">
                                <strong>
                                  {commonService.toMoney(
                                    spendBySource?.data
                                      ?.map((x) => x.averagePrice)
                                      ?.reduce((prev, a) => prev + a, 0) /
                                      spendBySource?.data?.length
                                  )}
                                </strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="SummaryGraph" title="Local Summary Graph">
                {summaryGraph.status === FetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                <div>
                  <div
                    className={`print-wrapper hide-on-print flex flex-center flex-wrap gap-15 ${
                      summaryGraph.status === FetchStatus.InProgress
                        ? "display-none"
                        : ""
                    }`}
                  >
                    <div className="flex">
                      <DateRangePicker
                        from={graphPeriod.from}
                        to={graphPeriod.to}
                        onChanged={(from, to) => {
                          setGraphPeriod({ from: from, to: to });
                        }}
                        requireBothDates={true}
                        periods={[
                          DatePeriod.ThisYear,
                          DatePeriod.LastYear,
                          DatePeriod.ThisMonth,
                          DatePeriod.LastMonth,
                          DatePeriod.ThisWeek,
                          DatePeriod.LastWeek,
                          DatePeriod.Custom,
                        ]}
                      ></DateRangePicker>
                    </div>
                    <div className="flex-1 now-wrap p-2">
                      <a
                        href="/"
                        className="no-decoration no-wrap me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i> Print
                      </a>
                    </div>
                  </div>
                  {summaryGraph.status === FetchStatus.Complete && (
                    <div
                      style={{ maxWidth: "400px" }}
                      className="chart-doughnut"
                    >
                      <Doughnut
                        data={{
                          labels: [
                            `Local Ordered: ${commonService.toMoney(
                              summaryGraph.data?.totalLocalAmount || 0
                            )}`,
                            `Total Ordered: ${commonService.toMoney(
                              summaryGraph.data?.totalOrderAmount || 0
                            )}`,
                          ],
                          datasets: [
                            {
                              label: "Orders",
                              data: [
                                summaryGraph.data?.totalLocalAmount || 0,
                                summaryGraph.data?.totalOrderAmount || 0,
                                getTotalOrder() === 0 ? 1 : 0,
                              ],
                              backgroundColor: ["#889362", "#4e9de6", "#ddd"],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        options={{
                          cutout: "70%",
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: (context) => {
                                  if (!context.label) {
                                    return "No Data";
                                  }
                                  const perc =
                                    Number(context.raw || 0) / getTotalOrder();
                                  return `${commonService.toNumberWithComma(
                                    perc * 100
                                  )}%`;
                                },
                              },
                            },
                          },
                        }}
                      ></Doughnut>
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingCenter;
