import SysModels from "../../models";
import fetcher from "../Fetcher";

export class CustomerUserService {
  login = async (data: SysModels.IUserLoginDto) => {
    const url = `/CustomerUser/Login`;
    return fetcher.post<any>(url, data, { notoken: true });
  };

  forgotPassword = async (data: SysModels.IForgotPasswordDto) => {
    const url = `/CustomerUser/ForgotPassword`;
    return fetcher.post<any>(url, data, { notoken: true });
  };

  resetPassword = async (data: SysModels.IResetPasswordDto) => {
    const url = `/CustomerUser/ResetPassword`;
    return fetcher.post<any>(url, data, { notoken: true });
  };

  changePassword = async (data: SysModels.IChangePasswordRequestDto) => {
    const url = `/CustomerUser/ChangePassword`;
    return fetcher.post<any>(url, data);
  };

  refreshToken = async (data: SysModels.IRefreshTokenDto) => {
    const url = `/CustomerUser/RefreshToken`;
    return fetcher.post<any>(url, data);
  };

  logout = async (sessionId: string) => {
    const url = `/CustomerUser/Logout/${sessionId}`;
    return fetcher.get<any>(url);
  };

  loginTwoFactor = async (data: SysModels.IUserTwoFactorLoginDto) => {
    const url = `/CustomerUser/LoginTwoFactor`;
    return fetcher.post<any>(url, data);
  };

  getTwoFactorSecret = async (data: SysModels.IPasswordDto) => {
    const url = `/CustomerUser/TwoFactorSecret`;
    return fetcher.post<SysModels.ICustomerUserTwoFactorSecretOutputDto>(
      url,
      data
    );
  };

  turnOffTwoFactor = async (data: SysModels.IPasswordDto) => {
    const url = `/CustomerUser/TurnOffTwoFactor`;
    return fetcher.post<any>(url, data);
  };

  acceptTwoFactor = async (code: string) => {
    const url = `/CustomerUser/AcceptTwoFactor/${code}`;
    return fetcher.get<any>(url);
  };

  askedAboutTwoFactor = async (userId: string) => {
    const url = `/CustomerUser/AskedAboutTwoFactor/${userId}`;
    return fetcher.get<any>(url);
  };
}

const customerUser = new CustomerUserService();
export default customerUser;
