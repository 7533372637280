import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import toastStore from "../../stores/ToastStore";
import DateTimePicker from "./DateTimePicker";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import SysModels from "../../models";
dayjs.extend(quarterOfYear);

export enum DatePeriod {
  All,
  Today,
  Tomorrow,
  Yesterday,
  ThisWeek,
  NextWeek,
  LastWeek,
  ThisMonth,
  NextMonth,
  LastMonth,
  ThisQuarter,
  LastQuarter,
  ThisYear,
  LastYear,
  Custom,
}

const DEFAULT_FORMAT = "YYYY-MM-DD";
const DatePeriodDefinitions = [
  {
    period: DatePeriod.All,
    label: "All",
    values: () => {
      return {
        from: null,
        to: null,
      };
    },
  },
  {
    period: DatePeriod.Today,
    label: "Today",
    values: () => {
      return {
        from: dayjs().format(DEFAULT_FORMAT),
        to: dayjs().format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.Tomorrow,
    label: "Tomorrow",
    values: () => {
      return {
        from: dayjs().add(1, "day").format(DEFAULT_FORMAT),
        to: dayjs().add(1, "day").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.Yesterday,
    label: "Yesterday",
    values: () => {
      return {
        from: dayjs().add(-1, "day").format(DEFAULT_FORMAT),
        to: dayjs().add(-1, "day").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.ThisWeek,
    label: "This Week",
    values: () => {
      return {
        from: dayjs().startOf("week").format(DEFAULT_FORMAT),
        to: dayjs().endOf("week").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.NextWeek,
    label: "Next Week",
    values: () => {
      return {
        from: dayjs().add(1, "week").startOf("week").format(DEFAULT_FORMAT),
        to: dayjs().add(1, "week").endOf("week").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.LastWeek,
    label: "Last Week",
    values: () => {
      return {
        from: dayjs().add(-1, "week").startOf("week").format(DEFAULT_FORMAT),
        to: dayjs().add(-1, "week").endOf("week").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.ThisMonth,
    label: "This Month",
    values: () => {
      return {
        from: dayjs().startOf("month").format(DEFAULT_FORMAT),
        to: dayjs().endOf("month").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.NextMonth,
    label: "Next Month",
    values: () => {
      return {
        from: dayjs().add(1, "month").startOf("month").format(DEFAULT_FORMAT),
        to: dayjs().add(1, "month").endOf("month").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.LastMonth,
    label: "Last Month",
    values: () => {
      return {
        from: dayjs().add(-1, "month").startOf("month").format(DEFAULT_FORMAT),
        to: dayjs().add(-1, "month").endOf("month").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.ThisQuarter,
    label: "This Quarter",
    values: () => {
      const qtr = dayjs().quarter() * 3;
      return {
        from: dayjs()
          .startOf("year")
          .add(qtr - 3, "month")
          .startOf("month")
          .format(DEFAULT_FORMAT),
        to: dayjs()
          .startOf("year")
          .add(qtr - 1, "month")
          .endOf("month")
          .format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.LastQuarter,
    label: "Last Quarter",
    values: () => {
      const qtr = dayjs().quarter() * 3;
      return {
        from: dayjs()
          .startOf("year")
          .add(qtr - 6, "month")
          .startOf("month")
          .format(DEFAULT_FORMAT),
        to: dayjs()
          .startOf("year")
          .add(qtr - 4, "month")
          .endOf("month")
          .format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.ThisYear,
    label: "This Year",
    values: () => {
      return {
        from: dayjs().startOf("year").format(DEFAULT_FORMAT),
        to: dayjs().endOf("year").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.LastYear,
    label: "Last Year",
    values: () => {
      return {
        from: dayjs().add(-1, "year").startOf("year").format(DEFAULT_FORMAT),
        to: dayjs().add(-1, "year").endOf("year").format(DEFAULT_FORMAT),
      };
    },
  },
  {
    period: DatePeriod.Custom,
    label: "Custom",
    values: () => {
      return {
        from: null,
        to: null,
      };
    },
  },
];

export const GetPeriodValues = (period: DatePeriod) => {
  const rtn = DatePeriodDefinitions.find((p) => p.period === period);
  return rtn ? rtn.values() : { from: null, to: null };
};

export const IsValidDate = (val: any) => {
  return !!val && val !== "Invalid Date" && dayjs(val).isValid();
};

function DateRangePicker(props: {
  from: string | null;
  to: string | null;
  onChanged: (
    from: string | null,
    to: string | null,
    period?: DatePeriod
  ) => void;
  periods: DatePeriod[];
  customPeriods?: SysModels.ILookupIntDto[];
  defaultPeriod?: DatePeriod;
  requireBothDates?: boolean;
  align?: "left" | "right" | undefined;
  hideCustom?: boolean;
  disabled?: boolean;
}) {
  const getDefaultPeriod = () => {
    if (props.defaultPeriod !== null && props.defaultPeriod !== undefined) {
      return props.defaultPeriod;
    }
    if (props.customPeriods?.length) {
      return props.customPeriods[0].value;
    }
    return !props.periods.length ? DatePeriod.Custom : props.periods[0];
  };
  const [period, setPeriod] = useState(getDefaultPeriod());

  const periodClassName = (val: DatePeriod) => {
    return period === val ? "primary" : "light";
  };
  const [dateRange, setDateRange] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: props.from, to: props.to });

  const [show, setShow] = useState(false);
  const getCustomDateDisplay = () => {
    return `${
      props.from
        ? `<small class="alert alert-primary p-0 px-1">
        ${dayjs(props.from).format("MMM DD, YYYY")}</small>`
        : `<small class="alert alert-secondary p-0 px-1">MM/DD/YYYY</small>`
    }
    <span class="px-1">to</span>  
    ${
      props.to
        ? `<small class="alert alert-primary p-0 px-1">
        ${dayjs(props.to).format("MMM DD, YYYY")}</small>`
        : `<small class="alert alert-secondary p-0 px-1">MM/DD/YYYY</small>`
    }`;
  };

  const [disableApply, setDisableApply] = useState(true);
  const isDisableApply = () => {
    if (period !== DatePeriod.Custom) {
      return true;
    }
    if (period === DatePeriod.Custom) {
      if (props.requireBothDates) {
        return !IsValidDate(dateRange.from) || !IsValidDate(dateRange.to);
      }
      if (dateRange.from) {
        return !IsValidDate(dateRange.from);
      }
      if (dateRange.to) {
        return !IsValidDate(dateRange.to);
      }
    }
    return false;
  };
  useEffect(() => {
    setDisableApply(isDisableApply());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const GetAllDateRangeDefinitions = () => {
    return [
      ...(props.customPeriods || []).map((p) => {
        return {
          period: p.value,
          label: p.label,
          other: true,
          values: () => {
            return {
              from: null,
              to: null,
            };
          },
        };
      }),
      ...DatePeriodDefinitions.map((d) => {
        return {
          ...d,
          period: Number(d.period),
          other: false,
        };
      }),
    ];
  };

  return (
    <div className="bg-white dataRangpicker">
      <Dropdown
        show={show}
        onToggle={(isOpen) => {
          setShow(isOpen);
        }}
      >
        <Dropdown.Toggle variant="outline-primary" disabled={props.disabled}>
          <i className="fa fa-calendar me-2"></i>
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html:
                period === DatePeriod.Custom
                  ? getCustomDateDisplay()
                  : GetAllDateRangeDefinitions().find(
                      (p) => p.period === period
                    )?.label || "",
            }}
          ></span>
        </Dropdown.Toggle>

        <Dropdown.Menu align={props.align}>
          {[...props.periods, ...(props.customPeriods || [])].length > 1 && (
            <div className="flex flex-column gap-10 p-2">
              {GetAllDateRangeDefinitions()
                .filter((p) => {
                  if (
                    props.periods.indexOf(p.period) > -1 ||
                    (p.other &&
                      !!props.customPeriods?.find((x) => x.value === p.period))
                  ) {
                    return true;
                  }
                  return p.period === DatePeriod.Custom && !props.hideCustom;
                })
                .map((p) => (
                  <Button
                    key={`${p.period}-${p.other}`}
                    size="sm"
                    variant={periodClassName(p.period)}
                    onClick={() => {
                      if (p.period === DatePeriod.Custom) {
                        setPeriod(DatePeriod.Custom);
                        return;
                      }
                      setPeriod(p.period);
                      setShow(false);
                      const vals = p.values();
                      props.onChanged(vals.from, vals.to, p.period);
                    }}
                  >
                    {p.label}
                  </Button>
                ))}
            </div>
          )}

          <div
            className={`flex gap-10 p-2 ${
              props.hideCustom ? "display-none" : ""
            }`}
          >
            <div className="flex-0">
              <DateTimePicker
                data={props.from}
                onChange={(data) => {
                  setDateRange((rtn) => {
                    return {
                      ...rtn,
                      from: data ? dayjs(data).format(DEFAULT_FORMAT) : null,
                    };
                  });
                }}
                dateOnly={true}
                disabled={period !== DatePeriod.Custom}
              ></DateTimePicker>
            </div>
            <div className="flex-0">
              <DateTimePicker
                data={props.to}
                onChange={(data) => {
                  setDateRange((rtn) => {
                    return {
                      ...rtn,
                      to: data ? dayjs(data).format(DEFAULT_FORMAT) : null,
                    };
                  });
                }}
                dateOnly={true}
                disabled={period !== DatePeriod.Custom}
              ></DateTimePicker>
            </div>
            <Button
              className="flex-1"
              disabled={disableApply}
              size="sm"
              variant="success"
              onClick={() => {
                if (
                  dateRange.from &&
                  dateRange.to &&
                  dayjs(dateRange.from).isAfter(dateRange.to)
                ) {
                  toastStore.showToast(
                    "Start date cannot be greater than End date",
                    "warning"
                  );
                  return;
                }

                props.onChanged(
                  dateRange.from,
                  dateRange.to,
                  DatePeriod.Custom
                );
                setShow(false);
              }}
            >
              Apply
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DateRangePicker;
