import React, { useEffect } from "react";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import FormModal from "../../components/common/FormModal";
import CommonSpinner from "../../components/common/CommonSpinner";
import commonService from "../../services/CommonService";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";

function OrderGuideUpdateHistoryDialog(props: {
  customerId: string;
  close: () => void;
}) {
  const list = useFetchHelper(
    async () =>
      SysServices.http.customer.orderGuideHistoryUpdate(props.customerId),
    "History"
  );

  useEffect(() => {
    list.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title={`Order Guide History for ${props.customerId}`}
      size="lg"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        props.close();
      }}
      primaryButtonOnly={true}
      customBodyFooter={true}
    >
      <ModalBody>
        {list.status === FetchStatus.InProgress && (
          <div className="text-center p-4">
            <CommonSpinner></CommonSpinner>
          </div>
        )}
        {list.status === FetchStatus.Complete && (
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>Date Requested</th>
                <th>Date Processed</th>
                <th>Requestor</th>
              </tr>
            </thead>
            <tbody>
              {!list.data?.length && (
                <tr>
                  <td colSpan={3} className="text-center">
                    No Record(s) Found
                  </td>
                </tr>
              )}
              {list.data?.map((row, i) => (
                <tr key={i}>
                  <td>
                    {commonService.toLocalDate(row.dateRequested, "full")}
                  </td>
                  <td>
                    {commonService.toLocalDate(row.dateProcessed, "full")}
                  </td>
                  <td>{row.requestor}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => {
            props.close();
          }}
          variant="secondary"
        >
          Close
        </Button>
      </ModalFooter>
    </FormModal>
  );
}

export default OrderGuideUpdateHistoryDialog;
