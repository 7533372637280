import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class SalesService {
  agingSummary = async (customerNumber?: string) => {
    let url = `/Sales/AgingSummary`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.IAgingSummaryDto>(url);
  };

  salesTeam = async () => {
    let url = `/Sales/SalesTeam`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.ICustomerSalesPersonDto[]>(url);
  };

  listSalesOrders = async (
    page: number,
    pageSize: number,
    dto: SysModels.ISalesOrderGridInputDto
  ) => {
    let url = `/Sales/SalesOrders/${page}/${pageSize}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.ISalesOrderGridDto>(url, dto);
  };

  getOrderGuideOrder = async (id: any, customerId?: string) => {
    let url = `/Sales/OrderGuideOrder/${id}`;
    if (customerId) {
      url = `${url}/${customerId}`;
    } else if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.IOrderOutputDto>(url);
  };

  getNsOrder = async (id: any, customerNumber?: string) => {
    let url = `/Sales/NsOrder/${id}`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.IOrderOutputDto>(url);
  };

  listInvoices = async (
    page: number,
    pageSize: number,
    dto: SysModels.ISalesOrderGridInputDto
  ) => {
    let url = `/Sales/Invoices/${page}/${pageSize}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.IInvoiceGridForCustomerSiteDto>(url, dto);
  };

  getInvoice = async (id: any, customerNumber?: string) => {
    let url = `/Sales/Invoice/${id}`;
    if (customerNumber || systemStore.currentCustomer) {
      url = `${url}/${customerNumber || systemStore.currentCustomer?.number}`;
    }
    return fetcher.get<SysModels.IInvoiceOutputDto>(url);
  };

  listCreditMemos = async (
    page: number,
    pageSize: number,
    dto: SysModels.ISalesOrderGridInputDto
  ) => {
    let url = `/Sales/CreditMemos/${page}/${pageSize}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.ICreditMemoGridDto>(url, dto);
  };

  getCreditMemo = async (id: any, customerNumber?: string) => {
    let url = `/Sales/CreditMemo/${id}`;
    if (customerNumber || systemStore.currentCustomer) {
      url = `${url}/${customerNumber || systemStore.currentCustomer?.number}`;
    }
    return fetcher.get<SysModels.ICreditMemoOutputDto>(url);
  };

  getLocalSpendBySource = async (fromDate: any, toDate: any) => {
    let url = `/Sales/LocalSpendBySource/${fromDate}/${toDate}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<SysModels.ILocalSpendBySourceReportOutputDto[]>(url);
  };

  getLocalSpendBySourceDownload = async (fromDate: any, toDate: any) => {
    let url = `/Sales/LocalSpendBySourceDownload/${fromDate}/${toDate}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  localVsAllComparison = async (data: SysModels.ISalesLocalSearchDto) => {
    let url = `/Sales/LocalVsAllComparison`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.ISalesLocalVsOutputDto>(url, data);
  };

  sendOrderGuideOrderConfirmationEmail = async (id: any) => {
    let url = `/Sales/SendOrderGuideOrderConfirmationEmail/${id}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<any>(url);
  };

  sendSalesOrderConfirmationEmail = async (id: any) => {
    let url = `/Sales/SendSalesOrderConfirmationEmail/${id}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.get<any>(url);
  };

  sendSupportEmail = async (data: SysModels.IContactUsInputDto) => {
    let url = `/Sales/SendSupportEmail`;
    return fetcher.post<any>(url, data);
  };

  salesRepProjection = async (data: SysModels.ISalesRepProjectionSearchDto) => {
    let url = `/Sales/SalesRepProjection`;
    return fetcher.post<SysModels.ISalesRepProjectionGridOutputDto>(url, data);
  };
}

const sales = new SalesService();
export default sales;
