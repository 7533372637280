import React from "react";
import { useSiteDown } from "../stores/SystemStore";
import dayjs from "dayjs";

function SiteDownPage(props: any) {
  const siteDown = useSiteDown();

  return (
    <div
      id="site-down"
      className="login-page"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="MWF Splash" src="img/midwestsplash.png" />
        <div>
          <div
            style={{
              maxWidth: "500px",
            }}
          >
            <i className="fa fa-warning fa-2x text-danger"></i>
            <h5
              className="pt-2 text-danger"
              dangerouslySetInnerHTML={{
                __html: siteDown?.globalMessage || "Loading...",
              }}
            ></h5>
            <div>
              Until{" "}
              {dayjs.utc(siteDown?.endDate).format("MMMM DD, YYYY hh:mm A")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteDownPage;
