import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";

function PrintGuide(props: any) {
  const grid = useFetchHelper(
    SysServices.http.order.getNewOrderGuide,
    "Order Guide"
  );
  const customer = useFetchHelper(SysServices.http.customer.get, "Customer");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    grid.getData();
    customer.getData();
  }, [calledOnce, grid, customer]);

  const [downloading, setDownloading] = useState(false);

  const startDownload = async () => {
    setDownloading(true);
    await SysServices.http.order
      .getNewOrderGuideForDownload()
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  return (
    <div className="default-page-layout print-guide">
      {downloading && (
        <CommonSpinner overlay={true} message="Downloading..."></CommonSpinner>
      )}

      <h4 className="hide-on-print">Print Guide</h4>
      <div className="row">
        <div className="col-sm-12 mb-4 hide-on-print">
          {customer.status === FetchStatus.Complete ? (
            <div>
              <span className="me-3">
                {customer.data?.customerName} (
                {customer.data?.erpCustomerNumber})
              </span>
              {grid.status === FetchStatus.Complete && (
                <>
                  <a
                    href="/"
                    className="no-decoration no-wrap me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.print();
                    }}
                  >
                    <i className="fa fa-print"></i> Print
                  </a>
                  <a
                    href="/"
                    className="no-decoration no-wrap"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      startDownload();
                    }}
                  >
                    <i className="fa fa-download"></i> Download CSV
                  </a>
                </>
              )}
            </div>
          ) : (
            "Loading..."
          )}
        </div>
        {grid.status === FetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}
        {grid.status === FetchStatus.Complete && (
          <div className="col-sm-12">
            <table className="table" id="print-guide-table">
              <thead>
                <tr>
                  <th className="hide-on-print">Item</th>
                  <th>Description</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">UoM</th>
                  <th className="text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                {grid.data?.orderGuideGridItemDtos?.map((item) => (
                  <tr key={item.itemCode}>
                    <td className="hide-on-print">{item.itemCode}</td>
                    <td>
                      <strong className="show-on-print">{item.itemCode}</strong>
                      <div>{item.itemDescription}</div>
                    </td>
                    <td className="text-center">
                      {item.quantity
                        ? commonService.toNumberWithComma(item.quantity)
                        : ""}
                    </td>
                    <td className="text-center no-wrap">{item.uom}</td>
                    <td className="text-right">
                      {commonService.toMoney(item.unitPrice, "$")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default PrintGuide;
