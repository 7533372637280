import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { useLHSCategories } from "../stores/SystemStore";
import CatalogItem from "./catalog/CatalogItem";
import { useParams } from "react-router-dom";

function ProductCatalog(props: any) {
  const params = useParams();
  const [categoryId, setCategoryId] = useState("");
  const [featured, setFeatured] = useState(false);

  useEffect(() => {
    setCategoryId(params.category || "");
    setFeatured(params?.featured === "featured");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [paging, setPaging] = usePaging(1, 50, search);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const items = useFetchHelper(async () => {
    if (featured) {
      return SysServices.http.dashboardAnnouncement.leftHandSideFeaturedItemsForDisplay(
        categoryId
      );
    }
    return SysServices.http.catalog.listProducts(paging.page, paging.pageSize, {
      categoryId: categoryId,
      search: search.used,
      orderByEnum: SysModels.OrderByEnum.Ascending,
    });
  }, "Products");

  // const [categoryName, setCategoryName] = useState<string>();
  // useEffect(() => {
  //   if (categoryId) {
  //     //---
  //   } else {
  //     setCategoryName(undefined);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categoryId, featured]);

  useEffect(() => {
    if (items.status !== FetchStatus.InProgress) {
      items.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  useEffect(() => {
    setPaging((data) => {
      return {
        ...data,
        page: 1,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.used]);

  useEffect(() => {
    setSearch((data) => {
      return { typed: "", used: "" };
    });
    setPaging((data) => {
      return {
        ...data,
        page: 1,
        typed: "",
        used: "",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, featured]);

  const lhsCategories = useLHSCategories();

  return (
    <div className="default-page-layout product-catalog">
      <h4 className="mb-4">
        {featured &&
          items.status === FetchStatus.Complete &&
          (items.data as SysModels.IDashboardAnnouncementForDisplayDto)?.title}
        {!featured &&
          lhsCategories.length > 0 &&
          (lhsCategories.find((c) => c.id === categoryId)?.description ||
            "Product Catalog")}
      </h4>

      {!featured && items.status !== FetchStatus.InProgress && (
        <div className="flex pb-3">
          <div className="flex-0" style={{ maxWidth: "100%" }}>
            <div className="input-group search-box">
              <input
                autoFocus={true}
                className="form-control"
                type="text"
                placeholder="Search Product"
                value={search.typed}
                onChange={(e) => {
                  setSearch((data) => {
                    return {
                      ...data,
                      typed: e.target.value,
                    };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearch((data) => {
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                  }
                }}
              ></input>
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      return { typed: "", used: "" };
                    });
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {items.status === FetchStatus.InProgress && (
          <CommonSpinner message="Getting Products"></CommonSpinner>
        )}
        {items.status === FetchStatus.Complete && (
          <div className="catalog-items">
            {items.data?.erpInventoryItemOutputDtos?.map((item) => (
              <CatalogItem key={item.sqlId} item={item}></CatalogItem>
            ))}

            {!featured &&
              Math.ceil(
                ((items.data as any)?.totalRecords || 0) / paging.pageSize
              ) !== paging.page && (
                <div
                  className="catalog-item catalog-item-next"
                  onClick={(e) => {
                    pageChange(paging.page + 1, paging.pageSize);
                  }}
                >
                  <div>
                    <h4 className="text-primary">View More</h4>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>

      {!featured && items.status !== FetchStatus.InProgress && (
        <div className="p-3 pt-0 px-0">
          <Pagination
            length={(items.data as any)?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="items"
            sizes={[10, 25, 50, 100]}
          ></Pagination>
        </div>
      )}
    </div>
  );
}

export default ProductCatalog;
