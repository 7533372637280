import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CategoriesMenu from "../components/layout-common/CategoriesMenu";
import systemStore, { useSavedCartItems } from "../stores/SystemStore";
import { reaction } from "mobx";

function SideNav(props: { toggle: () => void }) {
  const savedCartItems = useSavedCartItems();
  const [customerInfo, setCustomerInfo] = useState(systemStore.customerInfo);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.customerInfo,
      (val, prevVal, r) => {
        setCustomerInfo(val);
      }
    );

    return () => {
      disposer();
      //clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="side-nav-panel">
        <div>
          <strong>Main Menu</strong>
        </div>

        <ul className="navbar-nav">
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/dashboard"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-landscape"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/billing-center"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-book"></i>
              <span>Billing Center</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/customer-service"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-flag"></i>
              <span>Customer Service</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/how-to"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-help"></i>
              <span>How To</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/releasenotes"
              onClick={props.toggle}
            >
              <i className="fa fa-book"></i>
              <span>Release Notes</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/Search"
              onClick={props.toggle}
            >
              <i className="fa fa-search"></i>
              <span>Global Search</span>
            </NavLink>
          </li>
        </ul>
        <hr className="light-grey-hr mb-10" />
        <div>
          <strong>Sales &amp; Ordering</strong>
        </div>
        <ul className="navbar-nav">
          {customerInfo?.orderGuideAllowedYn && (
            <li className="navbar-nav">
              <NavLink
                //exact
                className="nav-link"
                //activeClassName="active"
                to="/order-guide"
                onClick={props.toggle}
              >
                <i className="zmdi zmdi-collection-text"></i>
                <span>
                  Place an Order{" "}
                  {savedCartItems && savedCartItems?.items?.length > 0 && (
                    <strong
                      className="alert alert-primary p-0 m-0 px-2 pull-right"
                      style={{ border: "none" }}
                    >
                      <small>{savedCartItems?.items?.length}</small>
                    </strong>
                  )}
                </span>
              </NavLink>
            </li>
          )}
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/my-orders"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-truck"></i>
              <span>My Orders</span>
            </NavLink>
          </li>
          <li className="navbar-nav">
            <NavLink
              //exact
              className="nav-link"
              //activeClassName="active"
              to="/catalog"
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-shopping-cart"></i>
              <span>Product Catalog</span>
            </NavLink>
          </li>
        </ul>
        <hr className="light-grey-hr mb-10" />
        <div>
          <strong>Popular Categories</strong>
        </div>
        <CategoriesMenu></CategoriesMenu>
      </div>
    </>
  );
}

export default SideNav;
