import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore, { useWindowSize } from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";

function InvoiceViewDialog(props: {
  onClose: () => void;
  invoiceId: any;
  customerNumber?: string;
}) {
  const invoiceDetail = useFetchHelper(async () => {
    return SysServices.http.sales.getInvoice(
      props.invoiceId,
      commonService.isEmployeeSite ? props.customerNumber : undefined
    );
  }, "Invoice");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    invoiceDetail.getData();
  }, [calledOnce, invoiceDetail]);

  const [isSwitching, setIsSwitching] = useState(false);
  const windowSize = useWindowSize();

  return (
    <>
      <FormModal
        title={"View Invoice"}
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
      >
        {isSwitching && (
          <CommonSpinner
            overlay={true}
            message="Switching to Customer..."
          ></CommonSpinner>
        )}
        <ModalBody>
          {invoiceDetail.status === FetchStatus.InProgress && (
            <CommonSpinner message="Getting Invoice Detail"></CommonSpinner>
          )}
          {invoiceDetail.status === FetchStatus.Failed && (
            <div className="text-center p-4">
              <span className="text-danger">Failed Getting Invoice Detail</span>
              <br />
              <span
                className="text-primary pointer"
                onClick={() => invoiceDetail.getData()}
              >
                Try Again
              </span>
            </div>
          )}
          {invoiceDetail.status === FetchStatus.Complete &&
            invoiceDetail.data && (
              <>
                <div className="row pb-3 view-invoice-header">
                  <div className="col-sm-12 col-md-6 col-lg-4 pb-3">
                    <table className="no-format">
                      <tbody>
                        <tr>
                          <td className="pe-2 now-wrap">
                            <strong>Customer:</strong>
                          </td>
                          <td>
                            {commonService.isEmployeeSite ? (
                              <span
                                className="text-primary pointer"
                                onClick={(e) => {
                                  setIsSwitching(true);
                                  SysServices.http.customer
                                    .get(invoiceDetail.data?.erpCustomerNumber)
                                    .then((data) => {
                                      systemStore.setCurrentCustomer({
                                        name: data.customerName,
                                        number: data.erpCustomerNumber,
                                        id: data.sqlId,
                                        data: data,
                                      });
                                      props.onClose();
                                    })
                                    .catch((error) => {
                                      toastStore.showError(
                                        "Failed Getting Customer",
                                        error
                                      );
                                      setIsSwitching(false);
                                    });
                                }}
                              >
                                {invoiceDetail.data?.customerName} (
                                {invoiceDetail.data?.erpCustomerNumber})
                              </span>
                            ) : (
                              <span>
                                {invoiceDetail.data?.customerName} (
                                {invoiceDetail.data?.erpCustomerNumber})
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 now-wrap">
                            <strong>Invoice Number:</strong>
                          </td>
                          <td>{invoiceDetail.data?.tranId}</td>
                        </tr>

                        <tr>
                          <td className="pe-2">
                            <strong>Due Date:</strong>
                          </td>
                          <td>
                            {invoiceDetail.data?.dueDate &&
                              commonService.toLocalDate(
                                invoiceDetail.data?.dueDate,
                                "date"
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2">
                            <strong>Days Overdue:</strong>
                          </td>
                          <td>
                            {commonService.toNumberWithComma(
                              invoiceDetail.data?.daysOverDue
                            )}
                          </td>
                        </tr>
                        <tr className="show-on-print">
                          <td className="pe-2">
                            <strong>Transaction Amount:</strong>
                          </td>
                          <td>
                            <strong>
                              {commonService.toMoney(
                                invoiceDetail.data?.tranAmount
                              )}
                            </strong>
                          </td>
                        </tr>
                        <tr className="show-on-print">
                          <td className="pe-2">
                            <strong>Paid Amount:</strong>
                          </td>
                          <td>
                            {commonService.toMoney(
                              invoiceDetail.data?.paidAmount
                            )}
                          </td>
                        </tr>
                        <tr className="show-on-print">
                          <td className="pe-2">
                            <strong>Remaining Amount:</strong>
                          </td>
                          <td>
                            <strong className="text-danger">
                              {commonService.toMoney(
                                invoiceDetail.data?.amountRemaining
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 pb-3 hide-on-print">
                    <table className="no-format w-100">
                      <tbody>
                        <tr>
                          <td className="pe-2">
                            <strong>Transaction Amount:</strong>
                          </td>
                          <td className="text-right">
                            <strong>
                              {commonService.toMoney(
                                invoiceDetail.data?.tranAmount
                              )}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2">
                            <strong>Paid Amount:</strong>
                          </td>
                          <td className="text-right">
                            {commonService.toMoney(
                              invoiceDetail.data?.paidAmount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2">
                            <strong>Remaining Amount:</strong>
                          </td>
                          <td className="text-right">
                            <strong className="text-danger">
                              {commonService.toMoney(
                                invoiceDetail.data?.amountRemaining
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 pb-3">
                    {!!invoiceDetail?.data?.invoiceAddressDto && (
                      <div>
                        <div>
                          <img src="/mw1.png" alt="MWF"></img>
                        </div>
                        <div className="py-2">
                          <div>
                            {invoiceDetail?.data?.invoiceAddressDto?.name}
                          </div>
                          <div>
                            {invoiceDetail?.data?.invoiceAddressDto?.address}{" "}
                            {invoiceDetail?.data?.invoiceAddressDto?.city}
                            {", "}
                            {invoiceDetail?.data?.invoiceAddressDto?.state}{" "}
                            {invoiceDetail?.data?.invoiceAddressDto?.zip}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <table className="table table-striped table-sm w-100 bg-white">
                  <thead>
                    <tr>
                      {windowSize.isSmall ? (
                        <>
                          <th>Item</th>
                          <th>Price</th>
                        </>
                      ) : (
                        <>
                          <th>Item</th>
                          <th>Description</th>
                          <th className="text-center">Quantity</th>
                          <th> UOM</th>
                          <th className="text-right">Price</th>
                          <th className="text-right">Total</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {(invoiceDetail.data?.orderLineOutputDtos || []).map(
                      (item) => (
                        <tr key={item.itemCode}>
                          {windowSize.isSmall ? (
                            <>
                              <td className="pe-4">
                                <div className="no-wrap">{item.itemCode}</div>
                                {item.itemDescription}
                                <div className="txt-grey">
                                  <small>{item.category}</small>
                                </div>
                              </td>
                              <td>
                                <table className="table-sm">
                                  <tbody>
                                    <tr>
                                      <td className="text-left">
                                        <small>QTY:</small>
                                      </td>
                                      <td className="text-right">
                                        {item.quantity.toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left">
                                        <small>UOM:</small>
                                      </td>
                                      <td className="text-right">{item.uom}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-left">
                                        <small>Price:</small>
                                      </td>
                                      <td className="text-right">
                                        {commonService.toMoney(
                                          item.unitPrice || 0
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left">
                                        <small>Total:</small>
                                      </td>
                                      <td className="text-right">
                                        {commonService.toMoney(
                                          item.totalPrice || 0
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="no-wrap">{item.itemCode}</td>
                              <td>
                                {item.itemDescription}
                                <div className="txt-grey">
                                  <small>{item.category}</small>
                                </div>
                              </td>
                              <td className="text-center">
                                {item.quantity.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>{item.uom}</td>
                              <td className="text-right">
                                {commonService.toMoney(item.unitPrice || 0)}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.totalPrice || 0)}
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </>
            )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>
          <Button
            type="button"
            onClick={() => {
              window.print();
            }}
            variant="primary"
            size="sm"
            disabled={invoiceDetail.status !== FetchStatus.Complete}
          >
            <i className="fa fa-print"></i> Print
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default InvoiceViewDialog;
