import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class CustomerInvoicePaymentService {
  allowInvoicePayment = async () => {
    const url = `/CustomerInvoicePayment/AllowInvoicePayment`;
    return fetcher.get<boolean>(url);
  };

  list = async (page: number, pageSize: number, customerNumber?: string) => {
    let url = `/CustomerInvoicePayment/${page}/${pageSize}`;
    if (customerNumber) {
      url = `${url}/${encodeURIComponent(customerNumber)}`;
    } else {
      if (systemStore.currentCustomer) {
        url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
      }
    }
    return fetcher.get<SysModels.ICustomerInvoicePaymentGridDto>(url);
  };
}

const customerInvoicePayment = new CustomerInvoicePaymentService();
export default customerInvoicePayment;
