import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import Pagination, { usePaging } from "../components/common/Pagination";
import OrderDetailDialog from "./orders/OrderDetailDialog";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import systemStore, {
  useLHSCategories,
  useSavedCartItems,
} from "../stores/SystemStore";
import { reaction } from "mobx";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import PaymentStatusBadge from "../components/common/PaymentStatusBadge";
import TableRow from "../components/common/TableRow";
import ColumnHeaderSortComponent from "../components/common/ColumnHeaderSortComponent";
import DateRangePicker, {
  DatePeriod,
} from "../components/common/DateRangePicker";
import dayjs from "dayjs";
import UseAsTemplateConfirmation from "./orders/UseAsTemplateConfirmation";
import OrderSummaryWidget from "./widgets/OrderSummaryWidget";

function MyOrders(props: any) {
  const navigate = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [filter, setFilter] = useState({
    salesOrderGridOrderByEnum: SysModels.SalesOrderGridOrderByEnum.OrderDate,
    orderByEnum: SysModels.OrderByEnum.Descending,
    fromDate: null as any,
    toDate: null as any,
    search: "",
  } as SysModels.ISalesOrderGridInputDto);
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });

  const grid = useFetchHelper<SysModels.ISalesOrderGridDto>(
    () =>
      SysServices.http.sales.listSalesOrders(paging.page, paging.pageSize, {
        ...filter,
        search: search.used,
        fromDate: period.from
          ? dayjs(period.from).startOf("day").toDate()
          : (null as any),
        toDate: period.to
          ? dayjs(period.to).endOf("day").toDate()
          : (null as any),
      }),
    "Orders"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, period, filter]);

  const pageOptions = [10, 25, 50, 100];
  const [reportOrder, setReportOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] =
    useState<SysModels.ISalesOrderGridItemDto>();

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
  }, []);
  const [showSpinnerMessage, setShowSpinnerMessage] = useState<string>();
  const [useTempConfirm, setUseTempConfirm] = useState<{
    id: number;
    netSuite: boolean;
  }>();
  const savedCartItems = useSavedCartItems();

  const Actions = ({
    item,
    i,
  }: {
    i: number;
    item: SysModels.ISalesOrderGridItemDto;
  }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          size="sm"
          id={`dropdownProfile${i}${item.webOrderNumber}`}
          variant="outline-primary"
        >
          <i className="fa fa-bars"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu align="right">
          <Dropdown.Item
            onClick={() => {
              setSelectedOrder(item);
              setReportOrder(false);
            }}
          >
            View Detail
          </Dropdown.Item>
          {systemStore.allowOrderGuide && (
            <Dropdown.Item
              onClick={() => {
                if (item.portalSqlId || item.nsSqlId) {
                  if (savedCartItems && savedCartItems.items?.length > 0) {
                    setUseTempConfirm({
                      id: item.nsSqlId ? item.nsSqlId : item.portalSqlId,
                      netSuite: !!item.nsSqlId,
                    });
                  } else {
                    systemStore.setOrderGuideTemplate(
                      item.nsSqlId ? item.nsSqlId : item.portalSqlId,
                      !!item.nsSqlId
                    );
                    navigate("/order-guide");
                  }
                }
              }}
            >
              Use as Template
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              setShowSpinnerMessage("Sending Email...");
              (item.nsSqlId
                ? SysServices.http.sales.sendSalesOrderConfirmationEmail(
                    item.nsSqlId
                  )
                : SysServices.http.sales.sendOrderGuideOrderConfirmationEmail(
                    item.portalSqlId
                  )
              )
                .then(() => {
                  setShowSpinnerMessage(undefined);
                  toastStore.showToast("Email Sent.", "success");
                })
                .catch((error) => {
                  setShowSpinnerMessage(undefined);
                  toastStore.showError("Failed Sending Email", error);
                });
            }}
          >
            Email Me a Copy
          </Dropdown.Item>
          {!commonService.isEmployeeSite && (
            <>
              <Dropdown.Item
                onClick={() => {
                  setSelectedOrder(item);
                  setReportOrder(true);
                }}
              >
                Report a Problem
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const SortPlacedOn = () => {
    return (
      <ColumnHeaderSortComponent
        name="Placed On"
        mode={
          filter.salesOrderGridOrderByEnum ===
          SysModels.SalesOrderGridOrderByEnum.OrderDate
            ? filter.orderByEnum
            : null
        }
        onClick={(mode) => {
          if (mode === null) {
            setFilter((p) => {
              return {
                ...p,
                salesOrderGridOrderByEnum:
                  SysModels.SalesOrderGridOrderByEnum.OrderDate,
              };
            });
          } else {
            setFilter((p) => {
              return {
                ...p,
                orderByEnum: p.orderByEnum ? 0 : 1,
              };
            });
          }
        }}
      ></ColumnHeaderSortComponent>
    );
  };

  const SortDeliveredOn = () => {
    return (
      <ColumnHeaderSortComponent
        name="Delivery Date"
        mode={
          filter.salesOrderGridOrderByEnum ===
          SysModels.SalesOrderGridOrderByEnum.DeliveryDate
            ? filter.orderByEnum
            : null
        }
        onClick={(mode) => {
          if (mode === null) {
            setFilter((p) => {
              return {
                ...p,
                salesOrderGridOrderByEnum:
                  SysModels.SalesOrderGridOrderByEnum.DeliveryDate,
              };
            });
          } else {
            setFilter((p) => {
              return {
                ...p,
                orderByEnum: p.orderByEnum ? 0 : 1,
              };
            });
          }
        }}
      ></ColumnHeaderSortComponent>
    );
  };

  const [orderSummaryLoaded, setOrderSummaryLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("OrderList");
  const categs = useLHSCategories();

  return (
    <>
      {!!useTempConfirm && (
        <UseAsTemplateConfirmation
          onClose={(rtn) => {
            if (rtn === "append" || rtn === "clear") {
              systemStore.setOrderGuideTemplate(
                useTempConfirm.id,
                useTempConfirm.netSuite
              );
              navigate("/order-guide");
            }
            setUseTempConfirm(undefined);
          }}
        ></UseAsTemplateConfirmation>
      )}
      {!!showSpinnerMessage && (
        <CommonSpinner
          overlay={true}
          message={showSpinnerMessage}
        ></CommonSpinner>
      )}
      {selectedOrder && (
        <OrderDetailDialog
          order={selectedOrder}
          onClose={() => setSelectedOrder(undefined)}
          reportProblem={reportOrder}
        ></OrderDetailDialog>
      )}

      <div className="default-page-layout">
        <h4>
          {commonService.isEmployeeSite ? "Customer Orders" : "My Orders"}
        </h4>
      </div>

      <Tabs
        activeKey={activeTab}
        transition={false}
        onSelect={(tab) => {
          tab && setActiveTab(tab);
          if (!orderSummaryLoaded && tab === "OrderSummary") {
            setOrderSummaryLoaded(true);
          }
        }}
      >
        <Tab eventKey="OrderList" title="Order List">
          <div className="p-2 py-1 bg-white">
            {grid.status === FetchStatus.InProgress && (
              <div>
                <CommonSpinner message="Getting Orders"></CommonSpinner>
              </div>
            )}
            {grid.status === FetchStatus.Failed && (
              <div className="bg-white p-3 text-danger">
                <i className="fa fa-warning"></i> Failed getting orders.
              </div>
            )}

            {grid.status === FetchStatus.Complete && (
              <div className="bg-white">
                <div className="p-3 flex flex-wrap gap-10">
                  <div className="flex-0 w-100">
                    <div className="input-group search-box">
                      <input
                        autoFocus={true}
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={search.typed}
                        onChange={(e) => {
                          setSearch((data) => {
                            return {
                              ...data,
                              typed: e.target.value,
                            };
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }
                        }}
                      ></input>
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              return { typed: "", used: "" };
                            });
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <DateRangePicker
                      from={period.from}
                      to={period.to}
                      onChanged={(from, to) => {
                        setPeriod({ from: from, to: to });
                      }}
                      periods={[
                        DatePeriod.All,
                        DatePeriod.Today,
                        DatePeriod.ThisWeek,
                        DatePeriod.ThisMonth,
                        DatePeriod.Yesterday,
                        DatePeriod.Custom,
                      ]}
                    ></DateRangePicker>
                  </div>
                </div>

                <table className="table w-100 bg-white my-orders-table">
                  <thead>
                    <tr>
                      {isSmallScreen && (
                        <>
                          <th>
                            <SortPlacedOn></SortPlacedOn>
                            <span>
                              <span className="mx-4">|</span>
                              <SortDeliveredOn></SortDeliveredOn>
                            </span>
                          </th>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <th>ORDER ID</th>
                          <th>MWF ORDER ID</th>
                          <th>
                            <span>
                              <SortPlacedOn></SortPlacedOn>
                            </span>
                          </th>
                          <th>
                            <span>
                              <SortDeliveredOn></SortDeliveredOn>
                            </span>
                          </th>
                          <th className="text-right">ORDER AMOUNT</th>
                          <th>PLACED BY</th>
                          <th>STATUS</th>
                          <th className="text-center">ACTIONS</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {grid.data?.salesOrderGridItemDtos &&
                      grid.data.salesOrderGridItemDtos.map((item, i) => (
                        <tr key={`${i}-${item.webOrderNumber}`}>
                          {isSmallScreen && (
                            <td>
                              <table className="table-label-desc">
                                <tbody>
                                  <TableRow label="Order ID:">
                                    <div className="flex gap-10">
                                      <div className="flex-1">
                                        {item.webOrderNumber}
                                      </div>
                                      <Actions i={i} item={item}></Actions>
                                    </div>
                                  </TableRow>
                                  <TableRow label="MWF Order ID:">
                                    {item.tranId}
                                  </TableRow>
                                  <TableRow label="Placed On:">
                                    {item.tranDate &&
                                      commonService.toDateString(
                                        item.tranDate,
                                        "full",
                                        !item.nsSqlId
                                      )}
                                  </TableRow>
                                  <TableRow label="Delivery Date:">
                                    {item.deliveryDate &&
                                      commonService.toDateString(
                                        item.deliveryDate,
                                        "date"
                                      )}
                                  </TableRow>
                                  <TableRow label="Order Amount:">
                                    {commonService.toMoney(item.tranAmount)}
                                  </TableRow>
                                  <TableRow label="Placed By:">
                                    {item.orderPlacedBy && (
                                      <a href={`mailto:${item.orderPlacedBy}`}>
                                        {item.orderPlacedBy}
                                      </a>
                                    )}
                                  </TableRow>
                                  <TableRow label="Status:">
                                    <PaymentStatusBadge
                                      {...item}
                                    ></PaymentStatusBadge>
                                  </TableRow>
                                </tbody>
                              </table>
                            </td>
                          )}
                          {!isSmallScreen && (
                            <>
                              <td>{item.webOrderNumber}</td>
                              <td>{item.tranId}</td>
                              <td
                                title={commonService.toDateString(
                                  item.tranDate,
                                  "full",
                                  !item.nsSqlId
                                )}
                              >
                                {item.tranDate &&
                                  commonService.toDateString(
                                    item.tranDate,
                                    "date",
                                    !item.nsSqlId
                                  )}
                              </td>
                              <td>
                                {item.deliveryDate &&
                                  commonService.toDateString(
                                    item.deliveryDate,
                                    "date"
                                  )}
                              </td>
                              <td className="text-right">
                                {commonService.toMoney(item.tranAmount)}
                              </td>
                              <td>
                                {item.orderPlacedBy && (
                                  <a href={`mailto:${item.orderPlacedBy}`}>
                                    {item.orderPlacedBy}
                                  </a>
                                )}
                              </td>
                              <td>
                                <PaymentStatusBadge
                                  {...item}
                                ></PaymentStatusBadge>
                              </td>
                              <td className="text-center">
                                <div>
                                  <Actions i={i} item={item}></Actions>
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="entries"
                    sizes={pageOptions}
                  ></Pagination>
                </div>
              </div>
            )}
          </div>
        </Tab>
        {categs.length > 1 && (
          <Tab eventKey="OrderSummary" title="Order Summary">
            <div className="p-2 py-1 bg-white">
              {orderSummaryLoaded && (
                <OrderSummaryWidget
                  hide={activeTab !== "OrderSummary"}
                  action={SysServices.http.customer.customerOrderingSummary}
                ></OrderSummaryWidget>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  );
}

export default MyOrders;
