import React, { useState } from "react";
import { Button } from "react-bootstrap";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import { useParams } from "react-router-dom";

function SurveyUnsubscribe(props: any) {
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { token } = useParams();

  const save = async () => {
    setSaving(true);
    await SysServices.http.survey
      .unsubscribe(token || "")
      .then(() => {
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => {
        toastStore.showError("Failing Sending Request", error);
        setSaving(false);
      });
  };

  return (
    <div
      className="unsubscribe-survey-page"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      {saved && (
        <div className="p-4 text-primary">
          <span>
            <i className="fa fa-info-circle me-1"></i> You have been
            unsubscribed. You will not receive further survey requests.
          </span>
          <div className="mt-2">
            <a href="/">Return to Login Page</a>
          </div>
        </div>
      )}

      {!saved && (
        <div className="unsubscribe-form mt-3">
          <h4>Unsubscribe from Surveys</h4>
          <p>
            You are about to unsubscribe from Midwest Foods Customer Surveys
          </p>

          <div className="mt-4 ">
            <div className="flex">
              <Button
                type="button"
                variant="outline-secondary"
                className="me-3"
                onClick={(e) => {
                  window.location.href = "/";
                }}
                disabled={saving}
              >
                Back to Login Page
              </Button>
              <Button
                disabled={saving}
                className="flex-1"
                variant="warning"
                onClick={(e) => {
                  save();
                }}
              >
                {saving ? "Unsubscribing..." : "Unsubscribe"}
              </Button>
            </div>
            <div className="text-left mt-4 mb-2 text-danger">
              Problems? Call: <a href="tel:773-927-8870">773-927-8870</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveyUnsubscribe;
