import SysModels from "../../models";
import systemStore from "../../stores/SystemStore";
import fetcher from "../Fetcher";

export class SearchService {
  globalSearchPreview = async (data: SysModels.IGlobalSearchInputDto) => {
    let url = `/Search/GlobalSearchPreview`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.IGlobalSearchPreviewOutputDto>(url, data);
  };

  globalSearchAll = async (
    page: number,
    pageSize: number,
    data: SysModels.IGlobalSearchInputDto
  ) => {
    let url = `/Search/GlobalSearchAll/${page}/${pageSize}`;
    if (systemStore.currentCustomer) {
      url = `${url}/${encodeURIComponent(systemStore.currentCustomer.number)}`;
    }
    return fetcher.post<SysModels.IGlobalSearchGridOutputDto>(url, data);
  };
}

const search = new SearchService();
export default search;
